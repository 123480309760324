import { selector, selectorFamily } from "recoil";
import { ordersState } from "../atoms/orders";
import { tradesState } from "../atoms/trades";
import { Order } from "../models/backtesting_models";
import { sessionsState } from "../atoms/sessions";
import { strategiesState } from "../atoms/strategies";

export const ordersSelector = selectorFamily({
    key: "OrdersSelector",
    get: (tradeId: string) => ({ get }) => {
        const orders = get(ordersState).get(tradeId);

        return orders?.sort((a, b) => b.executionSessionTime - a.executionSessionTime) ?? [];
    }
});

export const sessionOrdersSelector = selectorFamily({
    key: "SessionOrdersSelector",
    get: (sessionId: string) => ({ get }) => {
        const trades = get(tradesState).get(sessionId);
        const orders: Order[] = [];

        if (trades != undefined) {
            for (let i = 0; i < trades.length; i++) {
                const trade = trades[i];
                const tradeOrders = get(ordersState).get(trade.id);

                if (tradeOrders != undefined) orders.push(...tradeOrders);
            }
        }

        const pendingOrders = get(ordersState).get("default");

        if (pendingOrders != undefined) {
            for (let j = 0; j < pendingOrders.length; j++) {
                const pendingOrder = pendingOrders[j];

                if (pendingOrder.sessionId == sessionId) orders.push(pendingOrder);
            }
        }

        return orders.sort(
            (a, b) => (b.executionSessionTime > 0 ? b.executionSessionTime : b.createdRealtime) - (a.executionSessionTime > 0 ? a.executionSessionTime : a.createdRealtime));
    }
});

export const strategyOrdersSelector = selectorFamily({
    key: "StrategyOrdersSelector",
    get: (strategyId: string) => ({ get }) => {
        const orders: Order[] = [];

        const sessions = get(sessionsState).get(strategyId);

        if (sessions != undefined) {
            for (let i = 0; i < sessions.length; i++) {
                const session = sessions[i];
                const trades = get(tradesState).get(session.id);

                if (trades != undefined) {
                    for (let j = 0; j < trades.length; j++) {
                        const trade = trades[j];
                        const tradeOrders = get(ordersState).get(trade.id);

                        if (tradeOrders != undefined) {
                            orders.push(...tradeOrders);
                        }
                    }
                }
            }
        }

        return orders.sort((a, b) => b.executionSessionTime - a.executionSessionTime);
    }
});

export const allOrdersSelector = selector({
    key: "AllOrdersSelector",
    get: ({ get }) => {
        const orders: Order[] = [];

        const strategies = get(strategiesState);

        for (let i = 0; i < strategies.length; i++) {
            const strategy = strategies[i];
            const sessions = get(sessionsState).get(strategy.id);

            if (sessions != undefined) {
                for (let j = 0; j < sessions.length; j++) {
                    const session = sessions[j];
                    const trades = get(tradesState).get(session.id);

                    if (trades != undefined) {
                        for (let k = 0; k < trades.length; k++) {
                            const trade = trades[k];
                            const tradeOrders = get(ordersState).get(trade.id);

                            if (tradeOrders != undefined) {
                                orders.push(...tradeOrders);
                            }
                        }
                    }
                }
            }
        }

        return orders.sort((a, b) => b.executionSessionTime - a.executionSessionTime);
    }
});