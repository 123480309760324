import { useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { backtestingManagerState } from "../../../../state/backtesting/backtesting_manager_state";
import { sessionsState } from "../../../../state/backtesting/atoms/sessions";
import { strategiesState } from "../../../../state/backtesting/atoms/strategies";
import { currentSessionState } from "../../../../state/backtesting/atoms/current_session";
import { useEffect, useState } from "react";
import CreateStrategyModal from "../strategies/components/CreateStrategyModal";
import EditStrategyModal from "../strategies/components/EditStrategyModal";
import DeleteStrategyModal from "../strategies/components/DeleteStrategyModal";
import CreateSessionModal from "../strategies/strategy_details/sessions/components/CreateSessionModal";
import EditSessionModal from "../strategies/strategy_details/sessions/components/EditSessionModal";
import DeleteSessionModal from "../strategies/strategy_details/sessions/components/DeleteSessionModal";
import { currentStrategyState } from "../../../../state/backtesting/atoms/current_strategy";
import { PencilIcon, PlayIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { allSessionsSelector } from "../../../../state/backtesting/selectors/sessions_selector";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const setSessions = useSetRecoilState(sessionsState);
    const sessions = useRecoilValue(allSessionsSelector)
    const [strategies, setStrategies] = useRecoilState(strategiesState);
    const setCurrentSession = useSetRecoilState(currentSessionState);
    const setCurrentStrategy = useSetRecoilState(currentStrategyState);

    const [createSessionOpen, setOpenCreateSession] = useState(false);
    const [editSessionOpen, setEditSessionOpen] = useState(false);
    const [deleteSessionOpen, setDeleteSessionOpen] = useState(false);

    const [strategyId, setStrategyId] = useState("");
    const [createStrategyOpen, setOpenCreateStrategy] = useState(false);
    const [deleteStrategyOpen, setOpenDeleteStrategy] = useState(false);
    const [editStrategyOpen, setOpenEditStrategy] = useState(false);

    useEffect(() => {
        console.log(loaderData);
        backtestingManager.listSessions(setSessions);
        backtestingManager.listStrategies(setStrategies);

        setCurrentSession(undefined);
        setCurrentStrategy(undefined);
    }, []);

    return (
        <>
            <CreateStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={createStrategyOpen} setOpen={setOpenCreateStrategy} />
            <EditStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={editStrategyOpen} setOpen={setOpenEditStrategy} />
            <DeleteStrategyModal backtestingManager={backtestingManager} setStrategies={setStrategies} open={deleteStrategyOpen} setOpen={setOpenDeleteStrategy} strategyId={strategyId} />
            <DeleteSessionModal open={deleteSessionOpen} setOpen={setDeleteSessionOpen} />
            <EditSessionModal open={editSessionOpen} setOpen={setEditSessionOpen} />
            <CreateSessionModal open={createSessionOpen} setOpen={setOpenCreateSession} />

            <div className="flex justify-stretch">
                <div className="bg-gray-900 basis-3/4">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">
                                <div className="flex justify-start space-x-2">
                                    <h2 className="text-gray-50 pt-3 pb-2 text-2xl">Sessions</h2>
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => setOpenCreateSession(true)}
                                    >
                                        <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                        New Session
                                    </button>
                                </div>
                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-6">
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Period
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Product
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Balance
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Description
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-50">
                                                    Strategy
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sessions.map((session, sessionIdx) => (
                                                <tr key={session.id}>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                                                        )}
                                                    >
                                                        <div className="flex flex-row space-x-3 items-center">
                                                            <button
                                                                type="button"
                                                                className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                onClick={() => {
                                                                    setCurrentSession(session);
                                                                    navigate(`/app/session/${session.id}/backtesting`);
                                                                }}
                                                            >
                                                                <PlayIcon aria-hidden="true" className="h-5 w-5" />
                                                            </button>
                                                            <div className="font-medium text-gray-50">
                                                                {session.name}
                                                            </div>
                                                        </div>
                                                        {sessionIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-600" /> : null}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {new Date(session.startDate * 1000).toISOString().split('T')[0]} -{'>'} {new Date(session.endDate * 1000).toISOString().split('T')[0]}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.pairs}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.accountBalance}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {session.description}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'px-3 py-3.5 text-sm text-gray-50',
                                                        )}
                                                    >

                                                        {session.strategyId == "" ? "-" : strategies.find((s) => s.id == session.strategyId)?.name ?? "-"}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            sessionIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                                                        )}
                                                    >
                                                        <div className="flex space-x-2">
                                                            <span className="isolate inline-flex rounded-md shadow-sm">
                                                                <button
                                                                    type="button"
                                                                    className="relative inline-flex items-center rounded-l-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-10"
                                                                    onClick={() => {
                                                                        setCurrentSession(session);
                                                                        setDeleteSessionOpen(true)
                                                                    }}
                                                                >
                                                                    <TrashIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="relative -ml-px inline-flex items-center rounded-r-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-10"
                                                                    onClick={() => {
                                                                        setCurrentSession(session);
                                                                        setEditSessionOpen(true)
                                                                    }}
                                                                >
                                                                    <PencilSquareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                                                </button>
                                                            </span>
                                                        </div>
                                                        {sessionIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-600" /> : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-900 basis-1/4">
                    <div className="mx-auto max-w-9xl">
                        <div className="bg-gray-900 py-10">
                            <div className="px-4 sm:px-6 lg:px-6">

                                <div className="flex justify-start space-x-2">
                                    <h2 className="text-gray-50 pt-3 pb-2 text-2xl">Strategies</h2>
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => setOpenCreateStrategy(true)}
                                    >
                                        <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                        New Strategy
                                    </button>
                                </div>

                                <div className="-mx-4 mt-10 ring-1 ring-gray-700 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-6">
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-50 lg:table-cell"
                                                >
                                                    Description
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {strategies.map((strategy, strategyIdx) => (
                                                <tr key={strategy.id}>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                                                        )}
                                                    >
                                                        <div className="font-medium text-gray-50">
                                                            {strategy.name}
                                                        </div>
                                                        <div className="mt-1 flex flex-col text-gray-50 sm:block lg:hidden">
                                                            {strategy.description}
                                                        </div>
                                                        {strategyIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-600" /> : null}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-gray-600',
                                                            'hidden px-3 py-3.5 text-sm text-gray-50 lg:table-cell',
                                                        )}
                                                    >
                                                        {strategy.description}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            strategyIdx === 0 ? '' : 'border-t border-transparent',
                                                            'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
                                                        )}
                                                    >
                                                        <div className="flex flex-row justify-end">
                                                            <span className="isolate inline-flex rounded-md shadow-sm">
                                                                <button
                                                                    type="button"
                                                                    className="relative inline-flex items-center rounded-l-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-10"
                                                                    onClick={() => {
                                                                        setStrategyId(strategy.id);
                                                                        setOpenDeleteStrategy(true);
                                                                    }}
                                                                >
                                                                    <TrashIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="relative -ml-px inline-flex items-center bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-10"
                                                                    onClick={() => {
                                                                        setCurrentStrategy(strategy);
                                                                        setOpenEditStrategy(true);
                                                                    }}
                                                                >
                                                                    <PencilSquareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="relative -ml-px inline-flex items-center rounded-r-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-10"
                                                                    onClick={() => {
                                                                        setCurrentStrategy(strategy);
                                                                        navigate(`/app/strategy/${strategy.id}/sessions`)
                                                                    }}
                                                                >
                                                                    Details
                                                                </button>
                                                            </span>
                                                            {strategyIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-600" /> : null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}