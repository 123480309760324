export class ChartLayout {
    id: string;
    chartId: number;
    chartName: string;
    chartSessions: string[];
    chartContent: string;
    userId: string;
    lastModified: number;

    constructor(id: string, chartId: number, chartName: string, chartSessions: string[], chartContent: string, userId: string, lastModified: number) {
        this.id = id;
        this.chartId = chartId;
        this.chartName = chartName;
        this.chartSessions = chartSessions;
        this.chartContent = chartContent;
        this.userId = userId;
        this.lastModified = lastModified;
    }
}