import { selector, selectorFamily } from "recoil";
import { ruleGroupsState } from "../atoms/rule_groups";
import { RuleGroup } from "../models/backtesting_models";
import { strategiesState } from "../atoms/strategies";

export const ruleGroupsSelector = selectorFamily({
    key: "RuleGroupsSelector",
    get: (strategyId: string) => ({ get }) => {
        const ruleGroups = get(ruleGroupsState).get(strategyId);

        return ruleGroups?.sort((a, b) => b?.created - a?.created) ?? [];
    }
});

export const allRuleGroupsSelector = selector({
    key: "AllRuleGroupsSelector",
    get: ({ get }) => {
        const ruleGroups: RuleGroup[] = [];
        const strategies = get(strategiesState);

        for (let i = 0; i < strategies.length; i++) {
            const strategy = strategies[i];
            const strategyRuleGroups = get(ruleGroupsState).get(strategy.id);

            if (strategyRuleGroups != undefined) {
                ruleGroups.push(...strategyRuleGroups);
            }
        }

        return ruleGroups.sort((a, b) => b.created - a.created);
    }
});