import { useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticationManagerState } from "../../state/authentication/authentication_manager_state";
import { useFormik } from "formik";
import LandingPageHeader from "../landing/components/LandingPageHeader";

export default function InitRecoverPassword() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const authenticationManager = useRecoilValue(authenticationManagerState);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        console.log(loaderData);
    }, []);

    const validate = (values: { email?: string, api?: string }) => {
        const errors: { email?: string, api?: string } = {};
        if (!values.email) {
            errors.email = "You have to enter an email";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(values.email ?? "")) {
            errors.email = "Invalid email provided";
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            email: "",
        },
        validate,
        onSubmit: async (values) => {
            try {
                await authenticationManager.initRecoverPassword(values.email!);
                setEmailSent(true);
            } catch (error) {
                errors.api = "email failed to be sent"
                return false;
            }
            return true;
        },
    });

    return (

        <div>
            <LandingPageHeader />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                        Recover Your Password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            {touched.email && errors.email
                                ? <p className="text-white">{errors.email}</p>
                                : null}
                            <br />
                            {errors.api
                                ? <p className="text-white">{errors.api}</p>
                                : null}
                            {emailSent
                                ? <p className="text-white">The recover email has been sent</p>
                                : null}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                {emailSent ? "Resend Recover Email" : "Recover Password"}
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-400">
                        <Link to="/login" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                            Back to Log In
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}