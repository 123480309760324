import axios, { AxiosInstance } from "axios";
import IAuthenticationAPIBase from "./authentication_api_base";
import { LoginResponse } from "../models/authentication_models";
import { signWithToken } from "../../../utils/crypto";

export default class AuthenticationAPI implements IAuthenticationAPIBase {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/authentication`,
        });
    }

    async login(email: string, password: string): Promise<LoginResponse> {
        const res = await this.instance.post("/login", {
            email,
            password,
        });

        const loginResponse = Object.assign(new LoginResponse("", ""), res.data);

        return loginResponse;
    }

    async loginGoogle(token: string): Promise<LoginResponse> {
        const res = await this.instance.post("/login/google", {}, {
            params: { token }
        });

        const loginResponse = Object.assign(new LoginResponse("", ""), res.data);

        return loginResponse;
    }

    async logout(): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post("/logout", {}, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async initRecoverPassword(email: string): Promise<void> {
        const res = await this.instance.post("/init_recover_password", {}, {
            params: { email }
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async recoverPassword(userId: string, token: string, newPassword: string): Promise<void> {
        const res = await this.instance.post(`/${userId}/recover_password`, {
            recoverToken: token,
            newPassword
        });

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async authenticate(
        userId: string,
        signature: string,
        payload: string
    ): Promise<string> {
        const res = await this.instance.post("/authenticate", {
            id: userId,
            signature,
            payload,
        });

        if (res.status === 200) {
            return userId;
        }

        throw res.data;
    }
}
