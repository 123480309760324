import axios, { AxiosInstance } from "axios";
import IStatisticsAPIBase from "./statistics_api_base";
import { SessionFilterSettings, DaysFilterSettings, TagsFilterSettings, RulesFilterSettings, StrategyStatistics, SessionStatistics, RuleStatistics, TradeStatistics } from "../models/statistics_models";
import { signWithToken } from "../../../utils/crypto";

export default class StatisticsAPI implements IStatisticsAPIBase {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/statistics`,
        });
    }


    async generateStrategyStatistics(strategyId: string, sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<StrategyStatistics> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/strategy/${strategyId}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const strategyStats: StrategyStatistics = Object.assign(
            new StrategyStatistics("", "", "", new Map(), ""),
            res.data
        );

        return strategyStats;
    }

    async generateSessionStatistics(sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<SessionStatistics> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/session/${sessionId}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const sessionStats: SessionStatistics = Object.assign(
            new SessionStatistics("", "", "", new Map(), ""),
            res.data
        );

        return sessionStats;
    }

    async generateRuleStatistics(ruleId: string): Promise<RuleStatistics> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/rule/${ruleId}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const ruleStats: RuleStatistics = Object.assign(
            new RuleStatistics("", "", "", new Map(), ""),
            res.data
        );

        return ruleStats;
    }

    async generateTradeStatistics(tradeId: string): Promise<TradeStatistics> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/trade/${tradeId}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const tradeStats: TradeStatistics = Object.assign(
            new TradeStatistics("", "", "", "", new Map(), new Map(), new Map(), 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, "", 0, 0, 0, 0),
            res.data
        );

        return tradeStats;
    }
}