import IAuthenticationAPIBase from "../api/authentication_api_base";
import IAuthenticationManagerBase from "./authentication_manager_base";
import { signWithToken } from "../../../utils/crypto";
import { AUTHENTICATION_KEY, USER_ID } from "../../../constants/users_constants";
import { SetterOrUpdater } from "recoil";

export default class AuthenticationManager<T extends IAuthenticationAPIBase> implements IAuthenticationManagerBase {
    constructor(protected readonly api: T) { }

    async login(setState: SetterOrUpdater<boolean>, email: string, password: string): Promise<[string, string]> {
        const loginResponse = await this.api.login(email.toLowerCase(), password);

        localStorage.setItem(USER_ID, loginResponse.userId);
        localStorage.setItem(AUTHENTICATION_KEY, loginResponse.token);

        setState(true);

        return [loginResponse.userId, loginResponse.token];
    }

    async loginGoogle(setState: SetterOrUpdater<boolean>, token: string): Promise<[string, string]> {
        const loginResponse = await this.api.loginGoogle(token);

        localStorage.setItem(USER_ID, loginResponse.userId);
        localStorage.setItem(AUTHENTICATION_KEY, loginResponse.token);

        setState(true);

        return [loginResponse.userId, loginResponse.token];
    }

    async logout(setState: SetterOrUpdater<boolean>): Promise<void> {
        await this.api.logout();

        localStorage.removeItem(USER_ID);
        localStorage.removeItem(AUTHENTICATION_KEY);

        setState(false);
    }

    async initRecoverPassword(email: string): Promise<void> {
        await this.api.initRecoverPassword(email.toLowerCase());
    }

    async recoverPassword(userId: string, token: string, newPassword: string): Promise<void> {
        await this.api.recoverPassword(userId, token, newPassword);
    }

    async authenticate(setState: SetterOrUpdater<boolean>): Promise<string> {
        const authenticationParams = await signWithToken();

        const id = await this.api.authenticate(authenticationParams.userId, authenticationParams.signature, authenticationParams.payload);

        setState(true);

        return id;
    }
}