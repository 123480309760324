import { Bar, CurrencyItem, DatafeedConfiguration, DatafeedSymbolType, Exchange, LibrarySubsessionInfo, LibrarySymbolInfo, ResolutionString, SearchSymbolResultItem, SeriesFormat, SymbolInfoPriceSource, Timezone, Unit, VisiblePlotsSet } from "charting_library";

export class ReplayDatafeedConfiguration implements DatafeedConfiguration {
    exchanges?: Exchange[];
    supported_resolutions?: ResolutionString[];
    units?: Record<string, Unit[]>;
    currency_codes?: (string | CurrencyItem)[];
    supports_marks?: boolean;
    supports_time?: boolean;
    supports_timescale_marks?: boolean;
    symbols_types?: DatafeedSymbolType[];
    symbols_grouping?: Record<string, string>;
}

export class ReplayDatafeedSymbolInfo implements LibrarySymbolInfo {
    name: string;
    base_name?: [
        string
    ];
    ticker?: string;
    description: string;
    long_description?: string;
    type: string;
    session: string;
    session_display?: string;
    session_holidays?: string;
    corrections?: string;
    exchange: string;
    listed_exchange: string;
    timezone: Timezone;
    format: SeriesFormat;
    pricescale: number;
    minmov: number;
    fractional?: boolean;
    minmove2?: number;
    variable_tick_size?: string;
    has_intraday?: boolean;
    supported_resolutions?: ResolutionString[];
    intraday_multipliers?: string[];
    has_seconds?: boolean;
    has_ticks?: boolean;
    seconds_multipliers?: string[];
    has_daily?: boolean;
    daily_multipliers?: string[];
    has_weekly_and_monthly?: boolean;
    weekly_multipliers?: string[];
    monthly_multipliers?: string[];
    has_empty_bars?: boolean;
    visible_plots_set?: VisiblePlotsSet;
    volume_precision?: number;
    data_status?: "streaming" | "endofday" | "delayed_streaming";
    delay?: number;
    expired?: boolean;
    expiration_date?: number;
    sector?: string;
    industry?: string;
    currency_code?: string;
    original_currency_code?: string;
    unit_id?: string;
    original_unit_id?: string;
    unit_conversion_types?: string[];
    subsession_id?: string;
    subsessions?: LibrarySubsessionInfo[];
    price_source_id?: string;
    price_sources?: SymbolInfoPriceSource[];
    logo_urls?: [
        string
    ] | [
        string,
        string
    ];
    exchange_logo?: string;

    constructor(name: string, description: string, type: string, session: string, exchange: string, listed_exchange: string, timezone: Timezone, format: SeriesFormat, pricescale: number, minmov: number) {
        this.name = name;
        this.description = description;
        this.type = type;
        this.session = session;
        this.exchange = exchange;
        this.listed_exchange = listed_exchange;
        this.timezone = timezone;
        this.format = format;
        this.pricescale = pricescale;
        this.minmov = minmov;
    }
}

export class ReplayBar implements Bar {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
    volume?: number | undefined;

    constructor(time: number, open: number, high: number, low: number, close: number, volume?: number) {
        this.time = time;
        this.open = open;
        this.high = high;
        this.low = low;
        this.close = close;
        this.volume = volume;
    }
}

export class ReplaySearchSymbolResultItem implements SearchSymbolResultItem {
    symbol: string;
    full_name: string;
    description: string;
    exchange: string;
    ticker?: string | undefined;
    type: string;
    logo_urls?: [string] | [string, string] | undefined;
    exchange_logo?: string | undefined;

    constructor(symbol: string, full_name: string, description: string, exchange: string, type: string, ticker?: string, logo_urls?: [string] | [string, string], exchange_logo?: string) {
        this.symbol = symbol;
        this.full_name = full_name;
        this.description = description;
        this.exchange = exchange;
        this.ticker = ticker;
        this.type = type;
        this.logo_urls = logo_urls;
        this.exchange_logo = exchange_logo;
    }
}