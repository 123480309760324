export class EquityCurveGraphData {
    constructor(
        public date: number,
        public equity: number,
    ) { }
}

export class PLCurveGraphData {
    constructor(
        public date: number,
        public profit: number,
        public value_engaged: number,
        public product: string,
        public sessionId: string,
        public start_balance: number,
        public end_balance: number,
    ) { }
}

export class DailyReturnsGraphData {
    constructor(
        public date: number,
        public daily_return: number,
    ) { }
}

export class DrawdownDrawupGraphData {
    constructor(
        public date: number,
        public drawdown?: number,
        public drawup?: number,
    ) { }
}

export class RiskRewardGraphData {
    constructor(
        public date: number,
        public risk_reward: number,
    ) { }
}

export class BalanceMovementGraphData {
    constructor(
        public date: number,
        public balance: number = 0,
        public long_amount: number = 0,
        public short_amount: number = 0,
        public product: string,
        public sessionId: string,
    ) { }
}

// In the Recharts library, you can add a suffix to the y - axis values by using the `tickFormatter` prop of the `YAxis` component.This prop allows you to define a function that formats the tick values displayed on the axis.You can use this function to append the desired suffix, such as "USD" or "%", to the values.

//     Here's an example of how you can achieve this:

//         ```jsx
// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// const data = [
//   { name: 'Page A', uv: 4000 },
//   { name: 'Page B', uv: 3000 },
//   { name: 'Page C', uv: 2000 },
//   { name: 'Page D', uv: 2780 },
//   { name: 'Page E', uv: 1890 },
//   { name: 'Page F', uv: 2390 },
//   { name: 'Page G', uv: 3490 },
// ];

// const MyLineChart = () => {
//   // Define a formatter function for the y-axis
//   const yAxisTickFormatter = (value) => `${ value } USD`;

//   return (
//     <LineChart
//       width={500}
//       height={300}
//       data={data}
//       margin={{
//         top: 5, right: 30, left: 20, bottom: 5,
//       }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis tickFormatter={yAxisTickFormatter} />
//       <Tooltip formatter={(value) => `${ value } USD`} />
//       <Legend />
//       <Line type="monotone" dataKey="uv" stroke="#8884d8" activeDot={{ r: 8 }} />
//     </LineChart>
//   );
// };

// export default MyLineChart;
// ```

// In this example, the`yAxisTickFormatter` function adds "USD" as a suffix to each tick value on the y - axis.Similarly, you can modify the formatter function to use other suffixes as needed, such as percentages:

// ```jsx
// const yAxisTickFormatter = (value) => `${ value }% `;
// ```

// Additionally, the`Tooltip` component's `formatter` prop can be used to maintain consistency with the displayed y-axis values when hovering over the chart.