export class Session {
    constructor(
        public id: string,
        public name: string,
        public accountBalance: number,
        public assetAmount: number,
        public amountOwed: number,
        public pairs: string[],
        public strategyId: string,
        public startDate: number,
        public stopDate: number,
        public endDate: number,
        public resolution: string,
        public userId: string,
        public created: number,
        public description: string,
        public lastChartLayoutId: number
    ) { }
}

export class Strategy {
    constructor(
        public id: string,
        public name: string,
        public description: string,
        public userId: string,
        public created: number,
        public ruleGroups: string[],
        public rules: string[]
    ) {
    }
}

export class RuleGroup {
    constructor(
        public id: string,
        public name: string,
        public rules: string[],
        public userId: string,
        public strategyId: string,
        public created: number,
        public description: string
    ) { }
}

export class Rule {
    constructor(
        public id: string,
        public name: string,
        public description: string,
        public strategyId: string,
        public groupId: string,
        public created: number,
        public userId: string
    ) { }
}

export class Qty {
    constructor(
        public FixedAmount?: number,
        public Percentage?: number,
    ) { }
}

export class TakeProfit {
    constructor(
        public price: number,
        public Qty: Qty,
        public created: number,
        public createdSessionTime: number,
        public triggered: boolean
    ) { }
}

export class StopLoss {
    constructor(
        public price: number,
        public Qty: Qty,
        public created: number,
        public createdSessionTime: number,
        public triggered: boolean
    ) { }
}

export class TPSL {
    constructor(
        public id: string,
        public takeProfit?: TakeProfit,
        public stopLoss?: StopLoss
    ) { }
}

export class Trade {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public entryTime: number,
        public exitTime: number,
        public entryRealTime: number,
        public exitRealTime: number,
        public setups: string[],
        public mistakes: string[],
        public custom: string[],
        public product: string,
        public rulesFollowed: string[],
        public side: string,
        public status: string,
        public tpsl: TPSL[],
        public strategyId?: string,
        public commissionFee?: number,
    ) { }
}



export class Order {
    constructor(
        public id: string,
        public userId: string,
        public side: string,
        public orderType: string,
        public price: number,
        public priceAtTime: number,
        public amount: number,
        public sessionId: string,
        public tradeId: string,
        public createdSessionTime: number,
        public createdRealtime: number,
        public riskPercentage: number,
        public state: string,
        public product: string,
        public executionSessionTime: number,
        public executionRealTime: number,
        public tags: string[],
        public stopLoss?: StopLoss,
        public takeProfit?: TakeProfit,
        public strategyId?: string
    ) { }
}

export class BasicSymbolInfo {
    constructor(
        public baseName: string,
        public quoteAsset: string,
        public exchange: string,
        public name: string,
        public firstDate: number,
        public lastDate: number,
    ) { }
}