import { atom } from "recoil";
import IPaymentManagerBase from "./manager/payment_manager_base";
import PaymentAPI from "./api/payment_api";
import PaymentManager from "./manager/payment_manager";

const newPaymentManager = (): IPaymentManagerBase => {
    const full = window.location.protocol + '//' + window.location.hostname;
    const api = new PaymentAPI(full, window.location.port);

    return new PaymentManager(api);
}

export const paymentManagerState = atom({
    key: "PaymentManagerState",
    default: newPaymentManager()
});