import { selector, selectorFamily } from "recoil";
import { tradesState } from "../atoms/trades";
import { Trade } from "../models/backtesting_models";
import { sessionsState } from "../atoms/sessions";
import { strategiesState } from "../atoms/strategies";
import { currentStrategyState } from "../atoms/current_strategy";

export const tradesSelector = selectorFamily({
    key: "TradesSelector",
    get: (sessionId: string) => ({ get }) => {
        const trades = get(tradesState).get(sessionId);

        return trades?.sort((a, b) => b.exitTime - a.exitTime) ?? [];
    }
});

export const strategyTradesSelector = selectorFamily({
    key: "StrategyTradesSelector",
    get: (strategyId: string) => ({ get }) => {
        const trades: Trade[] = [];

        if (strategyId == "") {
            strategyId = get(currentStrategyState)?.id ?? "";
        }

        const sessions = get(sessionsState).get(strategyId);

        if (sessions != undefined) {
            for (let i = 0; i < sessions.length; i++) {
                const session = sessions[i];
                const sessionTrades = get(tradesState).get(session.id);

                if (sessionTrades != undefined) {
                    trades.push(...sessionTrades);
                }
            }
        }

        return trades.sort((a, b) => b.exitTime - a.exitTime);
    }
});

export const allTradesSelector = selector({
    key: "AllTradesSelector",
    get: ({ get }) => {
        const trades: Trade[] = [];
        const strategies = get(strategiesState);

        for (let i = 0; i < strategies.length; i++) {
            const strategy = strategies[i];
            const sessions = get(sessionsState).get(strategy.id);

            if (sessions != undefined) {
                for (let j = 0; j < sessions.length; j++) {
                    const session = sessions[j];
                    const sessionTrades = get(tradesState).get(session.id);

                    if (sessionTrades != undefined) {
                        trades.push(...sessionTrades);
                    }
                }
            }
        }

        return trades.sort((a, b) => b.exitTime - a.exitTime);
    }
});