import { selector, selectorFamily } from "recoil";
import { sessionsState } from "../atoms/sessions";
import { Session } from "../models/backtesting_models";
import { strategiesState } from "../atoms/strategies";

export const sessionsSelector = selectorFamily({
    key: "SessionsSelector",
    get: (strategyId: string) => ({ get }) => {
        const sessions = get(sessionsState).get(strategyId);

        return sessions?.sort((a, b) => b.created - a.created) ?? [];
    }
});

export const allSessionsSelector = selector({
    key: "AllSessionsSelector",
    get: ({ get }) => {
        const sessions: Session[] = [];
        const strategies = get(strategiesState);

        for (let i = 0; i < strategies.length; i++) {
            const strategy = strategies[i];
            const strategySessions = get(sessionsState).get(strategy.id);

            if (strategySessions != undefined) {
                sessions.push(...strategySessions);
            }
        }

        const noStrategySessions = get(sessionsState).get("no_strategy");

        if (noStrategySessions != undefined) sessions.push(...noStrategySessions);

        return sessions.sort((a, b) => b.created - a.created);
    }
});