import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { stripeState } from "../..//state/payment/atoms/stripe";
import { StripeElements, StripePaymentElement } from "@stripe/stripe-js";
import { pricesState } from "../../state/payment/atoms/prices";
import { useLoaderData, useNavigate } from "react-router-dom";
import { currentClientSecretState } from "../../state/payment/atoms/current_client_secret";
import { currentSetupIntentState } from "../../state/payment/atoms/current_setup_intent";
import { paymentManagerState } from "../../state/payment/payment_manager_state";
import { userState } from "../../state/user/atoms/user";
import { subscriptionsState } from "../../state/payment/atoms/subscriptions";

function Payment() {
    const navigate = useNavigate();
    const stripe = useRecoilValue(stripeState);
    const paymentManager = useRecoilValue(paymentManagerState);
    const user = useRecoilValue(userState);
    const [currentClientSecret, setCurrentClientSecret] = useRecoilState(currentClientSecretState);
    const [currentSetupIntentId, setCurrentSetupIntentId] = useRecoilState(currentSetupIntentState);
    const [prices, setPrices] = useRecoilState(pricesState);
    const setSubscriptions = useSetRecoilState(subscriptionsState);
    const [elements, setElements] = useState<StripeElements>();
    const [paymentElement, setPaymentElement] = useState<StripePaymentElement>();
    const loaderData = useLoaderData();

    useEffect(() => {
        paymentManager.listPrices(setPrices);
    }, [])

    useEffect(() => {
        console.log(loaderData);
        if (currentClientSecret == "") {
            if (user.customerId != "") {
                paymentManager.initAddPaymentMethod(setCurrentSetupIntentId, setCurrentClientSecret, user.customerId);
            }
            return;
        }

        const elements = stripe!.elements({
            clientSecret: currentClientSecret,
            appearance: {
                theme: "flat"
            },
        });

        setElements(elements);

        const paymentElement = elements.create("payment", {
            layout: "accordion"
        })

        setPaymentElement(paymentElement);

        paymentElement.mount("#payment-box");
    }, [currentClientSecret, user]);

    return (
        <div>
            <h1>Add a Payment Method To Access Free Trial</h1>
            <br />
            <h5>Add a payment Method to access 7 days free trial, you will be automatically billed at the end of it if you don't cancel your subscription.</h5>
            <br />

            <div id="payment-box"></div>
            <button onClick={async () => {
                await elements?.submit()

                const result = await stripe?.confirmSetup({
                    elements: elements,
                    clientSecret: currentClientSecret,
                    confirmParams: {
                        return_url: "https://trader-lab.com/payment",
                    },
                    redirect: "if_required"
                });

                console.log(result);

                await paymentManager.listPrices(setPrices)
                await paymentManager.createSubscription(setSubscriptions, user.customerId, "usd", "", [], "", prices[0].id, "trader lab starter subscription");

                navigate("/app");
            }}>Save Card</button>
            <button onClick={async () => {
                await paymentManager.cancelAddPaymentMethod(setCurrentSetupIntentId, setCurrentClientSecret, user.customerId, currentSetupIntentId);
                navigate("/");
            }}>Cancel</button>
        </div >
    )
}

export default Payment;