import { useRecoilState, useRecoilValue } from "recoil"
import { tradesState } from "../../../../../../state/backtesting/atoms/trades"
import { ordersState } from "../../../../../../state/backtesting/atoms/orders";
import { Order, Qty, StopLoss, TakeProfit, TPSL } from "../../../../../../state/backtesting/models/backtesting_models";
import { useEffect, useState } from "react";
import { backtestingManagerState } from "../../../../../../state/backtesting/backtesting_manager_state";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { lastBarState } from "../../../../../../state/replay/atoms/lastBar";
import { symbolsState } from "../../../../../../state/backtesting/atoms/symbols";
import AddTPSLModal from "./modals/AddTPSLModal";
import EditTPSLModal from "./modals/EditTPSLModal";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import useSize from "../hooks/useSize";
import DeleteTPSLModal from "./modals/DeleteTPSLModal";
import CloseTradeModal from "./modals/CloseTradeModal";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function OpenTradesView() {
    const [trades, setTrades] = useRecoilState(tradesState);
    const [orders, setOrders] = useRecoilState(ordersState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const currentSession = useRecoilValue(currentSessionState);
    const lastBar = useRecoilValue(lastBarState);
    const symbols = useRecoilValue(symbolsState);
    const [addTPSLModalOpen, setAddTPSLModalOpen] = useState(false);
    const [closeTradeModalOpen, setCloseTradeModalOpen] = useState(false);
    const [editTPSLModalOpen, setEditTPSLModalOpen] = useState(false);
    const [deleteTPSLModalOpen, setDeleteTPSLModalOpen] = useState(false);
    const [currentTradeId, setCurrentTradeId] = useState<string | undefined>(undefined);
    const [currentTpsl, setCurrentTpsl] = useState<TPSL | undefined>(undefined);

    useEffect(() => {
        if (currentSession != undefined) {
            backtestingManager.listSessionTrades(setTrades, currentSession.id);
        }
    }, [orders]);

    useEffect(() => {

    }, [lastBar]);

    const computeTradeAvgEntryPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            (o.side.includes("buy_long") || o.side.includes("sell_short")) &&
            o.state == "passed"
        );

        console.log(or)
        console.log("trade orders: ", tradeOrders)

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAvgExitPrice = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            (o.side.includes("buy_short") || o.side.includes("sell_long")) &&
            o.state == "passed"
        );

        if (tradeOrders.length == 0) {
            return undefined;
        }

        let amountCount = 0;
        let priceCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
            priceCount += order.price * order.amount
        }

        return (priceCount / amountCount);
    }

    const computeTradeAmountBought = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("buy") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computeTradeAmountSold = (tradeId: string, or: Order[]) => {
        const tradeOrders = or.filter((o) =>
            o.tradeId == tradeId &&
            o.side.includes("sell") &&
            o.state == "passed"
        );

        let amountCount = 0;

        for (let i = 0; i < tradeOrders.length; i++) {
            const order = tradeOrders[i];

            amountCount += order.amount;
        }

        return amountCount;
    }

    const computePositionSize = (tradeId: string, tradeSide: string, or: Order[]) => {
        if (tradeSide == "short") {
            return computeTradeAmountSold(tradeId, or) - computeTradeAmountBought(tradeId, or);
        }

        return computeTradeAmountBought(tradeId, or) - computeTradeAmountSold(tradeId, or);
    }

    const computeTradeUnrealisedPL = (tradeId: string, tradeSide: string, or: Order[]) => {
        const amountSold = computeTradeAmountSold(tradeId, or);
        const amountBought = computeTradeAmountBought(tradeId, or)
        const avgEntry = computeTradeAvgEntryPrice(tradeId, or);
        const currentPrice = lastBar?.close ?? 0;

        if (tradeSide == "short") {
            return ((amountSold - amountBought) * (avgEntry - currentPrice));
        }

        return ((amountBought - amountSold) * (currentPrice - avgEntry));
    }

    const computeTradeRealisedPl = (tradeId: string, tradeSide: string, or: Order[]) => {
        const amountSold = computeTradeAmountSold(tradeId, or);
        const amountBought = computeTradeAmountBought(tradeId, or);

        if (tradeSide == "short") {
            if (amountBought == 0) {
                return 0;
            }

            const avgEntry = computeTradeAvgEntryPrice(tradeId, or);
            const avgExit = computeTradeAvgExitPrice(tradeId, or);

            return (avgExit! - avgEntry) * amountBought;
        }

        if (amountSold == 0) {
            return 0;
        }

        const avgEntry = computeTradeAvgEntryPrice(tradeId, or);
        const avgExit = computeTradeAvgExitPrice(tradeId, or);

        return (avgExit! - avgEntry) * amountSold;
    }

    const computeTPSLQty = (qty: Qty, isShort: boolean): number => {
        if (qty.FixedAmount != undefined) return qty.FixedAmount;

        if (qty.Percentage != undefined) {
            if (isShort) {
                return (currentSession!.amountOwed * qty.Percentage) / 100;
            }

            return (currentSession!.assetAmount * qty.Percentage) / 100;
        } else throw "no valid qty provided";
    }

    const displayTakeProfit = (tp?: TakeProfit, isShort?: boolean): string => {
        if (tp != undefined) {
            const price = tp.price;
            const quoteAsset = symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? "";
            const qty = computeTPSLQty(tp.Qty, isShort ?? false);
            const baseAsset = (symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? "");

            return `${price} ${quoteAsset} -> ${qty} ${baseAsset}`
        }

        return "-"
    }

    const displayStopLoss = (sl?: StopLoss, isShort?: boolean): string => {
        if (sl != undefined) {
            const price = sl.price;
            const quoteAsset = symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? "";
            const qty = computeTPSLQty(sl.Qty, isShort ?? false);
            const baseAsset = (symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? "");

            return `${price} ${quoteAsset} -> ${qty} ${baseAsset}`
        }

        return "-"
    }

    return (
        <>
            <AddTPSLModal open={addTPSLModalOpen} setOpen={setAddTPSLModalOpen} tradeId={currentTradeId!} />
            <EditTPSLModal open={editTPSLModalOpen} setOpen={setEditTPSLModalOpen} tradeId={currentTradeId!} tpsl={currentTpsl} />
            <DeleteTPSLModal open={deleteTPSLModalOpen} setOpen={setDeleteTPSLModalOpen} tradeId={currentTradeId!} slId={currentTpsl?.id} tpId={currentTpsl?.id} />
            <CloseTradeModal open={closeTradeModalOpen} setOpen={setCloseTradeModalOpen} tradeId={currentTradeId!} />

            <div className="bg-gray-950 flex-none overflow-auto">
                <div>
                    <div className="bg-gray-950">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full">
                                <table className="min-w-full divide-y divide-gray-700">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Side
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Status
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Avg Entry Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Avg Exit Price
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Position Size
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Realised P&L
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Unrealised P&L
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                TP / SL
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                                Entry Time
                                            </th>
                                            <th scope="col" className="py-3.5 pl-3">
                                                <span className="sr-only">Details</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-800">
                                        {trades.get(currentSession?.id ?? "")?.filter((trade) => trade.status == "open").map((trade) => (
                                            <tr key={trade.id}>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.side}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{trade.status}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {Math.round(computeTradeAvgEntryPrice(trade.id, orders.get(trade.id) ?? []) * 100) / 100}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {computeTradeAvgExitPrice(trade.id, orders.get(trade.id) ?? []) != undefined ? Math.round(computeTradeAvgExitPrice(trade.id, orders.get(trade.id) ?? [])! * 100) / 100 : "-"}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {`${computePositionSize(trade.id, trade.side, orders.get(trade.id) ?? [])} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? ""}`}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        "whitespace-nowrap px-3 py-4 text-sm",
                                                        computeTradeRealisedPl(
                                                            trade.id, trade.side, orders.get(trade.id) ?? []) > 0
                                                            ? "text-green-400"
                                                            : (computeTradeRealisedPl(trade.id, trade.side, orders.get(trade.id) ?? []) < 0 ? "text-red-500" : "text-gray-300")
                                                    )}
                                                >
                                                    {`${Math.round(computeTradeRealisedPl(trade.id, trade.side, orders.get(trade.id) ?? []) * 100) / 100} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}`}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        "whitespace-nowrap px-3 py-4 text-sm",
                                                        computeTradeUnrealisedPL(trade.id, trade.side, orders.get(trade.id) ?? []) > 0
                                                            ? "text-green-400"
                                                            : (computeTradeUnrealisedPL(trade.id, trade.side, orders.get(trade.id) ?? []) < 0 ? "text-red-500" : "text-gray-300")
                                                    )}
                                                >
                                                    {lastBar != undefined ? `${Math.round(computeTradeUnrealisedPL(trade.id, trade.side, orders.get(trade.id) ?? []) * 100) / 100} ${symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? ""}` : "-"}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        "whitespace-nowrap px-3 py-4 text-sm",
                                                        "text-gray-300")
                                                    }
                                                >
                                                    <div className="flex flex-col space-y-2 items-baseline justify-start">
                                                        {trade.tpsl.map((tpsl) => {
                                                            const { takeProfit, stopLoss } = tpsl;
                                                            return (
                                                                <div className="flex space-x-3 justify-between items-baseline">
                                                                    <p className="flex-shrink">
                                                                        <span className="text-green-400">
                                                                            {displayTakeProfit(takeProfit, trade.side == "short")}
                                                                        </span>
                                                                        <span> / </span>
                                                                        <span className="text-red-500">
                                                                            {displayStopLoss(stopLoss, trade.side == "short")}
                                                                        </span>
                                                                        <span> </span>
                                                                    </p>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            className="rounded flex-shrink bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                                                                            onClick={() => {
                                                                                setCurrentTradeId(trade.id);
                                                                                setCurrentTpsl(tpsl);
                                                                                setEditTPSLModalOpen(true);
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="rounded ml-2 flex-shrink bg-red-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-400"
                                                                            onClick={() => {
                                                                                setCurrentTradeId(trade.id);
                                                                                setCurrentTpsl(tpsl);
                                                                                setDeleteTPSLModalOpen(true);
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            onClick={() => {
                                                                setCurrentTradeId(trade.id);
                                                                setAddTPSLModalOpen(true);
                                                            }}
                                                        >
                                                            <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-4 w-4" />
                                                            Add New
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-white sm:pl-0">
                                                    {new Date(trade.entryTime * 1000).toUTCString()}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    <button
                                                        type="button"
                                                        className="rounded bg-red-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                                                        onClick={() => {
                                                            setCurrentTradeId(trade.id);
                                                            setCloseTradeModalOpen(true);
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}