export function convertToDateTimeFormat(isoDate: string) {
    return isoDate.slice(0, 16)
}

export function formatDuration(seconds: number): string {
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds %= 24 * 60 * 60;
    const hours = Math.floor(seconds / (60 * 60));
    seconds %= 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    const parts: string[] = [];

    if (days > 0) {
        parts.push(`${days}D`);
    }
    if (hours > 0) {
        parts.push(`${hours}H`);
    }
    if (minutes > 0) {
        parts.push(`${minutes}min`);
    }
    if (seconds > 0) {
        parts.push(`${seconds} s`);
    }

    return parts.join(' ');
}