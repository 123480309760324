import { SetterOrUpdater } from "recoil";
import { AUTHENTICATION_KEY, USER_ID } from "../../../constants/users_constants";
import IUserAPIBase from "../api/users_api_base";
import { User } from "../models/users_models";
import IUsersManagerBase from "./users_manager_base";

export default class UsersManager<T extends IUserAPIBase> implements IUsersManagerBase {
    constructor(protected readonly api: T) { }

    async createUser(
        setUserState: SetterOrUpdater<User>,
        authenticationMethod: string,
        token?: string,
        email?: string,
        password?: string,
    ): Promise<[User, string]> {
        const createUserResponse = await this.api.createUser(authenticationMethod, token, email?.toLowerCase(), password);
        setUserState(createUserResponse.user);

        localStorage.setItem(USER_ID, createUserResponse.user.id);
        localStorage.setItem(AUTHENTICATION_KEY, createUserResponse.token);

        return [createUserResponse.user, createUserResponse.token];
    }

    async getUser(setUserState: SetterOrUpdater<User>): Promise<User> {
        const userId = localStorage.getItem(USER_ID);

        if (userId != null) {
            const user = await this.api.getUser();
            setUserState(user);
            return user;
        }

        throw "user id not set";
    }

    async updateEmail(setUserState: SetterOrUpdater<User>, newEmail: string, password: string): Promise<void> {
        await this.api.updateEmail(newEmail, password);

        setUserState((previousUser) => new User(
            previousUser.id,
            newEmail,
            previousUser.customerId,
            previousUser.createdAt,
            previousUser.lastActivity,
            previousUser.valid,
            previousUser.isMember,
            previousUser.authorizationLevel
        ))
    }

    async updatePassword(setUserState: SetterOrUpdater<User>, oldPassword: string, newPassword: string): Promise<void> {
        await this.api.updatePassword(oldPassword, newPassword);
    }

    async deleteUser(setUserState: SetterOrUpdater<User>): Promise<void> {
        await this.api.deleteUser();

        setUserState(new User("", "", "", 0, 0, false, false, ""))
    }
}