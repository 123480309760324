import { atom } from "recoil";
import IUsersManagerBase from "./manager/users_manager_base";
import UsersAPI from "./api/users_api";
import UsersManager from "./manager/users_manager";

const newUserManager = (): IUsersManagerBase => {
    const full = window.location.protocol + '//' + window.location.hostname;
    const api = new UsersAPI(full, window.location.port);

    return new UsersManager(api);
}

export const usersManagerState = atom({
    key: "UsersManagerState",
    default: newUserManager()
});