import { atom } from "recoil";

interface IReplayTimeframe {
    from?: number,
    to: number
}

const replayTimeframeManagerState = atom<IReplayTimeframe>({
    key: "replayTimeframeManagerState",
    default: {
        to: Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 300
    }
})

export default replayTimeframeManagerState;