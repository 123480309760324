'use client'

import { useState } from 'react'
import { Dialog, DialogPanel, Radio, RadioGroup } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import LandingPageHeader from './components/LandingPageHeader'

const pricing = {
    frequencies: [
        { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
        { value: 'annually', label: 'Annually', priceSuffix: '/year' },
    ],
    tiers: [
        {
            name: 'Alpha',
            id: 'tier-starter',
            href: '/signup',
            price: { monthly: '$9.99', annually: '$90' },
            description: 'Access our Alpha version for free.',
            features: ['All Crypto pairs', 'Up to 10 sessions', 'Basic analytics', 'Up to 5 strategies', '24-hour support response time'],
            mostPopular: true,
            available: true,
        },
        {
            name: 'Advanced',
            id: 'tier-advanced',
            href: '/signup',
            price: { monthly: '$29.99', annually: '$280' },
            description: 'A plan that strengthens your trading skills.',
            features: [
                'All pairs',
                'Unlimited sessions',
                'Unlimited strategies',
                'Advanced analytics',
                '24-hour support response time',
                'Basic Community Access',
            ],
            mostPopular: false,
            available: false,
        },
        {
            name: 'Pro',
            id: 'tier-pro',
            href: '/signup',
            price: { monthly: '$45', annually: '$400' },
            description: 'The complete plan to maximize your profits.',
            features: [
                'All pairs',
                'Unlimited sessions',
                'Unlimited strategies',
                'Pro analytics',
                '1-hour, dedicated support response time',
                'Custom reporting tools',
                'Access to Dev team, and give your opinion on new features'
            ],
            mostPopular: false,
            available: false
        },
    ],
}
const faqs = [
    {
        id: 1,
        question: "What is backtesting, and why is it important?",
        answer:
            "Backtesting is a process of testing trading strategies using historical data to evaluate their performance and identify potential flaws. It's essential because it allows traders to assess the viability of their strategies before risking real money.",
    },
    {
        id: 2,
        question: "What kind of data do I need for backtesting?",
        answer:
            "Trader-Lab provides historical data on the markets you want to trade, including prices, volumes, and relevant indicators. Trader-Lab ensures the data is accurate, reliable, and covers a sufficient time period.",
    },
    {
        id: 3,
        question: "Why choose Trader-Lab backtesting platform?",
        answer:
            "Trader-Lab has large scale of historical data, simulation capabilities, performance analysis, ease of use, cost affordability, and integration with other trading tools.",
    },
    {
        id: 4,
        question: "Can I use backtesting to guarantee future profits?",
        answer:
            "No, backtesting cannot guarantee future profits. Past performance is not indicative of future results, and market conditions can change.",
    },
    {
        id: 5,
        question: "How often should I backtest my strategies?",
        answer:
            "It's recommended to backtest your strategies regularly, especially when making significant changes or encountering new market conditions.",
    },
    {
        id: 6,
        question: "Can I use backtesting to optimize my risk management?",
        answer:
            "Yes, backtesting can help you identify potential risk factors and test different risk management strategies to minimize losses.",
    },
]
const footerNavigation = {
    solutions: [
        { name: 'Marketing', href: '#' },
        { name: 'Analytics', href: '#' },
        { name: 'Commerce', href: '#' },
        { name: 'Insights', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Status', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
    social: [
        {
            name: 'X',
            href: 'https://x.com/lois_code',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
                </svg>
            ),
        },
    ],
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
    const [frequency, setFrequency] = useState(pricing.frequencies[0])

    return (
        <div className="bg-gray-900">
            {/* Header */}
            <LandingPageHeader />

            <main>
                {/* Pricing section */}
                <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h1 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h1>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                            Pricing plans
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                        Choose an affordable plan that’s packed with the best features for your needs
                    </p>
                    <div className="mt-16 flex justify-center">
                        <fieldset aria-label="Payment frequency">
                            <RadioGroup
                                value={frequency}
                                onChange={setFrequency}
                                className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
                            >
                                {pricing.frequencies.map((option) => (
                                    <Radio
                                        key={option.value}
                                        value={option}
                                        className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-indigo-500"
                                    >
                                        {option.label}
                                    </Radio>
                                ))}
                            </RadioGroup>
                        </fieldset>
                    </div>
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {pricing.tiers.map((tier) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                                    'rounded-3xl p-8 xl:p-10',
                                )}
                            >
                                <div className="flex items-center justify-between gap-x-4">
                                    <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                                        {tier.name}
                                    </h2>
                                    {tier.mostPopular ? (
                                        <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                            Early Access
                                        </p>
                                    ) : null}
                                </div>
                                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                                <p className="mt-6 flex items-baseline gap-x-1">

                                    <span className={classNames(tier.available ? "line-through decoration-gray-50" : "", "text-4xl font-bold tracking-tight text-gray-50")}>{frequency.value == "annually" ? tier.price.annually : tier.price.monthly}</span>
                                    <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>

                                    {tier.available
                                        ? <>
                                            <span className="text-4xl ml-3 font-bold tracking-tight text-white">$0</span>
                                            <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                                        </>
                                        : null}
                                </p>

                                <a
                                    href={tier.available ? tier.href : undefined}
                                    aria-describedby={tier.id}
                                    aria-disabled={!tier.available}
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                                            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                        'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                                    )}
                                >
                                    {tier.available ? "Try for Free" : "Coming Soon"}
                                </a>
                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Testimonial section */}
                {/* <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-56 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                            <img alt="" src="https://tailwindui.com/img/logos/tuple-logo-white.svg" className="h-12 self-start" />
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="text-lg leading-8 text-white">
                                    <p>
                                        “Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare
                                        arcu gravida natoque erat et cursus tortor consequat at. Vulputate gravida sociis enim nullam
                                        ultricies habitant malesuada lorem ac. Tincidunt urna dui pellentesque sagittis.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        className="h-14 w-14 rounded-full bg-gray-800"
                                    />
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Judith Black</div>
                                        <div className="mt-1 text-gray-400">CEO of Tuple</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                            <img alt="" src="https://tailwindui.com/img/logos/reform-logo-white.svg" className="h-12 self-start" />
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="text-lg leading-8 text-white">
                                    <p>
                                        “Excepteur veniam labore ullamco eiusmod. Pariatur consequat proident duis dolore nulla veniam
                                        reprehenderit nisi officia voluptate incididunt exercitation exercitation elit. Nostrud veniam sint
                                        dolor nisi ullamco.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        className="h-14 w-14 rounded-full bg-gray-800"
                                    />
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Joseph Rodriguez</div>
                                        <div className="mt-1 text-gray-400">CEO of Reform</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div> */}

                {/* FAQ section */}
                <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-56 lg:px-8 mb-32">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
                    <p className="mt-6 max-w-2xl text-base leading-7 text-gray-300">
                        Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
                        <a href="mailto:support@zippytal.com?subject=Trader-Lab Question" className="font-semibold text-indigo-400 hover:text-indigo-300">
                            sending us an email
                        </a>{' '}
                        and we’ll get back to you as soon as we can.
                    </p>
                    <div className="mt-20">
                        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                            {faqs.map((faq) => (
                                <div key={faq.id}>
                                    <dt className="font-semibold leading-8 text-lg text-white">{faq.question}</dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-300">{faq.answer}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer aria-labelledby="footer-heading" className="relative">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
                    <div className="border-t border-white/10 pt-8 md:flex md:items-start md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            {footerNavigation.social.map((item) => (
                                <a key={item.name} href={item.href} target='_blank' className="text-gray-500 hover:text-gray-400">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon aria-hidden="true" className="h-6 w-6" />
                                </a>
                            ))}
                        </div>
                        <div className='flex flex-col space-y-4'>
                            <div className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                                &copy; 2024 Trader-Lab, Inc. All rights reserved.
                            </div>
                            <div className=" text-xs leading-4 max-w-3xl text-justify text-gray-400 md:order-1 md:mt-0">
                                The information provided on this platform is for informational purposes only and does not constitute financial advice. Past performance is not indicative of future results. Investing involves risk, and there is no guarantee of profits. It is essential to conduct thorough research and consider your own financial situation before making any investment decisions.

                                This platform does not provide investment recommendations or advice. Users are solely responsible for their own investment decisions and should consult with a qualified financial advisor if necessary.
                            </div>
                        </div>

                    </div>
                </div>
            </footer>

            {/* Footer */}
            {/* <footer aria-labelledby="footer-heading" className="mt-24 sm:mt-56">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
                    <div className="border-t border-white/10 pt-16 sm:pt-24 xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.solutions.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm font-semibold leading-6 text-white">Support</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.support.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.company.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.legal.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 xl:mt-0">
                            <h3 className="text-sm font-semibold leading-6 text-white">Subscribe to our newsletter</h3>
                            <p className="mt-2 text-sm leading-6 text-gray-300">
                                The latest news, articles, and resources, sent to your inbox weekly.
                            </p>
                            <form className="mt-6 sm:flex sm:max-w-md">
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email-address"
                                    type="email"
                                    required
                                    placeholder="Enter your email"
                                    autoComplete="email"
                                    className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                                />
                                <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                                    <button
                                        type="submit"
                                        className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
                        <div className="flex space-x-6 md:order-2">
                            {footerNavigation.social.map((item) => (
                                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon aria-hidden="true" className="h-6 w-6" />
                                </a>
                            ))}
                        </div>
                        <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                            &copy; 2020 Your Company, Inc. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer> */}
        </div>
    )
}