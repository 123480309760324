import { atom } from "recoil";
import BacktestingManager from "./manager/backtesting_manager";
import IBacktestingManagerBase from "./manager/backtesting_manager_base";
import BacktestingAPI from "./api/backtesting_api";

const newBacktestingManager = (): IBacktestingManagerBase => {
    const full = window.location.protocol + '//' + window.location.hostname;
    const api = new BacktestingAPI(full, window.location.port);

    return new BacktestingManager(api);
}

export const backtestingManagerState = atom({
    key: "BacktestingManagerState",
    default: newBacktestingManager()
});