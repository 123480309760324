import { atom } from "recoil";
import StatisticsAPI from "./api/statistics_api";
import StatisticsManager from "./manager/statistics_manager";
import IStatisticsManagerBase from "./manager/statistics_manager_base";

const newStatisticsManager = (): IStatisticsManagerBase => {
    const full = window.location.protocol + '//' + window.location.hostname;
    const api = new StatisticsAPI(full, window.location.port);

    return new StatisticsManager(api);
}

export const statisticsManagerState = atom({
    key: "StatisticsManagerState",
    default: newStatisticsManager()
});