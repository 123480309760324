import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/20/solid"
import { ResolutionString, widget } from "charting_library";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { sessionOrdersSelector } from "../../../../../state/backtesting/selectors/orders_selectors";
import { useLoaderData, useNavigate } from "react-router-dom";
import replayDatafeedState from "../../../../../state/replay/atoms/replay_datafeed_state";
import replayTimeframeManagerState from "../../../../../state/replay/timeframe_manager/replay_timeframe_manager_state";
import { replaySaveLoadAdapterState } from "../../../../../state/replay/atoms/replay_save_load_adapter_state";
import replayChartState from "../../../../../state/replay/atoms/replay_chart_state";
import { backtestingManagerState } from "../../../../../state/backtesting/backtesting_manager_state";
import { lastBarState } from "../../../../../state/replay/atoms/lastBar";
import { sessionsState } from "../../../../../state/backtesting/atoms/sessions";
import { currentSessionState } from "../../../../../state/backtesting/atoms/current_session";
import { tradesState } from "../../../../../state/backtesting/atoms/trades";
import { ordersState } from "../../../../../state/backtesting/atoms/orders";
import { symbolsState } from "../../../../../state/backtesting/atoms/symbols";
import { convertResolutionToSeconds } from "../../../../../state/replay/manager/replay_manager";
import Quill from "quill";
import TextEditor from "../../../../../utils/quill/Editor";
import TradeSearchBar from "./components/TradeSearchBar";
import { currentTradeState } from "../../../../../state/backtesting/atoms/current_trade";
import { tradesSelector } from "../../../../../state/backtesting/selectors/trades_selector";
import { tradeStatisticsState } from "../../../../../state/statistics/atoms/trade_statistics";
import { statisticsManagerState } from "../../../../../state/statistics/statistics_manager_state";
import { TradeStatistics } from "../../../../../state/statistics/models/statistics_models";
import { Session, Trade } from "../../../../../state/backtesting/models/backtesting_models";
import { getAmountTraded, getExpectedProfit, getGrossLoss, getGrossProfit, getIdealRiskRewardRatio, getMAE, getMFE, getMistakes, getPlannedRMultiple, getRating, getRealizedRMultiple, getRiskRewardRatio, getValueRisked } from "./utils/trade_kpi";
import { strategiesState } from "../../../../../state/backtesting/atoms/strategies";
import "./utils/rating.css";
import TradeStats from "./components/TradeStats";
import TradeRules from "./components/TradeRules";
import TradeExecutions from "./components/TradeExecutions";

const Delta = Quill.import('delta');

export default function TradeAnalysis() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const replayDatafeed = useRecoilValue(replayDatafeedState);
    const replayTimeframe = useRecoilValue(replayTimeframeManagerState);
    const replaySaveLoadAdapter = useRecoilValue(replaySaveLoadAdapterState);
    const setReplayChartState = useSetRecoilState(replayChartState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const statisticsManager = useRecoilValue(statisticsManagerState);
    const [lastBar, setLastBar] = useRecoilState(lastBarState);
    const setStrategies = useSetRecoilState(strategiesState);
    const setSessions = useSetRecoilState(sessionsState);
    const strategies = useRecoilValue(strategiesState)
    const [currentSession, setCurrentSession] = useRecoilState(currentSessionState);
    const [trades, setTrades] = useRecoilState(tradesState);
    const [tradeStats, setTradeStats] = useRecoilState(tradeStatisticsState);
    const [currentTrade, setCurrentTrade] = useRecoilState(currentTradeState)
    const sessionTrades = useRecoilValue(tradesSelector(currentSession?.id ?? ""))
    const setOrders = useSetRecoilState(ordersState);
    const sessionOrders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""))
    const [loadPositionsDrawing, setLoadPositionDrawing] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [bottomBarOpen, setBottomBarOpen] = useState(true);
    const [replayTime, setReplayTime] = useState<number | undefined>(undefined);
    const [resolutionSeconds, setResolutionSeconds] = useState(1);
    const [symbols, setSymbols] = useRecoilState(symbolsState);
    const sessionId = useRef("");
    const currentTradeId = useRef("");
    const stopDate = useRef<number | undefined>(undefined);

    const rt = useRef<number | undefined>(undefined);

    const [tabs, setTabs] = useState([
        { name: 'Stats', href: '#', current: true, component: <TradeStats /> },
        { name: 'Strategy', href: '#', current: false, component: <TradeRules /> },
        { name: 'Executions', href: '#', current: false, component: <TradeExecutions /> },
        // { name: 'Attachements', href: '#', current: false },
    ])

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }

    const quillRef = useRef();

    const [range, setRange] = useState();
    const [lastChange, setLastChange] = useState();

    useEffect(() => {
        const path = window.location.pathname.split("/");
        const sessionId = path[3];
        const tradeId = path[5];

        console.log(path, sessionId, tradeId)

        backtestingManager.listStrategies(setStrategies)

        backtestingManager.listAllPairs(setSymbols)
            .then(() => backtestingManager.getSession(setSessions, sessionId).then(async (s) => {
                const trades = await backtestingManager.listSessionTrades(setTrades, s.id);
                const ct = trades.find((trade) => trade.id == tradeId);
                await statisticsManager.generateTradeStatistics(setTradeStats, ct!.id);
                setCurrentSession(s);
                console.log("setting current trade")
                if (ct != undefined) {
                    setCurrentTrade(ct);
                }
            }));

        return () => {
            replaySaveLoadAdapter.loadingAllCharts = false;
            replaySaveLoadAdapter.sessionLastChartId = undefined;
            //setCurrentTrade(undefined)
        }
    }, []);

    useEffect(() => {
        if (currentTrade != undefined && currentSession != undefined) {
            statisticsManager.generateTradeStatistics(setTradeStats, currentTrade.id)
        }
    }, [currentTrade])

    useEffect(() => {
        console.log(currentTrade, currentSession, "current trade and session")
        if (currentTrade != undefined && currentSession != undefined) {
            if (currentTradeId.current == currentTrade.id) {
                if (sessionId.current == currentSession.id) return;
            }

            stopDate.current = currentTrade.entryTime;

            currentTradeId.current = currentTrade.id;
            sessionId.current = currentSession.id;

            replayDatafeed.clearReplay();

            backtestingManager.listSessionTrades(setTrades, currentSession.id);
            backtestingManager.listOrders(setOrders, currentSession.id);
            replayDatafeed.setTimeframe(currentTrade.entryTime, replayTimeframe.from);
            replayDatafeed.setEndDate(currentSession.endDate);

            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
            console.log(currentSession.lastChartLayoutId)
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")

            replaySaveLoadAdapter.loadingAllCharts = false;
            replaySaveLoadAdapter.sessionLastChartId = currentSession.lastChartLayoutId;


            console.log(currentSession.resolution);

            const chartView = new widget({
                theme: "dark",
                container: "chartContainer",
                locale: "en",
                autosize: true,
                library_path: "/charting_library/",
                datafeed: replayDatafeed,
                save_load_adapter: replaySaveLoadAdapter,
                symbol: currentSession.pairs[0],
                interval: currentSession.resolution != "" ? currentSession.resolution as ResolutionString : "1" as ResolutionString,
                fullscreen: false,
                debug: true,
                auto_save_delay: 15,
                load_last_chart: true,
                // enabled_features: ["seconds_resolution"],
            });

            setReplayChartState(chartView);
            replayDatafeed.setCurrentChart(chartView);

            chartView.subscribe("onTick", (bar) => {
                console.log(bar)
                console.log("///////////////////////////////============>", bar.time, rt.current)

                if (rt.current != undefined) {
                    console.log(bar.time)
                    rt.current = bar.time * 1000;
                    setReplayTime(bar.time * 1000)

                    if (bar.time * 1000 >= (stopDate.current ?? currentSession.stopDate)) {
                        rt.current = undefined;
                        setReplayTime(undefined);
                    }
                    return;
                }

                if (stopDate.current! < bar.time * 1000) {
                    console.log("****************************************")
                    console.log("updating stop date", stopDate.current, bar.time * 1000)
                    console.log("****************************************")

                    stopDate.current = bar.time * 1000;
                    bar.time *= 1000 * 1000;
                    setLastBar(bar);
                }
            });

            chartView.onChartReady(() => {
                console.log("the chart is ready")
                setLoadPositionDrawing(true);
                setResolutionSeconds(convertResolutionToSeconds(chartView.activeChart().resolution()));

                chartView.activeChart().onDataLoaded().subscribe(null, () => {
                    console.log(replayDatafeed.lastBar, "here is the last bar")
                    if (rt.current != undefined) return;

                    setLastBar(replayDatafeed.lastBar)
                })

                chartView.activeChart().onIntervalChanged().subscribe(null, (resolution, params) => {
                    setResolutionSeconds(convertResolutionToSeconds(resolution));
                });
            })

            chartView.onChartReady(() => {
                chartView.activeChart().onIntervalChanged().subscribe(null, (resolution, timeFrameParameters) => {
                    backtestingManager.updateSessionResolution(setSessions, currentSession.id, resolution);
                }, false);
            });
        }
    }, [currentTrade, currentSession]);



    return (
        <div className="flex flex-col">
            <div className="flex bg-gray-900 h-20 w-full items-center justify-between px-12 border-b border-b-gray-800">
                <div className="flex items-center space-x-3">
                    <button
                        type="button"
                        className="rounded-md bg-gray-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                        onClick={async () => {
                            replayDatafeed.updateSpeed(1000);
                            currentSession?.strategyId != undefined ? navigate(`/app/strategy/${currentSession.strategyId}/trades`) : navigate("/app");
                        }}
                    >
                        Exit
                    </button>

                    <div className="items-center">
                        <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                                type="button"
                                className="relative inline-flex items-center rounded-l-md bg-gray-600 px-2 py-2 text-gray-50 ring-1 ring-inset ring-gray-500 hover:bg-gray-500 focus:z-10"
                                onClick={() => {
                                    const i = sessionTrades.findIndex((trade) => trade.id == currentTrade?.id);

                                    if (i != -1 && i > 0) {
                                        setCurrentTrade(sessionTrades[i - 1]);
                                        navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${sessionTrades[i - 1].id}`);
                                    }
                                }}
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon aria-hidden="true" className="size-5" />
                            </button>
                            <button
                                type="button"
                                className="relative -ml-px inline-flex items-center rounded-r-md bg-gray-600 px-2 py-2 text-gray-50 ring-1 ring-inset ring-gray-500 hover:bg-gray-500 focus:z-10"
                                onClick={() => {
                                    const i = sessionTrades.findIndex((trade) => trade.id == currentTrade?.id);

                                    if (i != -1 && i < (sessionTrades.length - 1)) {
                                        setCurrentTrade(sessionTrades[i + 1]);
                                        navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${sessionTrades[i + 1].id}`);
                                    }
                                }}
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon aria-hidden="true" className="size-5" />
                            </button>
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl text-gray-50">{currentSession?.name ?? "-"} - {currentSession?.pairs}</p>
                        <p className="text-gray-300 text-xs">
                            {currentTrade?.entryTime != undefined ? new Date(currentTrade.entryTime * 1000).toUTCString() : "-"} {"->"} {currentTrade?.exitTime != undefined ? new Date(currentTrade.exitTime * 1000).toUTCString() : "-"}
                        </p>
                    </div>
                </div>
                <div className="basis-1/4 items-center">
                    <TradeSearchBar />
                </div>
                <div className="flex flex-row space-x-3">
                    <button
                        type="button"
                        className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        Mark Trade As Reviewed
                    </button>
                    <button
                        type="button"
                        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                        Replay
                    </button>
                </div>
            </div>
            <div>
            </div>
            <div className="flex">
                <div className="sticky lg:inset-y-20 lg:z-50 lg:flex lg:w-96 lg:flex-col h-screen overflow-auto bg-gray-950">
                    <div className="border-b border-gray-800 pb-5 sm:pb-0">
                        <div className="mt-3 sm:mt-4">
                            <div className="grid grid-cols-1 sm:hidden">
                                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                <select
                                    defaultValue={tabs.find((tab) => tab.current)?.name}
                                    aria-label="Select a tab"
                                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                                <ChevronDownIcon
                                    aria-hidden="true"
                                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                                />
                            </div>
                            <div className="hidden sm:block">
                                <nav className="-mb-px flex justify-evenly space-x-8">
                                    {tabs.map((tab) => (
                                        <span
                                            key={tab.name}
                                            onClick={() => {
                                                setTabs(tabs.map((t) => {
                                                    if (t.name == tab.name) {
                                                        t.current = true;
                                                    } else {
                                                        t.current = false;
                                                    }

                                                    return t;
                                                }))
                                            }}
                                            aria-current={tab.current ? 'page' : undefined}
                                            className={classNames(
                                                tab.current
                                                    ? 'border-indigo-300 text-indigo-300'
                                                    : 'border-transparent text-gray-100 hover:border-gray-500 hover:text-gray-50',
                                                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer',
                                            )}
                                        >
                                            {tab.name}
                                        </span>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div>
                        {tabs.find((tab) => tab.current)?.component ?? null}
                    </div>
                </div>
                <div className="flex flex-col overflow-auto bg-black w-full">
                    <div id='chartContainer' className={classNames(bottomBarOpen ? 'basis-5/6' : 'basis-5/6')}></div>
                    {
                        currentSession != undefined
                            ? <input
                                type="range"
                                min={currentSession.startDate}
                                max={currentSession.endDate}
                                step={resolutionSeconds ?? 1}
                                value={replayTime ?? (lastBar?.time != undefined ? lastBar.time / 1000 : (stopDate.current ?? currentSession.stopDate))}
                                onChange={(e) => {
                                    replayDatafeed.pauseReplay();
                                    //handleReplayBackward(parseInt(e.target.value));
                                }}

                                onMouseUp={(e) => {
                                    //handleConfirmReplayBackward(parseInt(e.currentTarget.value));
                                }} />
                            : null
                    }
                    <div className='flex flex-row justify-center items-center space-x-4 basis-0 bg-gray-900 border-b border-b-gray-800'>
                        <button
                            type="button"
                            className="rounded bg-white/10 my-2 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                            onClick={() => replayDatafeed.pauseReplay()}
                        >
                            Pause
                        </button>
                        <button
                            type="button"
                            className="rounded bg-white/10 my-2 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                            onClick={() => replayDatafeed.startReplay()}
                        >
                            Play
                        </button>

                        <input type="range" min={1} max={20} defaultValue={1} onChange={async (e) => replayDatafeed.updateSpeed(1000 / parseInt(e.target.value))} />
                        <p className='text-white'>Play Speed</p>
                    </div>
                    <div className="bg-black text-gray-50 basis-1/6 flex-initial">
                        <TextEditor />
                    </div>
                </div>
            </div>
        </div >
    )
}