import { StrategyStatistics } from "../../../../../../state/statistics/models/statistics_models";
import { formatDuration } from "../../../../../../utils/date";

export function strategyKPI(strategyStatistics: StrategyStatistics) {
    return [
        {
            name: "Net Profit",
            value: strategyStatistics.netProfit,
            display: `${Math.round(strategyStatistics.netProfit * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: strategyStatistics.grossProfit,
            display: `${Math.round(strategyStatistics.grossProfit * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: strategyStatistics.grossLoss,
            display: `${Math.round(strategyStatistics.grossLoss * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Return On Investment",
            value: strategyStatistics.returnOnInvestment,
            display: `${Math.round(strategyStatistics.returnOnInvestment * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Compound Annual Growth Rate",
            value: strategyStatistics.compoundAnnualGrowthRate,
            display: `${Math.round(strategyStatistics.compoundAnnualGrowthRate * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: strategyStatistics.winRate,
            display: `${Math.round(strategyStatistics.winRate * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Annualized Volatility",
            value: strategyStatistics.annualizedVolatility,
            display: `${Math.round(strategyStatistics.annualizedVolatility * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: strategyStatistics.averageWin,
            display: `${Math.round(strategyStatistics.averageWin * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: strategyStatistics.averageLoss,
            display: `${Math.round(strategyStatistics.averageLoss * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: strategyStatistics.profitFactor,
            display: `${Math.round(strategyStatistics.profitFactor * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward Ratio",
            value: strategyStatistics.averageRiskReward,
            display: `${Math.round(strategyStatistics.averageRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward Ratio",
            value: strategyStatistics.averageIdealRiskReward,
            display: `${Math.round(strategyStatistics.averageIdealRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Drawdown",
            value: strategyStatistics.maxDrawdown,
            display: `${Math.round(strategyStatistics.maxDrawdown * 100) / 100} USD / ${Math.round(strategyStatistics.maxDrawdownPercentage * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Drawup",
            value: strategyStatistics.maxDrawup,
            display: `${Math.round(strategyStatistics.maxDrawup * 100) / 100} USD / ${Math.round(strategyStatistics.maxDrawupPercentage * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Calmar Ratio",
            value: strategyStatistics.calmarRatio,
            display: `${Math.round(strategyStatistics.calmarRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Sharpe Ratio",
            value: strategyStatistics.sharpeRatio,
            display: `${Math.round(strategyStatistics.sharpeRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Sortino Ratio",
            value: strategyStatistics.sortinoRatio,
            display: `${Math.round(strategyStatistics.sortinoRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Omega Ratio",
            value: strategyStatistics.omegaRatio,
            display: `${Math.round(strategyStatistics.omegaRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Value At Risk",
            value: strategyStatistics.valueAtRisk,
            display: `${Math.round(strategyStatistics.valueAtRisk * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Expected Shortfall",
            value: strategyStatistics.conditionalValueAtRisk,
            display: `${Math.round(strategyStatistics.conditionalValueAtRisk * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Tail Ratio",
            value: strategyStatistics.tailRatio,
            display: `${Math.round(strategyStatistics.tailRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: strategyStatistics.zScore,
            display: `${Math.round(strategyStatistics.zScore * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Kurtosis",
            value: strategyStatistics.kurtosis,
            display: `${Math.round(strategyStatistics.kurtosis * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Skewness",
            value: strategyStatistics.skewness,
            display: `${Math.round(strategyStatistics.skewness * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward Ratio",
            value: strategyStatistics.averageRiskReward,
            display: `${Math.round(strategyStatistics.averageRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Trades",
            value: strategyStatistics.totalNumberOfTrades,
            display: `${strategyStatistics.totalNumberOfTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Order",
            value: strategyStatistics.totalNumberOfOrder,
            display: `${strategyStatistics.totalNumberOfOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Buy Order",
            value: strategyStatistics.totalNumberOfBuyOrder,
            display: `${strategyStatistics.totalNumberOfBuyOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Sell Order",
            value: strategyStatistics.totalNumberOfSellOrder,
            display: `${strategyStatistics.totalNumberOfSellOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: strategyStatistics.winningTrades,
            display: `${strategyStatistics.winningTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: strategyStatistics.losingTrades,
            display: `${strategyStatistics.losingTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Consecutive Wins",
            value: strategyStatistics.averageConsecutiveWins,
            display: `${strategyStatistics.averageConsecutiveWins}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Consecutive Losses",
            value: strategyStatistics.averageConsecutiveLosses,
            display: `${strategyStatistics.averageConsecutiveLosses}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Consecutive Wins",
            value: strategyStatistics.maxConsecutiveWins,
            display: `${strategyStatistics.maxConsecutiveWins}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Consecutive Losses",
            value: strategyStatistics.maxConsecutiveLosses,
            display: `${strategyStatistics.maxConsecutiveLosses}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: strategyStatistics.timeInMarket,
            display: `${formatDuration(strategyStatistics.timeInMarket)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: strategyStatistics.averageTradeDuration,
            display: `${formatDuration(strategyStatistics.averageTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: strategyStatistics.maximumTradeDuration,
            display: `${formatDuration(strategyStatistics.maximumTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: strategyStatistics.minimumTradeDuration,
            display: `${formatDuration(strategyStatistics.minimumTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ];
}

export function strategyKPILongSide(strategyStatistics: StrategyStatistics) {
    return [
        {
            name: "Net Profit",
            value: strategyStatistics.netProfitLongSide,
            display: `${Math.round(strategyStatistics.netProfitLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: strategyStatistics.grossProfitLongSide,
            display: `${Math.round(strategyStatistics.grossProfitLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: strategyStatistics.grossLossLongSide,
            display: `${Math.round(strategyStatistics.grossLossLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: strategyStatistics.winRateLongSide,
            display: `${Math.round(strategyStatistics.winRateLongSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Loss Rate",
            value: strategyStatistics.lossRateLongSide,
            display: `${Math.round(strategyStatistics.lossRateLongSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: strategyStatistics.averageWinLongSide,
            display: `${Math.round(strategyStatistics.averageWinLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: strategyStatistics.averageLossLongSide,
            display: `${Math.round(strategyStatistics.averageLossLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: strategyStatistics.profitFactorLongSide,
            display: `${Math.round(strategyStatistics.profitFactorLongSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: strategyStatistics.zScoreLongSide,
            display: `${Math.round(strategyStatistics.zScoreLongSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward",
            value: strategyStatistics.averageRiskRewardLongTrades,
            display: `${Math.round(strategyStatistics.averageRiskRewardLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: strategyStatistics.averageIdealRiskRewardLongTrades,
            display: `${Math.round(strategyStatistics.averageIdealRiskRewardLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number Of Trades",
            value: strategyStatistics.totalNumberOfLongTrades,
            display: `${Math.round(strategyStatistics.totalNumberOfLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: strategyStatistics.winningLongTrades,
            display: `${Math.round(strategyStatistics.winningLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: strategyStatistics.losingLongTrades,
            display: `${Math.round(strategyStatistics.losingLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: strategyStatistics.timeInMarketLongTrades,
            display: `${formatDuration(strategyStatistics.timeInMarketLongTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: strategyStatistics.averageLongTradeDuration,
            display: `${formatDuration(strategyStatistics.averageLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: strategyStatistics.maximumLongTradeDuration,
            display: `${formatDuration(strategyStatistics.maximumLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: strategyStatistics.minimumLongTradeDuration,
            display: `${formatDuration(strategyStatistics.minimumLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function strategyKPIShortSide(strategyStatistics: StrategyStatistics) {
    return [
        {
            name: "Net Profit",
            value: strategyStatistics.netProfitShortSide,
            display: `${Math.round(strategyStatistics.netProfitShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: strategyStatistics.grossProfitShortSide,
            display: `${Math.round(strategyStatistics.grossProfitShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: strategyStatistics.grossLossShortSide,
            display: `${Math.round(strategyStatistics.grossLossShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: strategyStatistics.winRateShortSide,
            display: `${Math.round(strategyStatistics.winRateShortSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Loss Rate",
            value: strategyStatistics.lossRateShortSide,
            display: `${Math.round(strategyStatistics.lossRateShortSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: strategyStatistics.averageWinShortSide,
            display: `${Math.round(strategyStatistics.averageWinShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: strategyStatistics.averageLossShortSide,
            display: `${Math.round(strategyStatistics.averageLossShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: strategyStatistics.profitFactorShortSide,
            display: `${Math.round(strategyStatistics.profitFactorShortSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: strategyStatistics.zScoreShortSide,
            display: `${Math.round(strategyStatistics.zScoreShortSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward",
            value: strategyStatistics.averageRiskRewardShortTrades,
            display: `${Math.round(strategyStatistics.averageRiskRewardShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: strategyStatistics.averageIdealRiskRewardShortTrades,
            display: `${Math.round(strategyStatistics.averageIdealRiskRewardShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number Of Trades",
            value: strategyStatistics.totalNumberOfShortTrades,
            display: `${Math.round(strategyStatistics.totalNumberOfShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: strategyStatistics.winningShortTrades,
            display: `${Math.round(strategyStatistics.winningShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: strategyStatistics.losingShortTrades,
            display: `${Math.round(strategyStatistics.losingShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: strategyStatistics.timeInMarketShortTrades,
            display: `${formatDuration(strategyStatistics.timeInMarketShortTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: strategyStatistics.averageShortTradeDuration,
            display: `${formatDuration(strategyStatistics.averageShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: strategyStatistics.maximumShortTradeDuration,
            display: `${formatDuration(strategyStatistics.maximumShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: strategyStatistics.minimumShortTradeDuration,
            display: `${formatDuration(strategyStatistics.minimumShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function strategyKPIWinningSide(strategyStatistics: StrategyStatistics) {
    return [
        {
            name: "Average Risk Reward",
            value: strategyStatistics.averageRiskRewardWinningTrades,
            display: `${Math.round(strategyStatistics.averageRiskRewardWinningTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: strategyStatistics.averageIdealRiskRewardWinningTrades,
            display: `${Math.round(strategyStatistics.averageIdealRiskRewardWinningTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: strategyStatistics.timeInMarketWinningTrades,
            display: `${formatDuration(strategyStatistics.timeInMarketWinningTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: strategyStatistics.averageWinningTradeDuration,
            display: `${formatDuration(strategyStatistics.averageWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: strategyStatistics.maximumWinningTradeDuration,
            display: `${formatDuration(strategyStatistics.maximumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: strategyStatistics.minimumWinningTradeDuration,
            display: `${formatDuration(strategyStatistics.minimumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function strategyKPILosingSide(strategyStatistics: StrategyStatistics) {
    return [
        {
            name: "Average Risk Reward",
            value: strategyStatistics.averageRiskRewardLosingTrade,
            display: `${Math.round(strategyStatistics.averageRiskRewardLosingTrade * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: strategyStatistics.averageIdealRiskRewardLosingTrades,
            display: `${Math.round(strategyStatistics.averageIdealRiskRewardLosingTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: strategyStatistics.timeInMarketLosingTrades,
            display: `${formatDuration(strategyStatistics.timeInMarketLosingTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: strategyStatistics.averageLosingTradeDuration,
            display: `${formatDuration(strategyStatistics.averageLosingTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: strategyStatistics.maximumLosingTradeDuration,
            display: `${formatDuration(strategyStatistics.maximumLosingTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: strategyStatistics.minimumWinningTradeDuration,
            display: `${formatDuration(strategyStatistics.minimumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}