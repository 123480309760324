import { atom } from "recoil";
import ReplayDatafeed from "../replay_datafeed";
import ReplayDatafeedAPI from "../api/replay_datafeed_api";
import ReplayManager from "../manager/replay_manager";
import IReplayDatafeedAPI from "../api/replay_datafeed_api_base";
import IReplayManager from "../manager/replay_manager_base";
import BacktestingManager from "../../backtesting/manager/backtesting_manager";
import BacktestingAPI from "../../backtesting/api/backtesting_api";

const newReplayDatafeed = (): ReplayDatafeed<IReplayDatafeedAPI, IReplayManager> => {
    const full = window.location.protocol + '//' + window.location.hostname;

    const api = new ReplayDatafeedAPI(full, window.location.port);
    return new ReplayDatafeed(api, new ReplayManager(
        api,
        new BacktestingManager(new BacktestingAPI(full, window.location.port)))
    )
}

const replayDatafeedState = atom({
    key: "replayDatafeedState",
    default: newReplayDatafeed(),
})

export default replayDatafeedState;