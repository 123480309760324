'use client'

import { useState } from 'react'
import {
    ChartBarSquareIcon,
    Cog6ToothIcon,
    FolderIcon,
    GlobeAltIcon,
    ServerIcon,
    SignalIcon,
} from '@heroicons/react/24/outline'
import { useEffect } from "react";
import { Link, Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentManagerState } from "../../../../state/payment/payment_manager_state";
import { invoicesState } from "../../../../state/payment/atoms/invoices";
import { userState } from "../../../../state/user/atoms/user";
import { paymentMethodsState } from "../../../../state/payment/atoms/payment_methods";

// function Settings() {


//     return (
//         <div>
//             <h1 className="text-gray-50">User Info</h1>
//             <h5 className="text-gray-50">
//                 This is where you can update your info related to security or payment, you can also view all your invoices
//             </h5>
//             <br />
//             <div className="text-gray-50">
//                 <button onClick={() => navigate("/app")}>Back</button>
//             </div>
//             <div className="text-gray-50">
//                 <ul>
//                     <h4>
//                         Invoices:
//                     </h4>
//                     {invoices.map((invoice) => <li>{invoice.id} - {invoice.total} - {invoice.amount_due} - <a target="blank" href={invoice.hosted_invoice_url}>Detailed Invoice</a></li>)}
//                 </ul>
//                 <ul>
//                     <h4>
//                         Payment Methods:
//                     </h4>
//                     {payementMethods.map((paymentMethod) => <li>{paymentMethod.id} - {paymentMethod.type} - {paymentMethod.card?.brand} - {paymentMethod?.card?.country}- {paymentMethod?.card?.funding}- {paymentMethod?.card?.last4}</li>)}
//                 </ul>
//             </div>
//         </div>
//     )
// }




function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Settings() {
    const navigate = useNavigate()
    const loaderData = useLoaderData();
    const paymentManager = useRecoilValue(paymentManagerState);
    const user = useRecoilValue(userState);
    const [invoices, setInvoices] = useRecoilState(invoicesState);
    const [payementMethods, setPaymentMethods] = useRecoilState(paymentMethodsState);
    const [secondaryNavigation, setSecondaryNavigation] = useState([
        { name: 'Account', href: '/app/settings/account', current: window.location.pathname == '/app/settings/account' },
        { name: 'Billing', href: '/app/settings/billing', current: window.location.pathname == '/app/settings/billing' },
        { name: 'Data', href: '/app/settings/data', current: window.location.pathname == '/app/settings/data' },
    ]);

    useEffect(() => {
        console.log(loaderData);
        paymentManager.listInvoices(setInvoices, user.customerId);
        paymentManager.listPaymentMethods(setPaymentMethods, user.customerId);
    }, [user]);

    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-900">
        <body class="h-full">
        ```
      */}
            <div>
                <div className="xl:pl-72">
                    <main>
                        <h1 className="sr-only">Account Settings</h1>
                        <header className="border-b border-white/5">
                            {/* Secondary navigation */}
                            <nav className="flex overflow-x-auto py-4">
                                <ul
                                    role="list"
                                    className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
                                >
                                    {secondaryNavigation.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className={item.current ? 'text-indigo-400' : ''}
                                                onClick={() => {
                                                    setSecondaryNavigation(secondaryNavigation.map((s) => {
                                                        if (s.href == item.href) {
                                                            s.current = true;
                                                        } else {
                                                            s.current = false;
                                                        }
                                                        return s;
                                                    }))
                                                }}
                                            >
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </header>

                        {/* Settings forms */}
                        <div className="divide-y divide-white/5">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}