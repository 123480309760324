export default function TradeRules() {
    return (
        <div className="text-gray-100">
            <h3>
                Stoch RSI
            </h3>
            <div>
                Rules:
            </div>
        </div>
    )
}