export class Subscription {
    id: string;
    cancel_at_period_end: boolean;
    currency: string;
    current_period_end: number;
    current_period_start: number;
    customer: string;
    default_payment_method?: string;
    description?: string;
    latest_invoice?: string;
    pending_setup_intent?: string;
    status: string;
    created: number;
    start_date: number;
    trial_start?: number;
    trial_end?: number;

    constructor(id: string, cancel_at_period_end: boolean, currency: string, current_period_end: number, current_period_start: number, customer: string, status: string, created: number, start_date: number, default_payment_method?: string, description?: string, latest_invoice?: string, pending_setup_intent?: string, trial_start?: number, trial_end?: number) {
        this.id = id;
        this.cancel_at_period_end = cancel_at_period_end;
        this.currency = currency;
        this.current_period_end = current_period_end;
        this.current_period_start = current_period_start;
        this.customer = customer;
        this.default_payment_method = default_payment_method;
        this.description = description;
        this.latest_invoice = latest_invoice;
        this.pending_setup_intent = pending_setup_intent;
        this.status = status;
        this.created = created;
        this.start_date = start_date;
        this.trial_start = trial_start;
        this.trial_end = trial_end;
    }
}

export class Invoice {
    id: string;
    auto_advance: boolean;
    charge?: string;
    collection_method: string;
    currency: string;
    customer: string;
    amount_due: number;
    amount_paid: number;
    total: number;
    created: number;
    description?: string;
    hosted_invoice_url?: string;

    constructor(id: string, auto_advance: boolean, collection_method: string, currency: string, customer: string, amount_due: number, amount_paid: number, total: number, created: number, charge?: string, description?: string, hosted_invoice_url?: string) {
        this.id = id;
        this.auto_advance = auto_advance;
        this.charge = charge;
        this.collection_method = collection_method;
        this.currency = currency;
        this.customer = customer;
        this.amount_due = amount_due;
        this.amount_paid = amount_paid;
        this.total = total;
        this.created = created;
        this.description = description;
        this.hosted_invoice_url = hosted_invoice_url;
    }
}

export class PaymentMethod {
    id: string;
    type: string;
    created: number;
    card?: CardDetails;

    constructor(id: string, type: string, created: number, card?: CardDetails) {
        this.id = id;
        this.type = type;
        this.created = created;
        this.card = card;
    }
}

export class CardDetails {
    brand: string;
    country: string;
    exp_month: string;
    exp_year: string;
    fingerprint?: string;
    funding: string;
    last4: string;

    constructor(brand: string, country: string, exp_month: string, exp_year: string, funding: string, last4: string, fingerprint?: string) {
        this.brand = brand;
        this.country = country;
        this.exp_month = exp_month;
        this.exp_year = exp_year;
        this.fingerprint = fingerprint;
        this.funding = funding;
        this.last4 = last4;
    }
}

export class InitAddPaymentMethodResponse {
    client_secret: string;
    setup_intent_id: string;

    constructor(client_secret: string, setup_intent_id: string) {
        this.client_secret = client_secret;
        this.setup_intent_id = setup_intent_id;
    }
}

export class Product {
    id: string;
    active: boolean;
    default_price?: string;
    description?: string;
    name: string;

    constructor(id: string, active: boolean, name: string, default_price?: string, description?: string) {
        this.id = id;
        this.active = active;
        this.default_price = default_price;
        this.description = description;
        this.name = name;
    }
}

export class Price {
    id: string;
    active: boolean;
    currency: string;
    nickname?: string;
    product: string;
    recurring: RecurringPriceSetting;
    type: string;
    unit_amount: string;

    constructor(id: string, active: boolean, currency: string, product: string, recurring: RecurringPriceSetting, type: string, unit_amount: string, nickname?: string) {
        this.id = id;
        this.active = active;
        this.currency = currency;
        this.nickname = nickname;
        this.product = product;
        this.recurring = recurring;
        this.type = type;
        this.unit_amount = unit_amount;
    }
}

export class RecurringPriceSetting {
    aggregate_usage?: string;
    interval: string;
    interval_count: number;
    meter?: string;
    usage_type: string;

    constructor(interval: string, interval_count: number, usage_type: string, aggregate_usage?: string, meter?: string) {
        this.aggregate_usage = aggregate_usage;
        this.interval = interval;
        this.interval_count = interval_count;
        this.meter = meter;
        this.usage_type = usage_type;
    }
}