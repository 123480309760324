import { atom } from "recoil";
import AuthenticationAPI from "./api/authentication_api";
import AuthenticationManager from "./manager/authentication_manager";
import IAuthenticationManagerBase from "./manager/authentication_manager_base";

const newAuthenticationManager = (): IAuthenticationManagerBase => {
    const full = window.location.protocol + '//' + window.location.hostname;
    const api = new AuthenticationAPI(full, window.location.port);

    return new AuthenticationManager(api);
}

export const authenticationManagerState = atom({
    key: "AuthenticationState",
    default: newAuthenticationManager()
});