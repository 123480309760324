export class RegisterResponse {
    userId: string;
    token: string;

    constructor(userId: string, authenticationkey: string) {
        this.userId = userId;
        this.token = authenticationkey;
    }
}

export class LoginResponse {
    userId: string;
    token: string;

    constructor(userId: string, authenticationkey: string) {
        this.userId = userId;
        this.token = authenticationkey;
    }
}

export class AuthenticationResponse {
    userId: string;
    token: string;

    constructor(userId: string, authenticationkey: string) {
        this.userId = userId;
        this.token = authenticationkey;
    }
}

