import axios, { AxiosInstance } from "axios";
import IUserAPIBase from "./users_api_base";
import { CreateUserResponse, User } from "../models/users_models";
import { signWithToken } from "../../../utils/crypto";

export default class UsersAPI implements IUserAPIBase {
  protected instance: AxiosInstance;

  constructor(domain: string, port: string) {
    this.instance = axios.create({
      baseURL: `${domain}:${port}/api/users`,
    });
  }

  async createUser(authenticationMethod: string, token?: string, email?: string, password?: string): Promise<CreateUserResponse> {
    const res = await this.instance.post("/create", {
      token,
      email,
      password,
      authenticationMethod,
    });

    const createUserResponse: CreateUserResponse = Object.assign(
      new CreateUserResponse(
        new User("", "", "", 0, 0, false, false, ""),
        ""
      ),
      res.data
    );

    return createUserResponse;
  }

  async getUser(): Promise<User> {
    const { userId, signature, payload } = await signWithToken();
    const res = await this.instance.get(`/${userId}`, {
      headers: {
        "X-USER-ID": userId,
        "X-REQUEST-SIGNATURE": signature,
        "X-REQUEST-PAYLOAD": payload,
      },
    });

    const user: User = Object.assign(
      new User("", "", "", 0, 0, false, false, ""),
      res.data
    );

    return user;
  }

  async updateEmail(newEmail: string, password: string): Promise<void> {
    const { userId, signature, payload } = await signWithToken();
    const res = await this.instance.patch(
      `/${userId}`,
      {
        newEmail,
        password,
      },
      {
        headers: {
          "X-USER-ID": userId,
          "X-REQUEST-SIGNATURE": signature,
          "X-REQUEST-PAYLOAD": payload,
        },
      }
    );

    if (res.status === 200) {
      return;
    }

    throw res.data;
  }

  async updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const { userId, signature, payload } = await signWithToken();
    const res = await this.instance.patch(
      `/${userId}`,
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          "X-USER-ID": userId,
          "X-REQUEST-SIGNATURE": signature,
          "X-REQUEST-PAYLOAD": payload,
        },
      }
    );

    if (res.status === 200) {
      return;
    }

    throw res.data;
  }

  async deleteUser(): Promise<void> {
    const { userId, signature, payload } = await signWithToken();
    const res = await this.instance.delete(`/${userId}`, {
      headers: {
        "X-USER-ID": userId,
        "X-REQUEST-SIGNATURE": signature,
        "X-REQUEST-PAYLOAD": payload,
      },
    });

    if (res.status === 200) {
      return;
    }

    throw res.data;
  }
}
