import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { BasicSymbolInfo, Session, Strategy } from '../../../../../../../state/backtesting/models/backtesting_models'
import IBacktestingManagerBase from '../../../../../../../state/backtesting/manager/backtesting_manager_base'
import { useFormik } from 'formik'
import { currentStrategyState } from '../../../../../../../state/backtesting/atoms/current_strategy'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { symbolsState } from '../../../../../../../state/backtesting/atoms/symbols'
import { backtestingManagerState } from '../../../../../../../state/backtesting/backtesting_manager_state'
import { strategiesState } from '../../../../../../../state/backtesting/atoms/strategies'
import { currentSessionState } from '../../../../../../../state/backtesting/atoms/current_session'
import { sessionsState } from '../../../../../../../state/backtesting/atoms/sessions'
import { convertToDateTimeFormat } from '../../../../../../../utils/date'

export default function EditSessionModal(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}) {
    const currentStrategy = useRecoilValue(currentStrategyState);
    const currentSession = useRecoilValue(currentSessionState)
    const [strategyQuery, setStrategyQuery] = useState('')
    const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(null);
    const [symbols, setSymbols] = useRecoilState(symbolsState);
    const [strategies, setStrategies] = useRecoilState(strategiesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const setSessions = useSetRecoilState(sessionsState);

    useEffect(() => {
        values.endDate = undefined;
        values.name = undefined;
        values.description = undefined;
        values.strategy = undefined;
        setSelectedStrategy(null);
        setStrategyQuery("");

        backtestingManager.listAllPairs(setSymbols);
        backtestingManager.listStrategies(setStrategies);

        return () => {
            console.log("cleanup called")
            values.endDate = undefined;
            values.name = undefined;
            values.description = undefined;
            values.strategy = undefined;
            setSelectedStrategy(null);
            setStrategyQuery("");
        }
    }, []);

    const filteredStrategies =
        strategyQuery === ''
            ? strategies
            : strategies.filter((strategy) => {
                return strategy.name.toLowerCase().includes(strategyQuery.toLowerCase())
            })

    const validate = (values: { name?: string, endDate?: string, description?: string, strategy?: string, api?: string }) => {
        const errors: { name?: string, endDate?: string, description?: string, stragegy?: string, api?: string } = {};

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            name: currentSession?.name,
            endDate: currentSession?.endDate.toString(),
            description: currentSession?.description,
            strategy: currentSession?.strategyId,
        },
        validate,
        onSubmit: async (values) => {
            let offset = 0;
            if (values.endDate != undefined) {
                const d = new Date(values.endDate);
                offset = d.getTimezoneOffset();
            }

            try {
                await backtestingManager.updateSession(
                    setSessions,
                    currentSession!.id,
                    values.name,
                    values.description,
                    values.strategy,
                    values.endDate != undefined ? (Date.parse(values.endDate) / 1000) - (offset * 60) : undefined
                );

                props.setOpen(false);
                values.endDate = undefined;
                values.name = undefined;
                values.description = undefined;
                values.strategy = undefined;
                values = {};
                setSelectedStrategy(null);
                setStrategyQuery("");


                return true;
            } catch (error) {
                errors.api = "failed to update session"
                return false;
            }
        }
    });

    const getSymbolFromSession = (session: Session): BasicSymbolInfo | undefined => {
        const symbol = symbols.find((s) => s.name == session.pairs[0]);

        return symbol;
    }

    return (
        <Dialog open={props.open} onClose={(value) => {
            props.setOpen(value);

            console.log("cleanup called")
            values.endDate = undefined;
            values.name = undefined;
            values.description = undefined;
            values.strategy = undefined;
            setSelectedStrategy(null);
            setStrategyQuery("");
        }} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-transparent backdrop-blur-md transition-all data-[closed]:backdrop-blur-none data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <form onSubmit={handleSubmit}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-start sm:items-center sm:p-0">

                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mt-1 sm:mt-3">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Edit {currentSession?.name ?? "Session"}
                                    </DialogTitle>

                                    <div className="mt-6 flex flex-col space-y-6">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder={currentSession?.name ?? "name"}
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.name && errors.name
                                                ? <p className="text-gray-900">{errors.name}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <Combobox
                                                as="div"
                                                value={selectedStrategy}
                                                onChange={(strategy) => {
                                                    setStrategyQuery('')
                                                    setSelectedStrategy(strategy)
                                                    values.strategy = strategy?.id;
                                                }}
                                                onBlur={handleBlur}
                                            >
                                                <Label className="block text-sm font-medium leading-6 text-gray-900">Assign a Strategy</Label>
                                                <div className="relative mt-2">
                                                    <ComboboxInput
                                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        onChange={(event) => setStrategyQuery(event.target.value)}
                                                        onBlur={() => setStrategyQuery('')}
                                                        placeholder={strategies.find((s) => s.id == currentSession?.strategyId)?.name ?? "strategy"}
                                                        displayValue={(strategy: Strategy | null) => strategy?.name ?? strategies.find((s) => s.id == currentSession?.strategyId)?.name ?? ""}
                                                    />
                                                    <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </ComboboxButton>

                                                    {filteredStrategies.length > 0 && (
                                                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredStrategies.map((strategy) => (
                                                                <ComboboxOption
                                                                    key={strategy.name}
                                                                    value={strategy}
                                                                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                                >
                                                                    <span className="block truncate group-data-[selected]:font-semibold">{strategy.name}</span>

                                                                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                </ComboboxOption>
                                                            ))}
                                                        </ComboboxOptions>
                                                    )}
                                                </div>
                                            </Combobox>
                                            {touched.strategy && errors.strategy
                                                ? <p className="text-gray-900">{errors.strategy}</p>
                                                : null}
                                        </div>


                                        <div>
                                            <label htmlFor="endDate" className="block text-sm font-medium leading-6 text-gray-900">
                                                Choose Stop Date
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="endDate"
                                                    name="endDate"
                                                    type="datetime-local"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    min={currentSession != undefined ? currentSession!.startDate ?? convertToDateTimeFormat(new Date(getSymbolFromSession(currentSession)!.firstDate).toISOString()) : undefined}
                                                    max={currentSession != undefined ? convertToDateTimeFormat(new Date(getSymbolFromSession(currentSession)!.lastDate).toISOString()) : undefined}
                                                    onChange={handleChange}
                                                    value={values.endDate ?? (currentSession != undefined ? convertToDateTimeFormat(new Date(currentSession.endDate * 1000).toISOString()) : undefined)}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.endDate && errors.endDate
                                                ? <p className="text-gray-900">{errors.endDate}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    rows={4}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder={currentSession?.description ?? "description"}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.description && errors.description
                                                ? <p className="text-red-600">{errors.description}</p>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => {
                                        console.log(currentSession);
                                        props.setOpen(false);

                                        console.log("cleanup called")
                                        values.endDate = undefined;
                                        values.name = undefined;
                                        values.description = undefined;
                                        values.strategy = undefined;
                                        setSelectedStrategy(null);
                                        setStrategyQuery("");
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>

                    </div>
                </div>
            </form>
        </Dialog>
    )
}