export class User {
    id: string;
    email: string;
    customerId: string;
    createdAt: number;
    lastActivity: number;
    valid: boolean;
    isMember: boolean;
    authorizationLevel: string;

    constructor(id: string, email: string, customerId: string, createdAt: number, lastActivity: number, valid: boolean, isMember: boolean, authorizationLevel: string) {
        this.id = id;
        this.email = email;
        this.customerId = customerId;
        this.createdAt = createdAt;
        this.lastActivity = lastActivity;
        this.valid = valid;
        this.isMember = isMember;
        this.authorizationLevel = authorizationLevel;
    }
}

export class CreateUserResponse {
    user: User;
    token: string;

    constructor(user: User, token: string) {
        this.user = user;
        this.token = token;
    }
}