import axios, { AxiosInstance } from "axios";
import { ChartLayout } from "../models/chart_layout_models";
import IChartManagementAPI from "./chart_management_api_base";
import { signWithToken } from "../../../utils/crypto";

export default class ChartManagementAPI implements IChartManagementAPI {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/backtesting`,
        });
    }

    async updateSessionLastChartLayout(sessionId: string, chartId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(`/session/${sessionId}/lastChartLayout`, {}, {
            params: { chartId },
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status != 200) {
            throw res.data
        }

        return;
    }

    async saveChartLayout(chartId: number, chartName: string, currentSession: string, chartSessions: string[], chartContent: string): Promise<ChartLayout> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(`/${currentSession}/chartLayout`, {
            chartId,
            chartName,
            chartSessions,
            chartContent,
            userId
        }, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const chartLayout = Object.assign(
            new ChartLayout("", 0, "", [], "", "", 0),
            res.data
        );

        return chartLayout;
    }

    async listAllChartLayouts(): Promise<ChartLayout[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/chartLayouts`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const chartLayouts: ChartLayout[] = res.data.map((session: any) =>
            Object.assign(
                new ChartLayout("", 0, "", [], "", "", 0),
                session
            )
        );

        return chartLayouts
    }

    async listSessionChartLayouts(sessionId: string): Promise<ChartLayout[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${sessionId}/chartLayouts`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const chartLayouts: ChartLayout[] = res.data.map((session: any) =>
            Object.assign(
                new ChartLayout("", 0, "", [], "", "", 0),
                session
            )
        );

        return chartLayouts
    }

    async loadChartLayout(chartId: number, sessionId: string): Promise<ChartLayout> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${sessionId}/chartLayout/${chartId}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const chartLayout = Object.assign(
            new ChartLayout("", 0, "", [], "", "", 0),
            res.data
        );

        return chartLayout;
    }

    async deleteChartLayout(chartId: number, sessionId: string): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(`/${sessionId}/chartLayout`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.status != 200) {
            throw res.data;
        }

        return;
    }
}