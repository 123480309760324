'use client'

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { Trade } from '../../../../../../state/backtesting/models/backtesting_models'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { tradesSelector } from '../../../../../../state/backtesting/selectors/trades_selector'
import { currentSessionState } from '../../../../../../state/backtesting/atoms/current_session'
import { currentTradeState } from '../../../../../../state/backtesting/atoms/current_trade'
import { useNavigate } from 'react-router-dom'

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function TradeSearchBar() {
    const navigate = useNavigate();
    const [query, setQuery] = useState('')
    const [selectedTrade, setSelectedTrade] = useState<Trade | null>(null);
    const currentSession = useRecoilValue(currentSessionState);
    const sessionTrades = useRecoilValue(tradesSelector(currentSession?.id ?? ""));
    const [currentTrade, setCurrentTradeState] = useRecoilState(currentTradeState);

    const filteredPeople =
        query === ''
            ? sessionTrades
            : sessionTrades.filter((trade) => {
                return new Date(trade.entryTime * 1000).toUTCString().toLowerCase().includes(query.toLowerCase())
            })

    useEffect(() => {
        if (currentTrade != undefined) {
            setSelectedTrade(currentTrade)
        }
    }, [currentTrade]);

    return (
        <Combobox
            as="div"
            value={selectedTrade}
            onChange={(selectedTrade) => {
                setQuery('');
                setSelectedTrade(selectedTrade);

                if (selectedTrade != undefined) {
                    setCurrentTradeState(selectedTrade)
                    navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${selectedTrade?.id}`);
                }
            }}
        >
            <div className="relative mt-2">
                <ComboboxInput
                    className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                    displayValue={(trade: Trade | undefined) => trade != undefined ? new Date(trade.entryTime * 1000).toUTCString() : ""}
                    placeholder='Select A Trade To Review'
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                {filteredPeople.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((trade) => (
                            <ComboboxOption
                                key={trade.id}
                                value={trade}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                            >
                                <div className="flex items-center">
                                    <span
                                        className={classNames(
                                            'inline-block size-2 shrink-0 rounded-full',
                                            trade.side == "long" ? 'bg-green-400' : 'bg-red-500',
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span className="ml-3 truncate group-data-[selected]:font-semibold">
                                        {new Date(trade.entryTime * 1000).toUTCString()}
                                        {/* <span className="sr-only"> is {trade.online ? 'online' : 'offline'}</span> */}
                                    </span>
                                </div>

                                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                    <CheckIcon className="size-5" aria-hidden="true" />
                                </span>
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    )
}