// insert element in array if element not present else update element
export function upsertDefault<T extends { id: string, created: number }>(element: T, array: T[]): T[] {
    const i = array.findIndex(e => e.id === element.id);
    if (i > -1) array[i] = element;
    else array.push(element);

    return [...array].sort((a, b) => b.created - a.created);
}

// insert element in array if element not present else update element
export function upsertTrade<T extends { id: string, exitTime: number }>(element: T, array: T[]): T[] {
    const i = array.findIndex(e => e.id === element.id);
    if (i > -1) array[i] = element;
    else array.unshift(element);

    return [...array].sort((a, b) => b.exitTime - a.exitTime);
}

// insert element in array if element not present else update element
export function upsertOrder<T extends { id: string, executionSessionTime: number }>(element: T, array: T[]): T[] {
    const i = array.findIndex(e => e.id === element.id);
    if (i > -1) array[i] = element;
    else array.unshift(element);

    return [...array].sort((a, b) => b.executionSessionTime - a.executionSessionTime);
}

// insert elements in array if element not present else update elements
export function upsertMany<T extends { id: string, created: number }>(elems: T[], array: T[]): T[] {
    for (let i = 0; i < elems.length; i++) {
        const element = elems[i];
        const j = array.findIndex(e => e.id === element.id);

        if (j > -1) array[j] = element;
        else array.push(element);
    }

    return [...array].sort((a, b) => b.created - a.created);
}