export class TimedMetric {
    constructor(
        public date: number,
        public value: number = 0,
        public weight: number = 0,
    ) { }
}

export class PL {
    constructor(
        public date: number,
        public profit: number,
        public valueEngaged: number,
        public valueRisked: number,
        public expectedProfit: number,
        public riskRewardRatio: number,
        public product: string,
        public sessionId: string,
        public startBalance: number,
        public endBalance: number,
    ) { }
}

export class BalanceMovement {
    constructor(
        public date: number,
        public balance: number = 0,
        public assetAmount: number = 0,
        public amountOwed: number = 0,
        public product: string,
        public sessionId: string,
    ) { }
}

export class SessionFilterSettings {
    constructor(
        public session: string[]
    ) { }
}

export class DaysFilterSettings {
    constructor(
        public days: string[]
    ) { }
}

export class TagsFilterSettings {
    constructor(
        public setups: string[],
        public mistakes: string[],
        public custom: string[],
        public allSetups: boolean,
        public allMistakes: boolean,
        public allCustoms: boolean,
    ) { }
}

export class RulesFilterSettings {
    constructor(
        public rulesIds: string[],
        public allRules: boolean,
    ) { }
}

export class TradeStatistics {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public product: string,
        public setups: Map<string, number> = new Map(),
        public mistakes: Map<string, number> = new Map(),
        public custom: Map<string, number> = new Map(),
        public breakEvenThreshold: number,
        public amountTraded: number,
        public netProfit: number,
        public grossProfit: number,
        public grossLoss: number,
        public valueInvested: number,
        public valueRisked: number,
        public expectedProfit: number,
        public returnOnCapital: number,
        public riskReturnRatio: number,
        public returnOnInvestment: number,
        public riskRewardRatio: number,
        public idealRiskRewardRatio: number,
        public mae: number,
        public mfe: number,
        public rating: number,
        public plannedRMultiple: number,
        public realizedRMultiple: number,
        public notes: string,
        public totalNumberOfOrder: number,
        public totalNumberOfBuyOrder: number,
        public totalNumberOfSellOrder: number,
        public timeInMarket: number,
        public ordersIds: string[] = [],
        public strategyId?: string,
    ) { }
}

export class RuleStatistics {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public pairs: Map<string, string> = new Map(),
        public strategyId: string,
        public startDate: number = 0,
        public endDate: number = 0,
        public stopDate: number = 0,
        public baseBalance: number = 0,
        public lastBalance: number = 0,
        public lastAssetAmount: number = 0,
        public lastAmountOwed: number = 0,
        public balanceMovement: BalanceMovement[] = [],
        public returns: TimedMetric[] = [],
        public setups: Map<string, number> = new Map(),
        public mistakes: Map<string, number> = new Map(),
        public custom: Map<string, number> = new Map(),
        public breakEvenThreshold: number = 0,
        public netProfit: number = 0,
        public netProfitLongSide: number = 0,
        public netProfitShortSide: number = 0,
        public grossProfit: number = 0,
        public grossProfitLongSide: number = 0,
        public grossProfitShortSide: number = 0,
        public grossLoss: number = 0,
        public grossLossLongSide: number = 0,
        public grossLossShortSide: number = 0,
        public returnOnInvestment: number = 0,
        public compoundAnnualGrowthRate: number = 0,
        public winRate: number = 0,
        public winRateLongSide: number = 0,
        public winRateShortSide: number = 0,
        public lossRate: number = 0,
        public lossRateLongSide: number = 0,
        public lossRateShortSide: number = 0,
        public averageWin: number = 0,
        public averageWinLongSide: number = 0,
        public averageWinShortSide: number = 0,
        public averageLoss: number = 0,
        public averageLossLongSide: number = 0,
        public averageLossShortSide: number = 0,
        public profitFactor: number = 0,
        public profitFactorLongSide: number = 0,
        public profitFactorShortSide: number = 0,
        public riskRewardRatio: number = 0,
        public maxDrawdown: number = 0,
        public maxDrawdownPercentage: number = 0,
        public maxDrawup: number = 0,
        public maxDrawupPercentage: number = 0,
        public calmarRatio: number = 0,
        public sharpeRatio: number = 0,
        public sortinoRatio: number = 0,
        public omegaRatio: number = 0,
        public annualizedVolatility: number = 0,
        public alpha: number = 0,
        public beta: number = 0,
        public rSquared: number = 0,
        public equityCurve: TimedMetric[] = [],
        public plCurve: PL[] = [],
        public drawdownCurve: TimedMetric[] = [],
        public totalNumberOfTrades: number = 0,
        public totalNumberOfLongTrades: number = 0,
        public totalNumberOfShortTrades: number = 0,
        public totalNumberOfOrder: number = 0,
        public totalNumberOfBuyOrder: number = 0,
        public totalNumberOfSellOrder: number = 0,
        public winningTrades: number = 0,
        public winningLongTrades: number = 0,
        public winningShortTrades: number = 0,
        public losingTrades: number = 0,
        public losingLongTrades: number = 0,
        public losingShortTrades: number = 0,
        public breakEvenTrades: number = 0,
        public breakEvenLongTrades: number = 0,
        public breakEvenShortTrades: number = 0,
        public averageConsecutiveWins: number = 0,
        public averageConsecutiveLosses: number = 0,
        public maxConsecutiveWins: number = 0,
        public maxConsecutiveLosses: number = 0,
        public timeInMarket: number = 0,
        public timeInMarketLongTrades: number = 0,
        public timeInMarketShortTrades: number = 0,
        public timeInMarketWinningTrades: number = 0,
        public timeInMarketLosingTrades: number = 0,
        public averageTradeDuration: number = 0,
        public averageLongTradeDuration: number = 0,
        public averageShortTradeDuration: number = 0,
        public averageWinningTradeDuration: number = 0,
        public averageLosingTradeDuration: number = 0,
        public maximumTradeDuration: number = 0,
        public maximumLongTradeDuration: number = 0,
        public maximumShortTradeDuration: number = 0,
        public maximumWinningTradeDuration: number = 0,
        public maximumLosingTradeDuration: number = 0,
        public minimumTradeDuration: number = 0,
        public minimumLongTradeDuration: number = 0,
        public minimumShortTradeDuration: number = 0,
        public minimumWinningTradeDuration: number = 0,
        public minimumLosingTradeDuration: number = 0,
        public averageRiskReward: number = 0,
        public averageRiskRewardLongTrades: number = 0,
        public averageRiskRewardShortTrades: number = 0,
        public averageRiskRewardWinningTrades: number = 0,
        public averageRiskRewardLosingTrade: number = 0,
        public averageIdealRiskReward: number = 0,
        public averageIdealRiskRewardLongTrades: number = 0,
        public averageIdealRiskRewardShortTrades: number = 0,
        public averageIdealRiskRewardWinningTrades: number = 0,
        public averageIdealRiskRewardLosingTrades: number = 0,
        public riskRewardCurve: TimedMetric[] = [],
        public valueAtRisk: number = 0,
        public conditionalValueAtRisk: number = 0,
        public expectedShortfall: number = 0,
        public tailRatio: number = 0,
        public ulcerIndex: number = 0,
        public zScore: number = 0,
        public zScoreLongSide: number = 0,
        public zScoreShortSide: number = 0,
        public skewness: number = 0,
        public kurtosis: number = 0,
        public returnDistribution: Map<string, number> = new Map(),
        public startingCapital: number = 0,
        public endingCapital: number = 0,
        public marginUtilization: number = 0,
        public leverage: number = 0,
        public tradeIds: string[] = []
    ) { }
}

export class StrategyStatistics {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public pairs: Map<string, string> = new Map(),
        public strategyId: string,
        public startDate: number = 0,
        public endDate: number = 0,
        public stopDate: number = 0,
        public baseBalance: number = 0,
        public lastBalance: number = 0,
        public lastAssetAmount: number = 0,
        public lastAmountOwed: number = 0,
        public balanceMovement: BalanceMovement[] = [],
        public returns: TimedMetric[] = [],
        public setups: Map<string, number> = new Map(),
        public mistakes: Map<string, number> = new Map(),
        public custom: Map<string, number> = new Map(),
        public breakEvenThreshold: number = 0,
        public netProfit: number = 0,
        public netProfitLongSide: number = 0,
        public netProfitShortSide: number = 0,
        public grossProfit: number = 0,
        public grossProfitLongSide: number = 0,
        public grossProfitShortSide: number = 0,
        public grossLoss: number = 0,
        public grossLossLongSide: number = 0,
        public grossLossShortSide: number = 0,
        public returnOnInvestment: number = 0,
        public compoundAnnualGrowthRate: number = 0,
        public winRate: number = 0,
        public winRateLongSide: number = 0,
        public winRateShortSide: number = 0,
        public lossRate: number = 0,
        public lossRateLongSide: number = 0,
        public lossRateShortSide: number = 0,
        public averageWin: number = 0,
        public averageWinLongSide: number = 0,
        public averageWinShortSide: number = 0,
        public averageLoss: number = 0,
        public averageLossLongSide: number = 0,
        public averageLossShortSide: number = 0,
        public profitFactor: number = 0,
        public profitFactorLongSide: number = 0,
        public profitFactorShortSide: number = 0,
        public riskRewardRatio: number = 0,
        public maxDrawdown: number = 0,
        public maxDrawdownPercentage: number = 0,
        public maxDrawup: number = 0,
        public maxDrawupPercentage: number = 0,
        public calmarRatio: number = 0,
        public sharpeRatio: number = 0,
        public sortinoRatio: number = 0,
        public omegaRatio: number = 0,
        public annualizedVolatility: number = 0,
        public alpha: number = 0,
        public beta: number = 0,
        public rSquared: number = 0,
        public equityCurve: TimedMetric[] = [],
        public plCurve: PL[] = [],
        public drawdownCurve: TimedMetric[] = [],
        public drawupCurve: TimedMetric[] = [],
        public totalNumberOfTrades: number = 0,
        public totalNumberOfLongTrades: number = 0,
        public totalNumberOfShortTrades: number = 0,
        public totalNumberOfOrder: number = 0,
        public totalNumberOfBuyOrder: number = 0,
        public totalNumberOfSellOrder: number = 0,
        public winningTrades: number = 0,
        public winningLongTrades: number = 0,
        public winningShortTrades: number = 0,
        public losingTrades: number = 0,
        public losingLongTrades: number = 0,
        public losingShortTrades: number = 0,
        public breakEvenTrades: number = 0,
        public breakEvenLongTrades: number = 0,
        public breakEvenShortTrades: number = 0,
        public averageConsecutiveWins: number = 0,
        public averageConsecutiveLosses: number = 0,
        public maxConsecutiveWins: number = 0,
        public maxConsecutiveLosses: number = 0,
        public timeInMarket: number = 0,
        public timeInMarketLongTrades: number = 0,
        public timeInMarketShortTrades: number = 0,
        public timeInMarketWinningTrades: number = 0,
        public timeInMarketLosingTrades: number = 0,
        public averageTradeDuration: number = 0,
        public averageLongTradeDuration: number = 0,
        public averageShortTradeDuration: number = 0,
        public averageWinningTradeDuration: number = 0,
        public averageLosingTradeDuration: number = 0,
        public maximumTradeDuration: number = 0,
        public maximumLongTradeDuration: number = 0,
        public maximumShortTradeDuration: number = 0,
        public maximumWinningTradeDuration: number = 0,
        public maximumLosingTradeDuration: number = 0,
        public minimumTradeDuration: number = 0,
        public minimumLongTradeDuration: number = 0,
        public minimumShortTradeDuration: number = 0,
        public minimumWinningTradeDuration: number = 0,
        public minimumLosingTradeDuration: number = 0,
        public averageRiskReward: number = 0,
        public averageRiskRewardLongTrades: number = 0,
        public averageRiskRewardShortTrades: number = 0,
        public averageRiskRewardWinningTrades: number = 0,
        public averageRiskRewardLosingTrade: number = 0,
        public averageIdealRiskReward: number = 0,
        public averageIdealRiskRewardLongTrades: number = 0,
        public averageIdealRiskRewardShortTrades: number = 0,
        public averageIdealRiskRewardWinningTrades: number = 0,
        public averageIdealRiskRewardLosingTrades: number = 0,
        public riskRewardCurve: TimedMetric[] = [],
        public valueAtRisk: number = 0,
        public conditionalValueAtRisk: number = 0,
        public expectedShortfall: number = 0,
        public tailRatio: number = 0,
        public ulcerIndex: number = 0,
        public zScore: number = 0,
        public zScoreLongSide: number = 0,
        public zScoreShortSide: number = 0,
        public skewness: number = 0,
        public kurtosis: number = 0,
        public returnDistribution: Map<string, number> = new Map(),
        public startingCapital: number = 0,
        public endingCapital: number = 0,
        public marginUtilization: number = 0,
        public leverage: number = 0,
        public tradeIds: string[] = []
    ) { }
}

export class SessionStatistics {
    constructor(
        public id: string,
        public userId: string,
        public sessionId: string,
        public pairs: Map<string, string> = new Map(),
        public strategyId?: string,
        public startDate: number = 0,
        public endDate: number = 0,
        public stopDate: number = 0,
        public baseBalance: number = 0,
        public lastBalance: number = 0,
        public lastAssetAmount: number = 0,
        public lastAmountOwed: number = 0,
        public balanceMovement: BalanceMovement[] = [],
        public returns: TimedMetric[] = [],
        public setups: Map<string, number> = new Map(),
        public mistakes: Map<string, number> = new Map(),
        public custom: Map<string, number> = new Map(),
        public breakEvenThreshold: number = 0,
        public netProfit: number = 0,
        public netProfitLongSide: number = 0,
        public netProfitShortSide: number = 0,
        public grossProfit: number = 0,
        public grossProfitLongSide: number = 0,
        public grossProfitShortSide: number = 0,
        public grossLoss: number = 0,
        public grossLossLongSide: number = 0,
        public grossLossShortSide: number = 0,
        public returnOnInvestment: number = 0,
        public compoundAnnualGrowthRate: number = 0,
        public winRate: number = 0,
        public winRateLongSide: number = 0,
        public winRateShortSide: number = 0,
        public lossRate: number = 0,
        public lossRateLongSide: number = 0,
        public lossRateShortSide: number = 0,
        public averageWin: number = 0,
        public averageWinLongSide: number = 0,
        public averageWinShortSide: number = 0,
        public averageLoss: number = 0,
        public averageLossLongSide: number = 0,
        public averageLossShortSide: number = 0,
        public profitFactor: number = 0,
        public profitFactorLongSide: number = 0,
        public profitFactorShortSide: number = 0,
        public riskRewardRatio: number = 0,
        public maxDrawdown: number = 0,
        public maxDrawdownPercentage: number = 0,
        public maxDrawup: number = 0,
        public maxDrawupPercentage: number = 0,
        public calmarRatio: number = 0,
        public sharpeRatio: number = 0,
        public sortinoRatio: number = 0,
        public omegaRatio: number = 0,
        public annualizedVolatility: number = 0,
        public alpha: number = 0,
        public beta: number = 0,
        public rSquared: number = 0,
        public equityCurve: TimedMetric[] = [],
        public plCurve: PL[] = [],
        public drawdownCurve: TimedMetric[] = [],
        public drawupCurve: TimedMetric[] = [],
        public totalNumberOfTrades: number = 0,
        public totalNumberOfLongTrades: number = 0,
        public totalNumberOfShortTrades: number = 0,
        public totalNumberOfOrder: number = 0,
        public totalNumberOfBuyOrder: number = 0,
        public totalNumberOfSellOrder: number = 0,
        public winningTrades: number = 0,
        public winningLongTrades: number = 0,
        public winningShortTrades: number = 0,
        public losingTrades: number = 0,
        public losingLongTrades: number = 0,
        public losingShortTrades: number = 0,
        public breakEvenTrades: number = 0,
        public breakEvenLongTrades: number = 0,
        public breakEvenShortTrades: number = 0,
        public averageConsecutiveWins: number = 0,
        public averageConsecutiveLosses: number = 0,
        public maxConsecutiveWins: number = 0,
        public maxConsecutiveLosses: number = 0,
        public timeInMarket: number = 0,
        public timeInMarketLongTrades: number = 0,
        public timeInMarketShortTrades: number = 0,
        public timeInMarketWinningTrades: number = 0,
        public timeInMarketLosingTrades: number = 0,
        public averageTradeDuration: number = 0,
        public averageLongTradeDuration: number = 0,
        public averageShortTradeDuration: number = 0,
        public averageWinningTradeDuration: number = 0,
        public averageLosingTradeDuration: number = 0,
        public maximumTradeDuration: number = 0,
        public maximumLongTradeDuration: number = 0,
        public maximumShortTradeDuration: number = 0,
        public maximumWinningTradeDuration: number = 0,
        public maximumLosingTradeDuration: number = 0,
        public minimumTradeDuration: number = 0,
        public minimumLongTradeDuration: number = 0,
        public minimumShortTradeDuration: number = 0,
        public minimumWinningTradeDuration: number = 0,
        public minimumLosingTradeDuration: number = 0,
        public averageRiskReward: number = 0,
        public averageRiskRewardLongTrades: number = 0,
        public averageRiskRewardShortTrades: number = 0,
        public averageRiskRewardWinningTrades: number = 0,
        public averageRiskRewardLosingTrade: number = 0,
        public averageIdealRiskReward: number = 0,
        public averageIdealRiskRewardLongTrades: number = 0,
        public averageIdealRiskRewardShortTrades: number = 0,
        public averageIdealRiskRewardWinningTrades: number = 0,
        public averageIdealRiskRewardLosingTrades: number = 0,
        public riskRewardCurve: TimedMetric[] = [],
        public valueAtRisk: number = 0,
        public conditionalValueAtRisk: number = 0,
        public expectedShortfall: number = 0,
        public tailRatio: number = 0,
        public ulcerIndex: number = 0,
        public zScore: number = 0,
        public zScoreLongSide: number = 0,
        public zScoreShortSide: number = 0,
        public skewness: number = 0,
        public kurtosis: number = 0,
        public returnDistribution: Map<string, number> = new Map(),
        public startingCapital: number = 0,
        public endingCapital: number = 0,
        public marginUtilization: number = 0,
        public leverage: number = 0,
        public tradeIds: string[] = []
    ) { }
}