import { selector, selectorFamily } from "recoil";
import { rulesState } from "../atoms/rules";
import { Rule } from "../models/backtesting_models";
import { ruleGroupsState } from "../atoms/rule_groups";
import { strategiesState } from "../atoms/strategies";

export const rulesSelector = selectorFamily({
    key: "RulesSelector",
    get: (groupId: string) => ({ get }) => {
        const rules = get(rulesState).get(groupId);

        return rules?.sort((a, b) => b.created - a.created) ?? [];
    }
});

export const strategyRulesSelector = selectorFamily({
    key: "StrategyRulesSelector",
    get: (strategyId: string) => ({ get }) => {
        const rules: Rule[] = [];

        const ruleGroups = get(ruleGroupsState).get(strategyId);

        if (ruleGroups != undefined) {
            for (let i = 0; i < ruleGroups.length; i++) {
                const group = ruleGroups[i];
                const groupRules = get(rulesState).get(group.id);

                if (groupRules != undefined) {
                    rules.push(...groupRules);
                }
            }
        }

        return rules.sort((a, b) => b.created - a.created);
    }
});

export const allRulesSelector = selector({
    key: "AllRulesSelector",
    get: ({ get }) => {
        const rules: Rule[] = [];
        const strategies = get(strategiesState);

        for (let i = 0; i < strategies.length; i++) {
            const strategy = strategies[i];
            const ruleGroups = get(ruleGroupsState).get(strategy.id);

            if (ruleGroups != undefined) {
                for (let j = 0; j < ruleGroups.length; j++) {
                    const group = ruleGroups[j];
                    const groupRules = get(rulesState).get(group.id);

                    if (groupRules != undefined) {
                        rules.push(...groupRules);
                    }
                }
            }
        }

        return rules.sort((a, b) => b.created - a.created);
    }
});