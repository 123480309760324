import { useRecoilState, useRecoilValue } from "recoil";
import { ordersState } from "../../../../../../state/backtesting/atoms/orders";
import CancelOrderModal from "./modals/CancelOrderModal";
import EditOrderModal from "./modals/EditOrderModal";
import { useState } from "react";
import { Order, Qty, StopLoss, TakeProfit } from "../../../../../../state/backtesting/models/backtesting_models";
import { symbolsState } from "../../../../../../state/backtesting/atoms/symbols";
import { currentSessionState } from "../../../../../../state/backtesting/atoms/current_session";
import { sessionOrdersSelector } from "../../../../../../state/backtesting/selectors/orders_selectors";

export default function AwaitingOrders() {
    const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
    const [editOrderOpen, setEditOrderOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState<Order | undefined>(undefined);
    const currentSession = useRecoilValue(currentSessionState);
    const orders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""));
    const symbols = useRecoilValue(symbolsState);

    const computeTPSLQty = (qty: Qty, isShort: boolean): number => {
        if (qty.FixedAmount != undefined) return qty.FixedAmount;

        if (qty.Percentage != undefined) {
            if (isShort) {
                return (currentSession!.amountOwed * qty.Percentage) / 100;
            }

            return (currentSession!.assetAmount * qty.Percentage) / 100;
        } else throw "no valid qty provided";
    }

    const displayTakeProfit = (tp?: TakeProfit, isShort?: boolean): string => {
        if (tp != undefined) {
            const price = tp.price;
            const quoteAsset = symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? "";
            const qty = computeTPSLQty(tp.Qty, isShort ?? false);
            const baseAsset = (symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? "");

            return `${price} ${quoteAsset} -> ${qty} ${baseAsset}`
        }

        return "-"
    }

    const displayStopLoss = (sl?: StopLoss, isShort?: boolean): string => {
        if (sl != undefined) {
            const price = sl.price;
            const quoteAsset = symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.quoteAsset ?? "";
            const qty = computeTPSLQty(sl.Qty, isShort ?? false);
            const baseAsset = (symbols.find((symbol) => symbol.name == currentSession?.pairs[0])?.baseName ?? "");

            return `${price} ${quoteAsset} -> ${qty} ${baseAsset}`
        }

        return "-"
    }

    return (
        <>
            <CancelOrderModal open={cancelOrderOpen} setOpen={setCancelOrderOpen} order={currentOrder} />
            <EditOrderModal open={editOrderOpen} setOpen={setEditOrderOpen} order={currentOrder} />
            <div className="bg-gray-950 h-full">
                <div>
                    <div className="bg-gray-950">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full ">
                                <table className="min-w-full divide-y divide-gray-700">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Side
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                State
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Type
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Amount
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                Price
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                                                Creation
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                TP / SL
                                            </th>
                                            <th scope="col" className="py-3.5 pl-3">
                                                <span className="sr-only">Details</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-800">
                                        {orders.filter((order) => order.state == "awaiting").map((order) => (
                                            <tr key={order.id}>

                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.side}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{order.state}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {order.orderType}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {order.amount}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {order.price}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    {new Date(order.createdSessionTime * 1000).toUTCString()}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    <span className="text-green-400">
                                                        {displayTakeProfit(order.takeProfit, order.side.includes("short"))}
                                                    </span>
                                                    <span> / </span>
                                                    <span className="text-red-500">
                                                        {displayStopLoss(order.stopLoss, order.side.includes("short"))}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="rounded bg-indigo-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                            onClick={() => {
                                                                setCurrentOrder(order)
                                                                setEditOrderOpen(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="rounded ml-3 bg-red-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                                                            onClick={() => {
                                                                setCurrentOrder(order)
                                                                setCancelOrderOpen(true);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}