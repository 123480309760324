import { SetterOrUpdater } from "recoil";
import IPaymentAPIBase from "../api/payment_api_base";
import { Subscription, Invoice, PaymentMethod, Price, Product } from "../models/payment_models";
import IPaymentManagerBase from "./payment_manager_base";

export default class PaymentManager<T extends IPaymentAPIBase> implements IPaymentManagerBase {
    constructor(protected readonly api: T) { }

    async listProducts(setProductsState: SetterOrUpdater<Product[]>): Promise<void> {
        const products = await this.api.listProducts();

        setProductsState((previousProducts) => [...previousProducts, ...products]);
    }

    async getProduct(setProductsState: SetterOrUpdater<Product[]>, product_id: string): Promise<void> {
        const product = await this.api.getProduct(product_id);

        setProductsState((products) => [...products, product]);
    }

    async listPrices(setPricesState: SetterOrUpdater<Price[]>): Promise<void> {
        const prices = await this.api.listPrices();

        setPricesState((previousPrices) => [...previousPrices, ...prices]);
    }

    async getPrice(setPricesState: SetterOrUpdater<Price[]>, price_id: string): Promise<void> {
        const price = await this.api.getPrice(price_id);

        setPricesState((prices) => [...prices, price]);
    }

    async createSubscription(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string, currency: string, default_payment_method: string, items: string[], trial_end: string, plan_id: string, description?: string): Promise<void> {
        const subscription = await this.api.createSubscription(customer_id, currency, default_payment_method, items, trial_end, plan_id, description);

        setSubscriptionsState((subscriptions) => [...subscriptions, subscription]);
    }

    async cancelSubscription(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string, subscription_id: string): Promise<void> {
        await this.api.cancelSubscription(customer_id, subscription_id);

        setSubscriptionsState((subscriptions) => subscriptions.filter((subscription) => subscription.id != subscription_id));
    }

    async resumeSubscription(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string, subscription_id: string): Promise<void> {
        const subscription = await this.api.resumeSubscription(customer_id, subscription_id);

        setSubscriptionsState((subscriptions) => [...subscriptions, subscription]);
    }

    async getSubscription(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string, subscription_id: string): Promise<void> {
        const subscription = await this.api.getSubscription(customer_id, subscription_id);

        setSubscriptionsState((subscriptions) => [...subscriptions, subscription]);
    }

    async listSubscriptions(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string): Promise<Subscription[]> {
        const subscriptions = await this.api.listSubscriptions(customer_id);

        setSubscriptionsState(() => [...subscriptions]);

        return subscriptions;
    }

    async updateSubscription(setSubscriptionsState: SetterOrUpdater<Subscription[]>, customer_id: string, subscription_id: string, default_payment_method: string, items: string[]): Promise<void> {
        const sub = await this.api.updateSubscription(customer_id, subscription_id, default_payment_method, items);

        setSubscriptionsState((subscriptions) => subscriptions.map((subscription) => {
            if (subscription.id == subscription_id) {
                if (default_payment_method != undefined) subscription.default_payment_method = default_payment_method;

                return subscription;
            }
            return subscription;
        }))
    }

    async getInvoice(setInvoicesState: SetterOrUpdater<Invoice[]>, customer_id: string, invoice_id: string): Promise<void> {
        const invoice = await this.api.getInvoice(customer_id, invoice_id);

        setInvoicesState((invoices) => [...invoices, invoice]);
    }

    async listInvoices(setInvoicesState: SetterOrUpdater<Invoice[]>, customer_id: string): Promise<void> {
        const invoices = await this.api.listInvoices(customer_id);

        setInvoicesState(() => [...invoices]);
    }

    async initAddPaymentMethod(setCurrentSetupIntentState: SetterOrUpdater<string>, setCurrentClientSecretState: SetterOrUpdater<string>, customer_id: string): Promise<void> {
        const initAddPaymentMethodResponse = await this.api.initAddPaymentMethod(customer_id);

        setCurrentSetupIntentState(initAddPaymentMethodResponse.setup_intent_id);
        setCurrentClientSecretState(initAddPaymentMethodResponse.client_secret);
    }

    async cancelAddPaymentMethod(setCurrentSetupIntentState: SetterOrUpdater<string>, setCurrentClientSecretState: SetterOrUpdater<string>, customer_id: string, current_setup_intent_id: string): Promise<void> {
        await this.api.cancelAddPaymentMethod(customer_id, current_setup_intent_id);

        setCurrentSetupIntentState("");
        setCurrentClientSecretState("");
    }

    async getPaymentMethod(setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>, customer_id: string, payment_method: string): Promise<PaymentMethod> {
        const paymentMethod = await this.api.getPaymentMethod(customer_id, payment_method);

        setPaymentMethodsState((paymentMethods) => [...paymentMethods, paymentMethod]);

        return paymentMethod;
    }

    async getDefaultPaymentMethod(setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>, customer_id: string): Promise<PaymentMethod> {
        const paymentMethod = await this.api.getDefaultPaymentMethod(customer_id);

        setPaymentMethodsState((paymentMethods) => [...paymentMethods, paymentMethod]);

        return paymentMethod;
    }

    async listPaymentMethods(setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>, customer_id: string): Promise<PaymentMethod[]> {
        const paymentMethods = await this.api.listPaymentMethods(customer_id);

        setPaymentMethodsState(() => [...paymentMethods]);

        return paymentMethods;
    }

    async updateDefaultPaymentMethod(setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>, customer_id: string, payment_method_id: string): Promise<void> {
        await this.api.updateDefaultPaymentMethod(customer_id, payment_method_id);
    }

    async deletePaymentMethod(setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>, customer_id: string, payment_method_id: string): Promise<void> {
        await this.api.deletePaymentMethod(customer_id, payment_method_id);

        setPaymentMethodsState((paymentMethods) => paymentMethods.filter((paymentMethod) => paymentMethod.id != payment_method_id));
    }

    async clear(
        setProductsState: SetterOrUpdater<Product[]>,
        setPricesState: SetterOrUpdater<Price[]>,
        setSubscriptionsState: SetterOrUpdater<Subscription[]>,
        setInvoicesState: SetterOrUpdater<Invoice[]>,
        setCurrentSetupIntentState: SetterOrUpdater<string>,
        setPaymentMethodsState: SetterOrUpdater<PaymentMethod[]>,
    ): Promise<void> {
        setProductsState([]);
        setPricesState([]);
        setSubscriptionsState([]);
        setInvoicesState([]);
        setCurrentSetupIntentState("");
        setPaymentMethodsState([]);
    }
}