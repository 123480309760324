import { StarIcon } from "@heroicons/react/20/solid";
import { getAmountTraded, getExpectedProfit, getGrossLoss, getGrossProfit, getIdealRiskRewardRatio, getMAE, getMFE, getPlannedRMultiple, getRealizedRMultiple, getRiskRewardRatio, getValueRisked } from "../utils/trade_kpi";
import { useRecoilValue } from "recoil";
import { tradeStatisticsState } from "../../../../../../state/statistics/atoms/trade_statistics";
import { strategiesState } from "../../../../../../state/backtesting/atoms/strategies";
import { currentTradeState } from "../../../../../../state/backtesting/atoms/current_trade";
import { TradeStatistics } from "../../../../../../state/statistics/models/statistics_models";
import { Trade } from "../../../../../../state/backtesting/models/backtesting_models";

export default function TradeStats() {
    const tradeStats = useRecoilValue(tradeStatisticsState);
    const strategies = useRecoilValue(strategiesState);
    const currentTrade = useRecoilValue(currentTradeState)

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }

    const getStats = (trade: Trade | undefined, stats: Map<string, TradeStatistics>) => {
        if (trade != undefined) {
            const s = stats.get(trade.id);

            console.log(trade)
            console.log(trade.id);
            console.log(s)
            console.log(s?.netProfit, (s?.netProfit ?? 0) > 0)

            return s;
        } else {
            console.log("some how current trade was set to undefined")
        }

        return undefined;
    }

    return (
        <div className="flex flex-col px-8 py-8 space-y-3">
            <div className={classNames(getStats(currentTrade, tradeStats) != undefined ? getStats(currentTrade, tradeStats)!.netProfit > 0 ? "border-l-green-500" : "border-l-red-500" : "border-l-gray-500", "border-l-2 px-4")}>
                <p className="text-gray-200">Net P&L</p>
                <p className={classNames(getStats(currentTrade, tradeStats) != undefined ? getStats(currentTrade, tradeStats)!.netProfit > 0 ? "text-green-500 text-2xl" : "text-red-500 text-2xl" : "text-gray-400")}>{Math.round((getStats(currentTrade, tradeStats)?.netProfit ?? 0) * 100) / 100}$</p>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Side
                </div>
                <div className="basis-1/2 text-white">{currentTrade?.side ?? "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Amount Traded
                </div>
                <div className="basis-1/2 text-white">{getAmountTraded(tradeStats, currentTrade?.id ?? "")}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Gross P&L
                </div>
                <div className="basis-1/2 text-white">
                    <p>
                        {
                            getGrossProfit(tradeStats, currentTrade?.id ?? "") != undefined
                                ? `${Math.round(getGrossProfit(tradeStats, currentTrade!.id)! * 100) / 100}$`
                                : "-"
                        } - {
                            getGrossLoss(tradeStats, currentTrade?.id ?? "") != undefined
                                ? `${Math.round(getGrossLoss(tradeStats, currentTrade!.id)! * 100) / 100}$`
                                : "-"
                        }
                    </p>
                </div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Strategy
                </div>
                <div className="basis-1/2 text-white">{strategies.find((strategy) => strategy.id == currentTrade?.strategyId)?.name ?? "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    MAE/MFE
                </div>
                <div className="basis-1/2 text-white">
                    {getMAE(tradeStats, currentTrade?.id ?? "") != undefined ? `${Math.round(getMAE(tradeStats, currentTrade?.id ?? "")! * 10000) / 100}% ` : " - "}
                    /
                    {getMFE(tradeStats, currentTrade?.id ?? "") ? `${Math.round(getMFE(tradeStats, currentTrade?.id ?? "")! * 10000) / 100}% ` : " - "}
                </div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Trade Rating
                </div>
                <div className="basis-1/2 text-white">
                    <div className="star-container flex items-center space-x-1">
                        {
                            Array.from(Array(5)).map((_, i) => {
                                return (
                                    <button key={i} className="star">
                                        <StarIcon
                                            className={"star-icon h-6 w-6 fill-gray-800"}
                                        />
                                    </button>
                                );
                            })
                        }
                    </div>
                    {/* {getRating(tradeStats, currentTrade?.id ?? "") ?? "-"} */}
                </div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Profit Target
                </div>
                <div className="basis-1/2 text-white">{getExpectedProfit(tradeStats, currentTrade?.id ?? "") ?? "-"}</div>
            </div>
            <div className="flex flex-col">
                <div className=" text-gray-400">
                    TP/SL
                </div>
                <div className="flex flex-col text-white">
                    {currentTrade?.tpsl?.map((tpsl) => {
                        return (
                            <p className="text-sm">
                                <span className="text-green-600">{tpsl.takeProfit != undefined ? `${tpsl.takeProfit.price} -> ${tpsl.takeProfit.Qty.Percentage != undefined ? tpsl.takeProfit?.Qty.Percentage : tpsl.takeProfit?.Qty.FixedAmount}` : "-"}</span>
                                {" "}/{" "}
                                <span className="text-red-600">{tpsl.stopLoss != undefined ? `${tpsl.stopLoss.price} -> ${tpsl.stopLoss.Qty.Percentage != undefined ? tpsl.stopLoss?.Qty.Percentage : tpsl.stopLoss?.Qty.FixedAmount}` : "-"}</span>
                            </p>
                        );
                    })}
                </div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Trade Risk
                </div>
                <div className="basis-1/2 text-white">{getValueRisked(tradeStats, currentTrade?.id ?? "") ?? "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Planned R-Multiple
                </div>
                <div className="basis-1/2 text-white">{getPlannedRMultiple(tradeStats, currentTrade?.id ?? "") ?? "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Realized R-Multiple
                </div>
                <div className="basis-1/2 text-white">{getRealizedRMultiple(tradeStats, currentTrade?.id ?? "") ?? "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Risk Reward Ratio
                </div>
                <div className="basis-1/2 text-white">{getRiskRewardRatio(tradeStats, currentTrade?.id ?? "") != undefined ? Math.round(getRiskRewardRatio(tradeStats, currentTrade!.id)! * 100) / 100 : "-"}</div>
            </div>
            <div className="flex justify-between">
                <div className="basis-1/2 text-gray-400">
                    Ideal Risk Reward Ratio
                </div>
                <div className="basis-1/2 text-white">{getIdealRiskRewardRatio(tradeStats, currentTrade?.id ?? "") != undefined ? Math.round(getIdealRiskRewardRatio(tradeStats, currentTrade!.id)! * 100) / 100 : "-"}</div>
            </div>
            <div className="flex-col space-y-2">
                <div className="flex justify-between">
                    <div className="text-gray-400">
                        Mistakes
                    </div>
                    {/* <div className="basis-1/2 text-white">
                                    <button
                                        type="button"
                                        className="rounded bg-indigo-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Add New
                                    </button>
                                </div> */}
                </div>
                <div className="flex space-x-1">
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-red-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        Too early
                    </span>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-red-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        Greedy
                    </span>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-red-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        Bad Read
                    </span>
                </div>
            </div>
            <div className="flex-col space-y-2">
                <div className="flex justify-between">
                    <div className="text-gray-400">
                        Setups
                    </div>
                    {/* <div className="basis-1/2 text-white">
                                    <button
                                        type="button"
                                        className="rounded bg-indigo-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Add New
                                    </button>
                                </div> */}
                </div>
                <div className="flex space-x-1">
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-blue-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        Price Drop
                    </span>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-blue-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        High Volume
                    </span>
                </div>
            </div>
            <div className="flex-col space-y-2">
                <div className="flex justify-between">
                    <div className="text-gray-400">
                        Customs
                    </div>
                    {/* <div className="basis-1/2 text-white">
                                    <button
                                        type="button"
                                        className="rounded bg-indigo-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Add New
                                    </button>
                                </div> */}
                </div>
                <div className="flex space-x-1">
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-gray-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        RSI
                    </span>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-gray-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        End Of Day
                    </span>
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-gray-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        Dog Disturbance
                    </span>
                </div>
            </div>
        </div>
    )
}