import axios, { AxiosInstance } from "axios";
import IPaymentAPIBase from "./payment_api_base";
import {
    Subscription,
    Invoice,
    InitAddPaymentMethodResponse,
    PaymentMethod,
    CardDetails,
    Price,
    Product,
    RecurringPriceSetting,
} from "../models/payment_models";
import { signWithToken } from "../../../utils/crypto";

export default class PaymentAPI implements IPaymentAPIBase {
    protected instance: AxiosInstance;

    constructor(domain: string, port: string) {
        this.instance = axios.create({
            baseURL: `${domain}:${port}/api/payment`,
        });
    }

    async listProducts(): Promise<Product[]> {
        const res = await this.instance.get("/products");

        const products = res.data.map((product: any) => Object.assign(
            new Product("", false, "", "", ""),
            product
        ));

        return products;
    }

    async getProduct(product_id: string): Promise<Product> {
        const res = await this.instance.get(`product/${product_id}`);

        const product = Object.assign(new Product("", false, "", "", ""), res.data);

        return product;
    }

    async listPrices(): Promise<Price[]> {
        const res = await this.instance.get("/prices");

        const prices = res.data.map((price: any) => Object.assign(
            new Price("", false, "", "", new RecurringPriceSetting("", 0, ""), "", ""),
            price
        ));

        return prices;
    }

    async getPrice(price_id: string): Promise<Price> {
        const res = await this.instance.get(`price/${price_id}`);

        const price = Object.assign(new Price("", false, "", "", new RecurringPriceSetting("", 0, ""), "", ""), res.data);

        return price;
    }

    async createSubscription(
        customer: string,
        currency: string,
        default_payment_method: string,
        items: string[],
        trial_end: string,
        plan_id: string,
        description?: string
    ): Promise<Subscription> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/${customer}/subscription`,
            {
                currency,
                items,
                planId: plan_id,
                default_payment_method,
                trial_end,
                description,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const subscription: Subscription = Object.assign(
            new Subscription(
                "",
                false,
                "",
                0,
                0,
                "",
                "",
                0,
                0,
                "",
                "",
                "",
                "",
                0,
                0
            ),
            res.data
        );

        return subscription;
    }

    async cancelSubscription(
        customer: string,
        subscription_id: string
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(
            `/${customer}/subscription/${subscription_id}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.data === 200) {
            return;
        }

        throw res.data;
    }

    async resumeSubscription(
        customer: string,
        subscription_id: string
    ): Promise<Subscription> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/${customer}/subscription/${subscription_id}/resume`,
            {},
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status !== 200) {
            throw res.data;
        }

        const subscription = await this.getSubscription(customer, subscription_id);

        return subscription;
    }

    async getSubscription(
        customer: string,
        subscription_id: string
    ): Promise<Subscription> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/${customer}/subscription/${subscription_id}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const subscription: Subscription = Object.assign(
            new Subscription(
                "",
                false,
                "",
                0,
                0,
                "",
                "",
                0,
                0,
                "",
                "",
                "",
                "",
                0,
                0
            ),
            res.data
        );

        return subscription;
    }

    async listSubscriptions(customer_id: string): Promise<Subscription[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${customer_id}/subscriptions`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        if (res.data == null) {
            return [];
        }

        const subscriptions: Subscription[] = res.data.map((subscription: any) =>
            Object.assign(
                new Subscription(
                    "",
                    false,
                    "",
                    0,
                    0,
                    "",
                    "",
                    0,
                    0,
                    "",
                    "",
                    "",
                    "",
                    0,
                    0
                ),
                subscription
            )
        );

        return subscriptions;
    }

    async updateSubscription(
        customer: string,
        subscription_id: string,
        default_payment_method: string,
        items: string[]
    ): Promise<Subscription> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/${customer}/subscription/${subscription_id}`,
            {
                default_payment_method,
                items,
            },
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const subscription: Subscription = Object.assign(
            new Subscription(
                "",
                false,
                "",
                0,
                0,
                "",
                "",
                0,
                0,
                "",
                "",
                "",
                "",
                0,
                0
            ),
            res.data
        );

        return subscription;
    }

    async getInvoice(customer: string, invoice_id: string): Promise<Invoice> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${customer}/invoice/${invoice_id}`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const invoice: Invoice = Object.assign(
            new Invoice("", false, "", "", "", 0, 0, 0, 0, "", ""),
            res.data
        );

        return invoice;
    }

    async listInvoices(customer_id: string): Promise<Invoice[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${customer_id}/invoices`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const invoices: Invoice[] = res.data.map((invoice: any) =>
            Object.assign(
                new Invoice("", false, "", "", "", 0, 0, 0, 0, "", ""),
                invoice
            )
        );

        return invoices;
    }

    async initAddPaymentMethod(
        customer_id: string
    ): Promise<InitAddPaymentMethodResponse> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.post(
            `/${customer_id}/payment_method/add`,
            {},
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const initAddPaymentMethod = Object.assign(
            new InitAddPaymentMethodResponse("", ""),
            res.data
        );

        return initAddPaymentMethod;
    }

    async cancelAddPaymentMethod(
        customer: string,
        setup_intent_id: string
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(
            `/${customer}/setup_intent/${setup_intent_id}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async getPaymentMethod(
        customer_id: string,
        payment_method: string
    ): Promise<PaymentMethod> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(
            `/${customer_id}/invoice/${payment_method}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        const paymentMethod: PaymentMethod = Object.assign(
            new PaymentMethod("", "", 0),
            res.data
        );

        return paymentMethod;
    }

    async getDefaultPaymentMethod(customer_id: string): Promise<PaymentMethod> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${customer_id}/invoice/default`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const paymentMethod: PaymentMethod = Object.assign(
            new PaymentMethod("", "", 0),
            res.data
        );

        return paymentMethod;
    }

    async listPaymentMethods(customer_id: string): Promise<PaymentMethod[]> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.get(`/${customer_id}/payment_methods`, {
            headers: {
                "X-USER-ID": userId,
                "X-REQUEST-SIGNATURE": signature,
                "X-REQUEST-PAYLOAD": payload,
            },
        });

        const paymentMethods: PaymentMethod[] = res.data.map((paymentMethod: any) =>
            Object.assign(
                new PaymentMethod("", "", 0),
                paymentMethod
            )
        );

        return paymentMethods;
    }

    async updateDefaultPaymentMethod(
        customer_id: string,
        payment_method_id: string
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.patch(
            `/${customer_id}/payment_method/default`,
            {},
            {
                params: { payment_method_id },
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }

    async deletePaymentMethod(
        customer_id: string,
        payment_method_id: string
    ): Promise<void> {
        const { userId, signature, payload } = await signWithToken();
        const res = await this.instance.delete(
            `/${customer_id}/payment_method/${payment_method_id}`,
            {
                headers: {
                    "X-USER-ID": userId,
                    "X-REQUEST-SIGNATURE": signature,
                    "X-REQUEST-PAYLOAD": payload,
                },
            }
        );

        if (res.status === 200) {
            return;
        }

        throw res.data;
    }
}
