import { AUTHENTICATION_KEY, USER_ID } from "../constants/users_constants";

interface AuthenticationHeaders {
    userId: string;
    signature: string;
    payload: string;
}

const decodeHex = (str: string) => {
    const uint8array = new Uint8Array(Math.ceil(str.length / 2));
    for (let i = 0; i < str.length;)
        uint8array[i / 2] = Number.parseInt(str.slice(i, (i += 2)), 16);
    return uint8array;
};

export async function signWithToken(): Promise<AuthenticationHeaders> {
    const timestamp = Date.now().toString();
    const timestampUint8 = new TextEncoder().encode(timestamp); // encode as (utf-8) Uint8Array
    const timestampBuffer = await window.crypto.subtle.digest(
        "SHA-256",
        timestampUint8
    ); // hash the message
    const timestampArray = Array.from(new Uint8Array(timestampBuffer)); // convert buffer to byte array
    const payload = timestampArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

    const userId = localStorage.getItem(USER_ID)!;
    const token = localStorage.getItem(AUTHENTICATION_KEY)!;
    //const token = "9330937cdb847b6ef437b3e7a196e6928cd1991287a496fad807ea59b2aa6d489eb1b498e442c11f389678f2a4d4b3cc1bf677d14813de3e586c9289163b3ea5";

    const rawToken = decodeHex(token);

    const cryptoKey = await window.crypto.subtle.importKey(
        "raw",
        rawToken,
        { name: "HMAC", hash: "SHA-256" },
        true,
        ["sign"]
    );

    const enc = new TextEncoder();
    const encoded = enc.encode(payload);

    const signatureBuffer = await window.crypto.subtle.sign(
        "HMAC",
        cryptoKey,
        encoded
    );

    const signatureUint8 = new Uint8Array(signatureBuffer);
    const signatureArray = Array.from(new Uint8Array(signatureUint8)); // convert buffer to byte array
    const signature = signatureArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string

    return {
        userId,
        signature,
        payload,
    };
}

// export async function signWithKey(payload: string): Promise<string> {
//     //const pem = localStorage.getItem(AUTHENTICATION_KEY)!;

//     const pem = "-----BEGIN PRIVATE KEY-----MHcCAQEEIDrWIuxgoxaouEtBYLvfMTsKESqqiFxFoSWninJecF6woAoGCCqGSM49AwEHoUQDQgAEkg20lavbcKbSBTmrgpvb2jtAw2+7Qv5E7A381VbbN+UKIVDX4VF0s5A2Zl1zixP7BBon212+3IidFFKLgxrKJA==-----END PRIVATE KEY-----";

//     const pemHeader = "-----BEGIN PRIVATE KEY-----";
//     const pemFooter = "-----END PRIVATE KEY-----";
//     const pemContents = pem.substring(
//         pemHeader.length,
//         pem.length - pemFooter.length,
//     );

//     console.log(pemContents);

//     // base64 decode the string to get the binary data
//     const binaryDerString = window.atob(pemContents);
//     // convert from a binary string to an ArrayBuffer
//     const binaryDer = str2ab(binaryDerString);

//     console.log(binaryDerString);
//     console.log(binaryDer);

//     const cryptoKey = await window.crypto.subtle.importKey(
//         "pkcs8",
//         binaryDer,
//         {
//             name: "ECDSA",
//             namedCurve: "P-256",
//         },
//         true,
//         ["sign"],
//     );

//     console.log("import key done")
//     console.log(cryptoKey);

//     const enc = new TextEncoder();
//     const encoded = enc.encode(payload);

//     const signature = await window.crypto.subtle.sign(
//         {
//             name: "ECDSA",
//             hash: { name: "SHA-256" },
//         },
//         cryptoKey,
//         encoded,
//     );

//     const singatureArray = Array.from(new Uint8Array(signature)); // convert buffer to byte array
//     const signatureHex = singatureArray
//         .map((b) => b.toString(16).padStart(2, "0"))
//         .join(""); // convert bytes to hex string

//     return signatureHex;
// }
