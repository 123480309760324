import { atom } from "recoil";
import ReplaySaveLoadAdapter from "../replay_save_load_adapter";
import ChartManagementAPI from "../api/chart_management_api";
import ChartManager from "../manager/chart_manager";
import IChartManager from "../manager/chart_manager_base";

const newReplaySaveLoadAdapter = (): ReplaySaveLoadAdapter<IChartManager> => {
    const full = window.location.protocol + '//' + window.location.hostname;

    const api = new ChartManagementAPI(full, window.location.port);
    return new ReplaySaveLoadAdapter(new ChartManager(api))
}

export const replaySaveLoadAdapterState = atom({
    key: "ReplaySaveLoadAdapterState",
    default: newReplaySaveLoadAdapter()
})