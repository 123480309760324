'use client'

import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import {
    Bars3Icon,
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from "react";
import { useBeforeUnload, useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import replayDatafeedState from "../../../../../state/replay/atoms/replay_datafeed_state";
import replayTimeframeManagerState from "../../../../../state/replay/timeframe_manager/replay_timeframe_manager_state";
import replayChartState from "../../../../../state/replay/atoms/replay_chart_state";
import { Bar, IOrderLineAdapter, ResolutionString, widget } from "charting_library";
import { lastBarState } from "../../../../../state/replay/atoms/lastBar";
import { backtestingManagerState } from "../../../../../state/backtesting/backtesting_manager_state";
import { currentSessionState } from "../../../../../state/backtesting/atoms/current_session";
import { sessionsState } from "../../../../../state/backtesting/atoms/sessions";
import { Qty, Session } from "../../../../../state/backtesting/models/backtesting_models";
import { replaySaveLoadAdapterState } from "../../../../../state/replay/atoms/replay_save_load_adapter_state";
import { tradesState } from "../../../../../state/backtesting/atoms/trades";
import { ordersState } from "../../../../../state/backtesting/atoms/orders";
import ReplayManager, { convertResolutionToSeconds } from "../../../../../state/replay/manager/replay_manager";
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from '@heroicons/react/20/solid';
import { symbolsState } from '../../../../../state/backtesting/atoms/symbols';
import useSize from './hooks/useSize';
import PassedTrades from './components/PassedTrades';
import PassedOrders from './components/PassedOrders';
import AwaitingOrders from './components/AwaitingOrders';
import CanceledOrders from './components/CanceledOrders';
import OrderMaker from './components/order_maker/OrderMaker';
import OpenTradesView from './components/OpenTradesView';
import { sessionOrdersSelector } from '../../../../../state/backtesting/selectors/orders_selectors';

const navigation = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
    { name: 'Team', href: '#', icon: UsersIcon, current: false },
    { name: 'Projects', href: '#', icon: FolderIcon, current: false },
    { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
    { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
]

const tabs = [
    { name: 'Trades', href: '#', current: false },
    { name: 'Passed Orders', href: '#', current: false },
    { name: 'Awaiting Orders', href: '#', current: true },
    { name: 'Canceled Orders', href: '#', current: false },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function BacktestingView() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const replayDatafeed = useRecoilValue(replayDatafeedState);
    const replayTimeframe = useRecoilValue(replayTimeframeManagerState);
    const replaySaveLoadAdapter = useRecoilValue(replaySaveLoadAdapterState);
    const setReplayChartState = useSetRecoilState(replayChartState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const [lastBar, setLastBar] = useRecoilState(lastBarState);
    const setSessions = useSetRecoilState(sessionsState);
    const [currentSession, setCurrentSession] = useRecoilState(currentSessionState);
    const [trades, setTrades] = useRecoilState(tradesState);
    const setOrders = useSetRecoilState(ordersState);
    const sessionOrders = useRecoilValue(sessionOrdersSelector(currentSession?.id ?? ""))
    const [loadPositionsDrawing, setLoadPositionDrawing] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [bottomBarOpen, setBottomBarOpen] = useState(true);
    const [replayTime, setReplayTime] = useState<number | undefined>(undefined);
    const [resolutionSeconds, setResolutionSeconds] = useState(1);
    const [symbols, setSymbols] = useRecoilState(symbolsState);
    const stopDate = useRef<number | undefined>(undefined);

    const sessionId = useRef("")

    const lastBarProcessingQueue = useRef<Bar[]>([]);



    const [tabs, setTabs] = useState([
        { name: 'Open Position', href: '#', current: true, component: <OpenTradesView /> },
        { name: 'Open Order', href: '#', current: false, component: <AwaitingOrders /> },
        { name: 'Position History', href: '#', current: false, component: <PassedTrades /> },
        { name: 'Order History', href: '#', current: false, component: <PassedOrders /> },
        { name: 'Wallet', href: '#', current: false, component: <CanceledOrders /> },
    ])

    const rt = useRef<number | undefined>(undefined);

    const [innerHeight, innerWidth] = useSize();

    function handleClose(e: BeforeUnloadEvent) {
        backtestingManager.updateSessionStopTime(setSessions, currentSession!.id, lastBar!.time / 1000);
        return false;
    }

    useBeforeUnload(handleClose);

    useEffect(() => {
        const timer = setInterval(() => {
            if (lastBar != undefined) {
                backtestingManager.updateSessionStopTime(setSessions, currentSession!.id, lastBar!.time / 1000);
            }
        }, 15000);

        return () => {
            clearInterval(timer);
            replayDatafeed.clearReplay();
        }
    }, []);

    useEffect(() => {
        if (currentSession == undefined) {
            const path = window.location.pathname.split("/");
            const sessionId = path[3];

            backtestingManager.listAllPairs(setSymbols)
                .then(() => backtestingManager.getSession(setSessions, sessionId).then((s) => setCurrentSession(s)));
        } else {
            stopDate.current = currentSession.stopDate;


            if (sessionId.current != currentSession.id) {
                sessionId.current = currentSession.id;

                backtestingManager.listSessionTrades(setTrades, currentSession.id);
                backtestingManager.listOrders(setOrders, currentSession.id);
                replayDatafeed.setTimeframe(currentSession.stopDate, replayTimeframe.from);
                replayDatafeed.setEndDate(currentSession.endDate);

                replaySaveLoadAdapter.sessionLastChartId = currentSession.lastChartLayoutId;

                console.log(currentSession.resolution);

                const chartView = new widget({
                    theme: "dark",
                    container: "chartContainer",
                    locale: "en",
                    autosize: true,
                    library_path: "/charting_library/",
                    datafeed: replayDatafeed,
                    save_load_adapter: replaySaveLoadAdapter,
                    symbol: currentSession.pairs[0],
                    interval: currentSession.resolution != "" ? currentSession.resolution as ResolutionString : "1" as ResolutionString,
                    fullscreen: false,
                    debug: true,
                    auto_save_delay: 15,
                    load_last_chart: true,
                    // enabled_features: ["seconds_resolution"],
                });

                setReplayChartState(chartView);
                replayDatafeed.setCurrentChart(chartView);

                chartView.subscribe("onTick", (bar) => {
                    console.log(bar)
                    console.log("///////////////////////////////============>", bar.time, rt.current)

                    if (rt.current != undefined) {
                        console.log(bar.time)
                        rt.current = bar.time * 1000;
                        setReplayTime(bar.time * 1000)

                        if (bar.time * 1000 >= (stopDate.current ?? currentSession.stopDate)) {
                            rt.current = undefined;
                            setReplayTime(undefined);
                        }
                        return;
                    }

                    if (stopDate.current! < bar.time * 1000) {
                        console.log("****************************************")
                        console.log("updating stop date", stopDate.current, bar.time * 1000)
                        console.log("****************************************")

                        stopDate.current = bar.time * 1000;
                        bar.time *= 1000 * 1000;
                        setLastBar(bar);
                    }
                });

                chartView.onChartReady(() => {
                    console.log("the chart is ready")
                    setLoadPositionDrawing(true);
                    setResolutionSeconds(convertResolutionToSeconds(chartView.activeChart().resolution()));

                    chartView.activeChart().onDataLoaded().subscribe(null, () => {
                        console.log(replayDatafeed.lastBar, "here is the last bar")
                        if (rt.current != undefined) return;

                        setLastBar(replayDatafeed.lastBar)
                    })

                    chartView.activeChart().onIntervalChanged().subscribe(null, (resolution, params) => {
                        setResolutionSeconds(convertResolutionToSeconds(resolution));
                        backtestingManager.updateSessionResolution(setSessions, currentSession.id, resolution);
                    });
                })
            }
        }
        return () => {
            replaySaveLoadAdapter.loadingAllCharts = false;
        }
    }, [currentSession]);

    useEffect(() => {
        replayDatafeed.processing()
        watcher().then(() => replayDatafeed.processingDone());
    }, [lastBar]);

    useEffect(() => {
        replayDatafeed.processing()
        watchPendingOrders().then(() => replayDatafeed.processingDone());
    }, [sessionOrders]);

    const watcher = async () => {
        await watchCurrentTradeLiquidation();
        await watchPendingTPSL();
        await watchPendingOrders();
    }

    const watchPendingOrders = async () => {
        if (lastBar != undefined && currentSession != undefined) {
            for (let i = 0; i < sessionOrders.length; i++) {
                const order = sessionOrders[i];

                if (order.orderType == "limit" && order.state == "awaiting") {
                    if (lastBar.high >= order.price && lastBar.low <= order.price) {
                        backtestingManager.triggerOrder(setOrders, currentSession!.id, order.id, lastBar!.time / 1000)
                            .then(() => backtestingManager.getSession(setSessions, currentSession.id)
                                .then((session) => setCurrentSession(session),
                                ),
                            );
                    }
                }
            }
        }
    }

    const watchPendingTPSL = async () => {
        if (lastBar != undefined && currentSession != undefined) {
            for (let i = 0; i < (trades.get(currentSession.id)?.length ?? 0); i++) {
                const trade = trades.get(currentSession.id)![i];

                if (trade.status == "open") {
                    for (let j = 0; j < trade.tpsl.length; j++) {
                        const tpsl = trade.tpsl[j];

                        const takeProfit = tpsl.takeProfit;
                        const stopLoss = tpsl.stopLoss;

                        if (takeProfit != undefined && !takeProfit.triggered) {
                            if (lastBar.high >= takeProfit.price && lastBar.low <= takeProfit.price) {
                                await backtestingManager.triggerTakeProfit(
                                    setTrades,
                                    setOrders,
                                    currentSession.id,
                                    trade.id,
                                    tpsl.id,
                                    lastBar.time / 1000
                                );

                                const session = await backtestingManager.getSession(setSessions, currentSession.id)
                                setCurrentSession(session);
                            }
                        }

                        if (stopLoss != undefined && !stopLoss.triggered) {
                            if (lastBar.high >= stopLoss.price && lastBar.low <= stopLoss.price) {
                                await backtestingManager.triggerStopLoss(
                                    setTrades,
                                    setOrders,
                                    currentSession.id,
                                    trade.id,
                                    tpsl.id,
                                    lastBar.time / 1000
                                );

                                const session = await backtestingManager.getSession(setSessions, currentSession.id)
                                setCurrentSession(session);
                            }
                        }
                    }
                }
            }
        }
    }

    const watchCurrentTradeLiquidation = async () => {
        if (lastBar != undefined && currentSession != undefined) {
            for (let i = 0; i < (trades.get(currentSession.id)?.length ?? 0); i++) {
                const trade = trades.get(currentSession.id)![i];

                if (trade.status == "open" && trade.side == "short") {
                    const session = currentSession!;

                    if (session.accountBalance - (session.amountOwed * lastBar.high) <= 0) {
                        const liquidationPrice = session.accountBalance / session.amountOwed;

                        await backtestingManager.liquidateTrade(setTrades, setOrders, session.id, trade.id, liquidationPrice, lastBar.time / 1000)

                        const liquidatedSession = await backtestingManager.getSession(setSessions, currentSession.id)
                        setCurrentSession(liquidatedSession);
                    }
                }
            }
        }
    }

    const computeTPSLQty = (qty: Qty): number => {
        if (qty.FixedAmount != undefined) return qty.FixedAmount;

        if (qty.Percentage != undefined) {
            return (currentSession!.assetAmount * qty.Percentage) / 100
        } else throw "no valid qty provided";
    }

    const handleReplayBackward = (timestamp: number) => {
        console.log(timestamp)
        console.log("================================================> stop dates:", stopDate.current, currentSession?.stopDate)
        if (timestamp < (stopDate.current ?? currentSession!.stopDate) && timestamp >= currentSession!.startDate) {
            rt.current = timestamp;
            setReplayTime(timestamp);
            return;
        }

        rt.current = undefined;
        setReplayTime(undefined);
    }

    const handleConfirmReplayBackward = (timestamp: number) => {
        console.log("Go to:", timestamp)
        if (timestamp < (stopDate.current ?? currentSession!.stopDate) && timestamp >= currentSession!.startDate) {
            setReplayTime(timestamp);
            rt.current = timestamp;
            replayDatafeed.setTimeframe(timestamp, replayTimeframe.from);
            replayDatafeed.resetCurrentChart();
            return;
        } else {
            replayDatafeed.setTimeframe(stopDate.current ?? currentSession!.stopDate, replayTimeframe.from);
            replayDatafeed.resetCurrentChart();
        }

        rt.current = undefined;
        setReplayTime(undefined);
    }

    const getLastTime = () => {
        if (lastBar != undefined) {
            return lastBar.time;
        } else if (currentSession != undefined) {
            return (stopDate.current ?? currentSession!.stopDate) * 1000;
        }
        return 0;
    }

    return (
        <>
            <div>

                <div className='lg:flex lg:flex-row justify-stretch divide-x h-screen divide-gray-700'>
                    <div className={classNames('lg:flex lg:flex-col justify-stretch divide-y divide-gray-700', !sidebarOpen ? "basis-full" : 'xl:basis-11/12 lg:basis-4/5 lg:h-screen')} style={{ height: innerHeight }}>
                        <div className='flex flex-row justify-between basis-7 px-6 py-2 bg-gray-950'>
                            <button
                                type="button"
                                onClick={async () => {
                                    replayDatafeed.updateSpeed(1000);
                                    await backtestingManager.updateSessionStopTime(setSessions, currentSession!.id, stopDate.current!);
                                    currentSession?.strategyId != undefined ? navigate(`/app/strategy/${currentSession.strategyId}/sessions`) : navigate("/app");
                                }}
                                className="rounded-md bg-white/10 px-2 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                            >
                                Exit Session
                            </button>
                            {
                                currentSession != undefined
                                    ? <p className='text-white'>
                                        {replayTime != undefined
                                            ? new Date(replayTime * 1000).toUTCString()
                                            : new Date(getLastTime()).toUTCString()}
                                    </p>
                                    : null
                            }
                            <button
                                type="button"
                                className="rounded-md bg-white/10 px-2 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                onClick={() => replayDatafeed.revertReplay()}
                            >
                                Restart
                            </button>
                        </div>


                        <div id='chartContainer' className={classNames(bottomBarOpen ? 'lg:basis-11/12 h-96 flex-1' : 'flex-1 basis-full')}></div>
                        {
                            currentSession != undefined
                                ? <input
                                    type="range"
                                    min={currentSession.startDate}
                                    max={currentSession.endDate}
                                    step={resolutionSeconds ?? 1}
                                    value={replayTime ?? (lastBar?.time != undefined ? lastBar.time / 1000 : (stopDate.current ?? currentSession.stopDate))}
                                    onChange={(e) => {
                                        replayDatafeed.pauseReplay();
                                        handleReplayBackward(parseInt(e.target.value));
                                    }}

                                    onMouseUp={(e) => {
                                        handleConfirmReplayBackward(parseInt(e.currentTarget.value));
                                    }} />
                                : null
                        }
                        <div className='flex flex-row justify-center items-center space-x-4 basis-0'>
                            <button
                                type="button"
                                className="rounded bg-white/10 my-2 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                                onClick={() => replayDatafeed.pauseReplay()}
                            >
                                Pause
                            </button>
                            <button
                                type="button"
                                className="rounded bg-white/10 my-2 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20"
                                onClick={() => replayDatafeed.startReplay()}
                            >
                                Play
                            </button>

                            <input type="range" min={1} max={20} defaultValue={1} onChange={async (e) => replayDatafeed.updateSpeed(1000 / parseInt(e.target.value))} />
                            <p className='text-white'>Play Speed</p>
                        </div>
                        <div className={classNames(bottomBarOpen ? 'flex-shrink bg-gray-950' : 'bg-gray-950')}>
                            <div className="border-b border-gray-600 pb-5 sm:pb-0">
                                <div className="mt-3 sm:mt-4">
                                    <div className="sm:hidden bg-gray-900">
                                        <label htmlFor="current-tab" className="sr-only">
                                            Select a tab
                                        </label>
                                        <select
                                            id="current-tab"
                                            name="current-tab"
                                            defaultValue={tabs.find((tab) => tab.current)?.name ?? "no name"}
                                            className="block w-full rounded-md border-gray-600 py-2 pl-3 pr-10 text-base focus:border-indigo-400 focus:outline-none focus:ring-indigo-400 sm:text-sm"
                                        >
                                            {tabs.map((tab) => (
                                                <option key={tab.name}>{tab.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="hidden sm:flex sm:flex-row sm:justify-between sm:items-start sm:ml-4">
                                        <nav className="-mb-px flex space-x-8">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab.name}
                                                    aria-current={tab.current ? 'page' : undefined}
                                                    className={classNames(
                                                        tab.current
                                                            ? 'border-indigo-300 text-indigo-300'
                                                            : 'border-transparent text-gray-200 hover:border-gray-600 hover:text-gray-50',
                                                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                                                    )}
                                                    onClick={() => {
                                                        setTabs(tabs.map((t) => {
                                                            if (t.name == tab.name) {
                                                                t.current = true;
                                                            } else {
                                                                t.current = false;
                                                            }

                                                            return t;
                                                        }))
                                                    }}
                                                >
                                                    {tab.name}
                                                </div>
                                            ))}
                                        </nav>
                                        <button
                                            type="button"
                                            className="rounded-md mr-4 mb-2 bg-gray-900 p-1 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            onClick={() => {
                                                setBottomBarOpen(!bottomBarOpen);
                                            }}
                                        >
                                            {
                                                bottomBarOpen
                                                    ? <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                                                    : <ChevronUpIcon aria-hidden="true" className="h-5 w-5" />}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {
                                bottomBarOpen
                                    ?
                                    <div className="max-h-60 h-60">
                                        {tabs.find((tab) => tab.current)?.component ?? null}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    {
                        sidebarOpen
                            ? <OrderMaker />
                            : null
                    }
                </div>


                {/* Static sidebar for desktop */}
                {/* <div className="fixed inset-y-0 right-0 z-50 block w-20 overflow-y-auto bg-gray-950 pb-4">
                    <div className="flex h-16 shrink-0 items-center justify-center">
                        <p className=' text-2xl font-bold tracking-tight text-indigo-400 sm:text-4xl'>
                            TL
                        </p>
                    </div>
                    <nav className="mt-8">
                        <ul role="list" className="flex flex-col items-center space-y-1">
                            {navigation.map((item) => (
                                <li key={item.name}>
                                    <div
                                        className={classNames(
                                            item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                            'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                                        )}
                                        onClick={() => {
                                            setNavigation(navigation.map((n) => {
                                                if (n.name == item.name) {
                                                    if (n.current == true) {
                                                        n.current = false;
                                                        setSidebarOpen(false);
                                                    } else {
                                                        n.current = true;
                                                        setSidebarOpen(true);
                                                    }
                                                } else {
                                                    n.current = false;
                                                }

                                                return n;
                                            }))
                                        }}
                                    >
                                        <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                                        <span className="sr-only">{item.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div> */}

                {/* <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
                    <a href="#">
                        <span className="sr-only">Your profile</span>
                        <img
                            alt=""
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            className="h-8 w-8 rounded-full bg-gray-800"
                        />
                    </a>
                </div> */}


            </div>
        </>
    )
}