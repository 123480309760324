import { SessionStatistics } from "../../../../../state/statistics/models/statistics_models";
import { formatDuration } from "../../../../../utils/date";

export function sessionKPI(sessionStatistics: SessionStatistics) {
    return [
        {
            name: "Net Profit",
            value: sessionStatistics.netProfit,
            display: `${Math.round(sessionStatistics.netProfit * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: sessionStatistics.grossProfit,
            display: `${Math.round(sessionStatistics.grossProfit * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: sessionStatistics.grossLoss,
            display: `${Math.round(sessionStatistics.grossLoss * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Return On Investment",
            value: sessionStatistics.returnOnInvestment,
            display: `${Math.round(sessionStatistics.returnOnInvestment * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Compound Annual Growth Rate",
            value: sessionStatistics.compoundAnnualGrowthRate,
            display: `${Math.round(sessionStatistics.compoundAnnualGrowthRate * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: sessionStatistics.winRate,
            display: `${Math.round(sessionStatistics.winRate * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Annualized Volatility",
            value: sessionStatistics.annualizedVolatility,
            display: `${Math.round(sessionStatistics.annualizedVolatility * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: sessionStatistics.averageWin,
            display: `${Math.round(sessionStatistics.averageWin * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: sessionStatistics.averageLoss,
            display: `${Math.round(sessionStatistics.averageLoss * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: sessionStatistics.profitFactor,
            display: `${Math.round(sessionStatistics.profitFactor * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward Ratio",
            value: sessionStatistics.averageRiskReward,
            display: `${Math.round(sessionStatistics.averageRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward Ratio",
            value: sessionStatistics.averageIdealRiskReward,
            display: `${Math.round(sessionStatistics.averageIdealRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Drawdown",
            value: sessionStatistics.maxDrawdown,
            display: `${Math.round(sessionStatistics.maxDrawdown * 100) / 100} USD / ${Math.round(sessionStatistics.maxDrawdownPercentage * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Drawup",
            value: sessionStatistics.maxDrawup,
            display: `${Math.round(sessionStatistics.maxDrawup * 100) / 100} USD / ${Math.round(sessionStatistics.maxDrawupPercentage * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Calmar Ratio",
            value: sessionStatistics.calmarRatio,
            display: `${Math.round(sessionStatistics.calmarRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Sharpe Ratio",
            value: sessionStatistics.sharpeRatio,
            display: `${Math.round(sessionStatistics.sharpeRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Sortino Ratio",
            value: sessionStatistics.sortinoRatio,
            display: `${Math.round(sessionStatistics.sortinoRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Omega Ratio",
            value: sessionStatistics.omegaRatio,
            display: `${Math.round(sessionStatistics.omegaRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Value At Risk",
            value: sessionStatistics.valueAtRisk,
            display: `${Math.round(sessionStatistics.valueAtRisk * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Expected Shortfall",
            value: sessionStatistics.conditionalValueAtRisk,
            display: `${Math.round(sessionStatistics.conditionalValueAtRisk * 10000) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Tail Ratio",
            value: sessionStatistics.tailRatio,
            display: `${Math.round(sessionStatistics.tailRatio * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: sessionStatistics.zScore,
            display: `${Math.round(sessionStatistics.zScore * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Kurtosis",
            value: sessionStatistics.kurtosis,
            display: `${Math.round(sessionStatistics.kurtosis * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Skewness",
            value: sessionStatistics.skewness,
            display: `${Math.round(sessionStatistics.skewness * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward Ratio",
            value: sessionStatistics.averageRiskReward,
            display: `${Math.round(sessionStatistics.averageRiskReward * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Trades",
            value: sessionStatistics.totalNumberOfTrades,
            display: `${sessionStatistics.totalNumberOfTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Order",
            value: sessionStatistics.totalNumberOfOrder,
            display: `${sessionStatistics.totalNumberOfOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Buy Order",
            value: sessionStatistics.totalNumberOfBuyOrder,
            display: `${sessionStatistics.totalNumberOfBuyOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number of Sell Order",
            value: sessionStatistics.totalNumberOfSellOrder,
            display: `${sessionStatistics.totalNumberOfSellOrder}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: sessionStatistics.winningTrades,
            display: `${sessionStatistics.winningTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: sessionStatistics.losingTrades,
            display: `${sessionStatistics.losingTrades}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Consecutive Wins",
            value: sessionStatistics.averageConsecutiveWins,
            display: `${sessionStatistics.averageConsecutiveWins}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Consecutive Losses",
            value: sessionStatistics.averageConsecutiveLosses,
            display: `${sessionStatistics.averageConsecutiveLosses}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Consecutive Wins",
            value: sessionStatistics.maxConsecutiveWins,
            display: `${sessionStatistics.maxConsecutiveWins}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Max Consecutive Losses",
            value: sessionStatistics.maxConsecutiveLosses,
            display: `${sessionStatistics.maxConsecutiveLosses}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: sessionStatistics.timeInMarket,
            display: `${formatDuration(sessionStatistics.timeInMarket)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: sessionStatistics.averageTradeDuration,
            display: `${formatDuration(sessionStatistics.averageTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: sessionStatistics.maximumTradeDuration,
            display: `${formatDuration(sessionStatistics.maximumTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: sessionStatistics.minimumTradeDuration,
            display: `${formatDuration(sessionStatistics.minimumTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ];
}

export function sessionKPILongSide(sessionStatistics: SessionStatistics) {
    return [
        {
            name: "Net Profit",
            value: sessionStatistics.netProfitLongSide,
            display: `${Math.round(sessionStatistics.netProfitLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: sessionStatistics.grossProfitLongSide,
            display: `${Math.round(sessionStatistics.grossProfitLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: sessionStatistics.grossLossLongSide,
            display: `${Math.round(sessionStatistics.grossLossLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: sessionStatistics.winRateLongSide,
            display: `${Math.round(sessionStatistics.winRateLongSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Loss Rate",
            value: sessionStatistics.lossRateLongSide,
            display: `${Math.round(sessionStatistics.lossRateLongSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: sessionStatistics.averageWinLongSide,
            display: `${Math.round(sessionStatistics.averageWinLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: sessionStatistics.averageLossLongSide,
            display: `${Math.round(sessionStatistics.averageLossLongSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: sessionStatistics.profitFactorLongSide,
            display: `${Math.round(sessionStatistics.profitFactorLongSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: sessionStatistics.zScoreLongSide,
            display: `${Math.round(sessionStatistics.zScoreLongSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward",
            value: sessionStatistics.averageRiskRewardLongTrades,
            display: `${Math.round(sessionStatistics.averageRiskRewardLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: sessionStatistics.averageIdealRiskRewardLongTrades,
            display: `${Math.round(sessionStatistics.averageIdealRiskRewardLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number Of Trades",
            value: sessionStatistics.totalNumberOfLongTrades,
            display: `${Math.round(sessionStatistics.totalNumberOfLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: sessionStatistics.winningLongTrades,
            display: `${Math.round(sessionStatistics.winningLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: sessionStatistics.losingLongTrades,
            display: `${Math.round(sessionStatistics.losingLongTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: sessionStatistics.timeInMarketLongTrades,
            display: `${formatDuration(sessionStatistics.timeInMarketLongTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: sessionStatistics.averageLongTradeDuration,
            display: `${formatDuration(sessionStatistics.averageLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: sessionStatistics.maximumLongTradeDuration,
            display: `${formatDuration(sessionStatistics.maximumLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: sessionStatistics.minimumLongTradeDuration,
            display: `${formatDuration(sessionStatistics.minimumLongTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function sessionKPIShortSide(sessionStatistics: SessionStatistics) {
    return [
        {
            name: "Net Profit",
            value: sessionStatistics.netProfitShortSide,
            display: `${Math.round(sessionStatistics.netProfitShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Profit",
            value: sessionStatistics.grossProfitShortSide,
            display: `${Math.round(sessionStatistics.grossProfitShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Gross Loss",
            value: sessionStatistics.grossLossShortSide,
            display: `${Math.round(sessionStatistics.grossLossShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Win Rate",
            value: sessionStatistics.winRateShortSide,
            display: `${Math.round(sessionStatistics.winRateShortSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Loss Rate",
            value: sessionStatistics.lossRateShortSide,
            display: `${Math.round(sessionStatistics.lossRateShortSide * 100) / 100}%`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Win",
            value: sessionStatistics.averageWinShortSide,
            display: `${Math.round(sessionStatistics.averageWinShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Loss",
            value: sessionStatistics.averageLossShortSide,
            display: `${Math.round(sessionStatistics.averageLossShortSide * 100) / 100} USD`,
            description: "",
            infoLink: "",
        },
        {
            name: "Profit Factor",
            value: sessionStatistics.profitFactorShortSide,
            display: `${Math.round(sessionStatistics.profitFactorShortSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Z-Score",
            value: sessionStatistics.zScoreShortSide,
            display: `${Math.round(sessionStatistics.zScoreShortSide * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Risk Reward",
            value: sessionStatistics.averageRiskRewardShortTrades,
            display: `${Math.round(sessionStatistics.averageRiskRewardShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: sessionStatistics.averageIdealRiskRewardShortTrades,
            display: `${Math.round(sessionStatistics.averageIdealRiskRewardShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Total Number Of Trades",
            value: sessionStatistics.totalNumberOfShortTrades,
            display: `${Math.round(sessionStatistics.totalNumberOfShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Winning Trades",
            value: sessionStatistics.winningShortTrades,
            display: `${Math.round(sessionStatistics.winningShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Losing Trades",
            value: sessionStatistics.losingShortTrades,
            display: `${Math.round(sessionStatistics.losingShortTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: sessionStatistics.timeInMarketShortTrades,
            display: `${formatDuration(sessionStatistics.timeInMarketShortTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: sessionStatistics.averageShortTradeDuration,
            display: `${formatDuration(sessionStatistics.averageShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: sessionStatistics.maximumShortTradeDuration,
            display: `${formatDuration(sessionStatistics.maximumShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: sessionStatistics.minimumShortTradeDuration,
            display: `${formatDuration(sessionStatistics.minimumShortTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function sessionKPIWinningSide(sessionStatistics: SessionStatistics) {
    return [
        {
            name: "Average Risk Reward",
            value: sessionStatistics.averageRiskRewardWinningTrades,
            display: `${Math.round(sessionStatistics.averageRiskRewardWinningTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: sessionStatistics.averageIdealRiskRewardWinningTrades,
            display: `${Math.round(sessionStatistics.averageIdealRiskRewardWinningTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: sessionStatistics.timeInMarketWinningTrades,
            display: `${formatDuration(sessionStatistics.timeInMarketWinningTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: sessionStatistics.averageWinningTradeDuration,
            display: `${formatDuration(sessionStatistics.averageWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: sessionStatistics.maximumWinningTradeDuration,
            display: `${formatDuration(sessionStatistics.maximumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: sessionStatistics.minimumWinningTradeDuration,
            display: `${formatDuration(sessionStatistics.minimumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}

export function sessionKPILosingSide(sessionStatistics: SessionStatistics) {
    return [
        {
            name: "Average Risk Reward",
            value: sessionStatistics.averageRiskRewardLosingTrade,
            display: `${Math.round(sessionStatistics.averageRiskRewardLosingTrade * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Ideal Risk Reward",
            value: sessionStatistics.averageIdealRiskRewardLosingTrades,
            display: `${Math.round(sessionStatistics.averageIdealRiskRewardLosingTrades * 100) / 100}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Time In Market",
            value: sessionStatistics.timeInMarketLosingTrades,
            display: `${formatDuration(sessionStatistics.timeInMarketLosingTrades)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Average Trade Duration",
            value: sessionStatistics.averageLosingTradeDuration,
            display: `${formatDuration(sessionStatistics.averageLosingTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Maximum Trade Duration",
            value: sessionStatistics.maximumLosingTradeDuration,
            display: `${formatDuration(sessionStatistics.maximumLosingTradeDuration)}`,
            description: "",
            infoLink: "",
        },
        {
            name: "Minimum Trade Duration",
            value: sessionStatistics.minimumWinningTradeDuration,
            display: `${formatDuration(sessionStatistics.minimumWinningTradeDuration)}`,
            description: "",
            infoLink: "",
        },
    ]
}