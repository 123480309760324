import { useRecoilState, useRecoilValue } from "recoil"
import { statisticsManagerState } from "../../../../../../state/statistics/statistics_manager_state"
import { strategyStatisticsState } from "../../../../../../state/statistics/atoms/strategy_statistics";
import { useEffect } from "react";
import { currentStrategyState } from "../../../../../../state/backtesting/atoms/current_strategy";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, AreaChart, Area, Brush, Legend, ComposedChart, ResponsiveContainer } from 'recharts';
import { BalanceMovement, PL, StrategyStatistics, TimedMetric } from "../../../../../../state/statistics/models/statistics_models";
import { DrawdownDrawupGraphData } from "../../../../../../state/statistics/models/graphs_models";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { ContentType } from "recharts/types/component/Tooltip";
import { strategyKPI, strategyKPILongSide, strategyKPILosingSide, strategyKPIShortSide, strategyKPIWinningSide } from "./kpis";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function StrategyStatisticsView() {
    const currentStrategy = useRecoilValue(currentStrategyState);
    const statisticsManager = useRecoilValue(statisticsManagerState);
    const [strategyStats, setStrategyStats] = useRecoilState(strategyStatisticsState);

    const stats = [
        { name: 'Z-S', value: '$405,091.00', change: '+4.75%', changeType: 'positive' },
        { name: 'ROI', value: '$12,787.00', change: '+54.02%', changeType: 'negative' },
        { name: 'CAGR', value: '$245,988.00', change: '-1.39%', changeType: 'positive' },
        { name: 'Sort.', value: '$30,156.00', change: '+10.18%', changeType: 'negative' },
    ]

    useEffect(() => {
        if (currentStrategy != undefined)
            statisticsManager.generateStrategyStatistics(setStrategyStats, currentStrategy!.id, "")
                .then((stats) => console.log(stats));
    }, [currentStrategy]);

    const EquityCurveTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="p-4 bg-gray-50 bg-opacity-85">
                    <p className="intro">{new Date(label * 1000).toDateString()}</p>
                    <p className="text-indigo-500">{`Equity : ${Math.round(payload[0].value * 100) / 100} USD`}</p>
                </div>
            );
        }

        return null;
    };

    const renderEquityCurve = (equityCurve: TimedMetric[], stats: { name: string, value: number, display: string, percentage?: number }[]) => {
        const dataMax = Math.max(...equityCurve.map((i) => i.value));
        const dataMin = Math.min(...equityCurve.map((i) => i.value));

        return (
            <div className="divide-y flex flex-col divide-gray-600 rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">Equity Curve</p>
                </div>
                <div>
                    <dl className="grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-4">
                        {stats.map((stat) => (
                            <div
                                key={stat.name}
                                className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-5 sm:px-6 bg-gray-900 bg-opacity-85"
                            >
                                <dt className="text-sm/6 font-medium text-gray-100">{stat.name}</dt>
                                {/* <dd
                                    className={classNames(
                                        stat.changeType === 'negative' ? 'text-red-600' : 'text-green-700',
                                        'text-xs font-medium',
                                    )}
                                >
                                    {stat.change}
                                </dd> */}
                                <dd className="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-50">{stat.display}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="flex-1 h-full px-4 py-4">
                    <ResponsiveContainer>
                        <AreaChart data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Area type="natural" dataKey="value" stroke="#8884d8" fill="#8884d8" name="Equity" />

                            <XAxis dataKey="date" tickFormatter={(date) => {
                                const d = new Date(date * 1000);

                                return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                            }} />
                            <YAxis dataKey="value" domain={[dataMin, dataMax]} origin={equityCurve[0]?.value ?? 0} />
                            <Tooltip content={<EquityCurveTooltip />} />
                            <Legend />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>

        );
    }

    const DrawdownDrawupTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="p-4 bg-gray-50 bg-opacity-85">
                    <p className="intro">{new Date(label * 1000).toDateString()}</p>
                    <br />
                    {payload[0].payload.drawdown != undefined ? <p className="text-red-500">{`Drawdown : ${Math.round(payload[0].payload.drawdown * 10000) / 100} %`}</p> : null}
                    {payload[0].payload.drawup != undefined ? <p className="text-green-700">{`Drawup : ${Math.round(payload[0].payload.drawup * 10000) / 100} %`}</p> : null}
                </div>
            );
        }

        return null;
    };

    const renderDrawdownDrawupCurve = (drawDownCurve: TimedMetric[], drawUpCurve: TimedMetric[], stats: { name: string, value: number, display: string, percentage?: number }[]) => {
        const drawdownDrawup: DrawdownDrawupGraphData[] = [];
        const drawdownDrawupMap = new Map<number, DrawdownDrawupGraphData>();

        for (const element of drawDownCurve) {
            const d = drawdownDrawupMap.get(element.date);
            if (d == undefined) {
                drawdownDrawupMap.set(element.date, new DrawdownDrawupGraphData(element.date, element.value))
            } else {
                drawdownDrawupMap.set(element.date, new DrawdownDrawupGraphData(element.date, element.value, d.drawup))
            }
        }

        for (const element of drawUpCurve) {
            const d = drawdownDrawupMap.get(element.date);
            if (d == undefined) {
                drawdownDrawupMap.set(element.date, new DrawdownDrawupGraphData(element.date, undefined, element.value))
            } else {
                drawdownDrawupMap.set(element.date, new DrawdownDrawupGraphData(element.date, d.drawdown, element.value))
            }
        }

        drawdownDrawupMap.forEach(value => {
            drawdownDrawup.push(value);
        });

        drawdownDrawup.sort((a, b) => a.date - b.date);

        const drawdownMax = Math.max(...drawdownDrawup.map((i) => i.drawdown ?? 0));
        const drawupMax = Math.max(...drawdownDrawup.map((i) => i.drawup ?? 0));
        const drawdownMin = Math.min(...drawdownDrawup.map((i) => i.drawdown ?? 0));
        const drawupMin = Math.min(...drawdownDrawup.map((i) => i.drawup ?? 0));

        const dataMax = Math.max(drawdownMax, drawupMax)
        const dataMin = Math.min(drawdownMin, drawupMin)

        return (
            <div className="divide-y flex flex-col divide-gray-600 rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">Drawdown And Drawup</p>
                </div>
                <div>
                    <dl className="grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-4">
                        {stats.map((stat) => (
                            <div
                                key={stat.name}
                                className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-5 sm:px-6 bg-gray-900 bg-opacity-85"
                            >
                                <dt className="text-sm/6 font-medium text-gray-100">{stat.name}</dt>
                                {/* <dd
                                    className={classNames(
                                        stat.changeType === 'negative' ? 'text-red-600' : 'text-green-700',
                                        'text-xs font-medium',
                                    )}
                                >
                                    {stat.change}
                                </dd> */}
                                <dd className="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-50">{stat.display}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="px-4 pt-5 flex-1 h-full">
                    <ResponsiveContainer>
                        <AreaChart data={drawdownDrawup} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Area type="natural" dataKey="drawdown" stroke="red" fill="red" connectNulls={true} name="Drawdown" />
                            <Area type="natural" dataKey="drawup" stroke="green" fill="green" connectNulls={true} name="Drawup" />

                            <XAxis dataKey="date" tickFormatter={(date) => {
                                const d = new Date(date * 1000);

                                return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                            }} />
                            <YAxis domain={[dataMin - dataMin / 10, dataMax + dataMax / 10]} tickFormatter={(value => `${Math.round(value * 10000) / 100}%`)} />
                            <Tooltip content={<DrawdownDrawupTooltip />} />
                            <Legend />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }

    const renderIdealRiskRewardCurve = (riskRewardCurve: TimedMetric[]) => (
        <div className="divide-y divide-gray-600 overflow-hidden rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
            <div className="px-4 pt-5">
                <p className="text-gray-50">Equity Curve</p>
            </div>

            <div className="px-4 py-5 sm:p-6 h-full">
                <ResponsiveContainer>
                    <LineChart data={riskRewardCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line type="natural" dataKey="value" stroke="#8884d8" />

                        <XAxis dataKey="date" tickFormatter={(date) => {
                            const d = new Date(date * 1000);

                            return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                        }} />
                        <YAxis dataKey="value" />
                        <Tooltip labelFormatter={(date) => new Date(date * 1000).toUTCString()} />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );

    const PLTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="p-4 bg-gray-50 bg-opacity-85">
                    <p className="intro">{new Date(label * 1000).toUTCString()}</p>
                    <br />
                    {payload[0].payload.profit != undefined ? <p className={payload[0].payload.profit > 0 ? "text-green-700" : "text-red-500"}>{`Profit : ${payload[0].value > 0 ? "+" : ""}${Math.round(payload[0].payload.profit * 100) / 100} USD`}</p> : null}
                    {payload[0].payload.valueEngaged != undefined ? <p className="text-teal-600">{`Amount Invested : ${Math.round(payload[0].payload.valueEngaged * 100) / 100} USD`}</p> : null}
                    {payload[0].payload.expectedProfit != undefined || payload[0].payload.expectedProfit < 0 ? <p className="text-green-500">{`Expected Profit : ${Math.round(payload[0].payload.expectedProfit * 100) / 100} USD`}</p> : null}
                    {payload[0].payload.valueRisked != undefined || payload[0].payload.valueRisked < 0 ? <p className="text-red-600">{`Amount Risked : ${Math.round(payload[0].payload.valueRisked * 100) / 100} USD`}</p> : null}
                    {payload[0].payload.riskRewardRatio != undefined || payload[0].payload.riskRewardRatio < 0 ? <p className="text-blue-500">{`Risk Reward Ratio : ${Math.round(payload[0].payload.riskRewardRatio * 1000) / 1000}`}</p> : null}
                </div>
            );
        }

        return null;
    };

    const renderPLCurve = (plCurve: PL[], stats: { name: string, value: number, display: string, percentage?: number }[]) => {
        const gradientOffset = () => {
            const dataMax = Math.max(...plCurve.map((i) => i.profit));
            const dataMin = Math.min(...plCurve.map((i) => i.profit));

            if (dataMax <= 0) {
                return 0;
            }
            if (dataMin >= 0) {
                return 1;
            }

            return dataMax / (dataMax - dataMin);
        };

        const off = gradientOffset();

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">Profit And Loss</p>
                </div>
                <div>
                    <dl className="grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-8">
                        {stats.map((stat) => (
                            <div
                                key={stat.name}
                                className="flex flex-wrap items-baseline justify-between gap-x-2 gap-y-1 px-2 py-2 sm:px-6 bg-gray-900 bg-opacity-85"
                            >
                                <dt className="text-sm/6 font-medium text-gray-100">{stat.name}</dt>
                                <dd
                                    className={classNames(
                                        stat.value < 0 ? 'text-red-600' : 'text-green-700',
                                        'text-xs font-medium',
                                    )}
                                >
                                    {stat.percentage}
                                </dd>
                                <dd className="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-50">{stat.display}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="pt-5 flex-1 h-full">
                    <ResponsiveContainer>
                        <ComposedChart data={plCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tickFormatter={(date) => {
                                const d = new Date(date * 1000);

                                return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                            }} />
                            <YAxis yAxisId="left" dataKey="profit" />
                            <YAxis yAxisId="riskRewardRatio" dataKey="riskRewardRatio" />
                            <YAxis yAxisId="right" orientation="right" dataKey="valueEngaged" />
                            <Tooltip content={<PLTooltip />} />

                            <defs>
                                <linearGradient id="splitColorPL" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset={off} stopColor="green" stopOpacity={1} />
                                    <stop offset={off} stopColor="red" stopOpacity={1} />
                                </linearGradient>
                            </defs>

                            <Area yAxisId="left" type="monotone" dataKey="profit" stroke="url(#splitColorPL)" fill="url(#splitColorPL)" name="Profits" />
                            <Line yAxisId="riskRewardRatio" type="monotone" dataKey="riskRewardRatio" fill="skyblue" stroke="skyblue" name="Risk/Reward Ratio" opacity={0.8} />
                            <Bar yAxisId="right" type="monotone" barSize={8} dataKey="valueEngaged" stroke="skyblue" fill="teal" opacity={0.3} name="Value Invested" />
                            <Bar yAxisId="right" type="monotone" barSize={8} dataKey="expectedProfit" stroke="green" fill="green" opacity={0.3} name="Expected Profit" />
                            <Bar yAxisId="right" type="monotone" barSize={8} dataKey="valueRisked" stroke="red" fill="red" opacity={0.3} name="Value Risked" />
                            <Legend />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }

    const DailyReturnsTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="p-4 bg-gray-50 bg-opacity-85">
                    <p className="intro">{new Date(label * 1000).toDateString()}</p>
                    <p className={payload[0].value > 0 ? "text-green-700" : "text-red-600"}>{`Equity : ${payload[0].value > 0 ? "+" : ""}${Math.round(payload[0].value * 100) / 100} %`}</p>
                </div>
            );
        }

        return null;
    };

    const renderDailyReturns = (dailyReturns: TimedMetric[], stats: { name: string, value: number, display: string, percentage?: number }[]) => {
        const dailyReturnsAsPercentage = dailyReturns.map((dailyReturns) => new TimedMetric(dailyReturns.date, Math.round(dailyReturns.value * 10000) / 100, dailyReturns.weight))
        const gradientOffset = () => {
            const dataMax = Math.max(...dailyReturnsAsPercentage.map((i) => i.value));
            const dataMin = Math.min(...dailyReturnsAsPercentage.map((i) => i.value));

            if (dataMax <= 0) {
                return 0;
            }
            if (dataMin >= 0) {
                return 1;
            }

            return dataMax / (dataMax - dataMin);
        };

        const off = gradientOffset();

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50 h-full">Daily Returns</p>
                </div>
                <div>
                    <dl className="grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-5">
                        {stats.map((stat) => (
                            <div
                                key={stat.name}
                                className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-5 sm:px-6 bg-gray-900 bg-opacity-85"
                            >
                                <dt className="text-sm/6 font-medium text-gray-100">{stat.name}</dt>
                                {/* <dd
                                    className={classNames(
                                        stat.changeType === 'negative' ? 'text-red-600' : 'text-green-700',
                                        'text-xs font-medium',
                                    )}
                                >
                                    {stat.change}
                                </dd> */}
                                <dd className="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-50">{stat.display}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="pt-5 flex-1 h-full">
                    <ResponsiveContainer>
                        <AreaChart data={dailyReturnsAsPercentage} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tickFormatter={(date) => {
                                const d = new Date(date * 1000);

                                return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                            }} />
                            <YAxis dataKey="value" label={"daily return"} />
                            <Tooltip content={<DailyReturnsTooltip />} />

                            <defs>
                                <linearGradient id="splitColorDailyReturns" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset={off} stopColor="green" stopOpacity={1} />
                                    <stop offset={off} stopColor="red" stopOpacity={1} />
                                </linearGradient>
                            </defs>

                            <Area type="monotone" dataKey="value" stroke="url(#splitColorDailyReturns)" fill="url(#splitColorDailyReturns)" name="Daily Returns" />
                            <Legend />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );

    }

    const renderBalanceMovement = (equityCurve: BalanceMovement[]) => (
        <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
            <div className="px-4 py-5 sm:px-6">
                <p className="text-gray-50">Balance Movements</p>
            </div>
            <div>
                <dl className="grid grid-cols-1 gap-px bg-gray-700 sm:grid-cols-2 lg:grid-cols-4">
                    {stats.map((stat) => (
                        <div
                            key={stat.name}
                            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-5 sm:px-6 bg-gray-900 bg-opacity-85"
                        >
                            <dt className="text-sm/6 font-medium text-gray-100">{stat.name}</dt>
                            <dd
                                className={classNames(
                                    stat.changeType === 'negative' ? 'text-red-600' : 'text-green-700',
                                    'text-xs font-medium',
                                )}
                            >
                                {stat.change}
                            </dd>
                            <dd className="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-50">{stat.value}</dd>
                        </div>
                    ))}
                </dl>
            </div>
            <div className="pt-5 flex-1 h-full">
                <ResponsiveContainer>
                    <ComposedChart data={equityCurve} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                        <XAxis dataKey="date" label={"date"} tickFormatter={(date) => {
                            const d = new Date(date * 1000);

                            return `${d.getDay().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;
                        }} />
                        <YAxis yAxisId="left" label={"balance"} />
                        <YAxis yAxisId="right" orientation="right" label={"traded assets balance"} />

                        <Tooltip labelFormatter={(date) => new Date(date * 1000).toUTCString()} />
                        <Legend />

                        <Line yAxisId="left" type="monotone" dataKey="balance" fill="#8884d8" stroke="#8884d8" name="Balance (USD)" />
                        <Bar spacing={20} barSize={30} yAxisId="right" type="monotone" dataKey="assetAmount" fill="#82ca9d" stroke="#82ca9d" name="Amount Long" opacity={0.4} />
                        <Bar spacing={20} barSize={30} yAxisId="right" type="monotone" dataKey="amountOwed" fill="#f08080" stroke="#f08080" name="Amount Short" opacity={0.4} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );

    const renderKPI = (strategyStatistics: StrategyStatistics) => {
        const stats = strategyKPI(strategyStatistics);

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">Strategy KPIs</p>
                </div>
                <div className="pt-5 px-12 py-16 flex-1 grid grid-cols-3 content-evenly">
                    {stats.map((stat) => {
                        return (
                            <div>
                                <p className="text-start">
                                    <span className="text-gray-400">{stat.name}:</span> <span className="text-gray-50">{stat.display}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    const renderLongSideKPI = (strategyStatistics: StrategyStatistics) => {
        const stats = strategyKPILongSide(strategyStatistics);

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">KPIs Long Trades</p>
                </div>
                <div className="pt-5 px-12 py-16 flex-1 grid grid-cols-2 content-evenly">
                    {stats.map((stat) => {
                        return (
                            <div>
                                <p className="text-start">
                                    <span className="text-gray-400">{stat.name}:</span> <span className="text-gray-50">{stat.display}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    const renderShortSideKPI = (strategyStatistics: StrategyStatistics) => {
        const stats = strategyKPIShortSide(strategyStatistics);

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">KPIs Short Trades</p>
                </div>
                <div className="pt-5 px-12 py-16 flex-1 grid grid-cols-2 content-evenly">
                    {stats.map((stat) => {
                        return (
                            <div>
                                <p className="text-start">
                                    <span className="text-gray-400">{stat.name}:</span> <span className="text-gray-50">{stat.display}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    const renderWinningSideKPI = (strategyStatistics: StrategyStatistics) => {
        const stats = strategyKPIWinningSide(strategyStatistics);

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">KPIs Winning Trades</p>
                </div>
                <div className="pt-5 px-12 py-16 flex-1 grid grid-cols-2 content-evenly">
                    {stats.map((stat) => {
                        return (
                            <div>
                                <p className="text-start">
                                    <span className="text-gray-400">{stat.name}:</span> <span className="text-gray-50">{stat.display}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    const renderLosingSideKPI = (strategyStatistics: StrategyStatistics) => {
        const stats = strategyKPILosingSide(strategyStatistics);

        return (
            <div className="divide-y divide-gray-600 flex flex-col rounded-lg bg-gray-800 bg-opacity-40 border-gray-800 border h-full shadow">
                <div className="px-4 py-5 sm:px-6">
                    <p className="text-gray-50">KPIs Losing Trades</p>
                </div>
                <div className="pt-5 px-12 py-16 flex-1 grid grid-cols-2 content-evenly">
                    {stats.map((stat) => {
                        return (
                            <div>
                                <p className="text-start">
                                    <span className="text-gray-400">{stat.name}:</span> <span className="text-gray-50">{stat.display}</span>
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-center space-y-6 w-full items-center mt-12">
            <div className="flex flex-row space-x-12 w-full">
                <div className="flex-1" style={{ height: "70vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderPLCurve(
                                strategyStats.get(currentStrategy!.id)!.plCurve,
                                [
                                    {
                                        name: "Win Rate",
                                        value: strategyStats.get(currentStrategy!.id)!.winRate,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.winRate * 100) / 100}%`
                                    },
                                    {
                                        name: "Win Rate Long",
                                        value: strategyStats.get(currentStrategy!.id)!.winRateLongSide,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.winRateLongSide * 100) / 100}%`
                                    },
                                    {
                                        name: "Win Rate Short",
                                        value: strategyStats.get(currentStrategy!.id)!.winRateShortSide,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.winRateShortSide * 100) / 100}%`
                                    },
                                    {
                                        name: "Total Trades",
                                        value: strategyStats.get(currentStrategy!.id)!.totalNumberOfTrades,
                                        display: `${strategyStats.get(currentStrategy!.id)!.totalNumberOfTrades}`
                                    },
                                    {
                                        name: "Long Trades",
                                        value: strategyStats.get(currentStrategy!.id)!.totalNumberOfLongTrades,
                                        display: `${strategyStats.get(currentStrategy!.id)!.totalNumberOfLongTrades}`
                                    },
                                    {
                                        name: "Short Trades",
                                        value: strategyStats.get(currentStrategy!.id)!.totalNumberOfShortTrades,
                                        display: `${strategyStats.get(currentStrategy!.id)!.totalNumberOfShortTrades}`
                                    },
                                    {
                                        name: "Average RR",
                                        value: strategyStats.get(currentStrategy!.id)!.averageRiskReward,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.averageRiskReward * 1000) / 1000}`
                                    },
                                    {
                                        name: "Average IRR",
                                        value: strategyStats.get(currentStrategy!.id)!.averageIdealRiskReward,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.averageIdealRiskReward * 1000) / 1000}`
                                    },
                                    {
                                        name: "Average Win",
                                        value: strategyStats.get(currentStrategy!.id)!.averageWin,
                                        display: `$${Math.round(strategyStats.get(currentStrategy!.id)!.averageWin * 100) / 100}`
                                    },
                                    {
                                        name: "Average Loss",
                                        value: strategyStats.get(currentStrategy!.id)!.averageLoss,
                                        display: `$${Math.round(strategyStats.get(currentStrategy!.id)!.averageLoss * 100) / 100}`
                                    },
                                    {
                                        name: "Buy Orders",
                                        value: strategyStats.get(currentStrategy!.id)!.totalNumberOfBuyOrder,
                                        display: `${strategyStats.get(currentStrategy!.id)!.totalNumberOfBuyOrder}`
                                    },
                                    {
                                        name: "Sell Orders",
                                        value: strategyStats.get(currentStrategy!.id)!.totalNumberOfSellOrder,
                                        display: `${strategyStats.get(currentStrategy!.id)!.totalNumberOfSellOrder}`
                                    },
                                    {
                                        name: "Net Profit",
                                        value: strategyStats.get(currentStrategy!.id)!.netProfit,
                                        display: `$${Math.round(strategyStats.get(currentStrategy!.id)!.netProfit * 100) / 100}`
                                    },
                                    {
                                        name: "Gross Profit",
                                        value: strategyStats.get(currentStrategy!.id)!.grossProfit,
                                        display: `$${Math.round(strategyStats.get(currentStrategy!.id)!.grossProfit * 100) / 100}`
                                    },
                                    {
                                        name: "Gross Loss",
                                        value: strategyStats.get(currentStrategy!.id)!.grossLoss,
                                        display: `$${Math.round(strategyStats.get(currentStrategy!.id)!.grossLoss * 100) / 100}`
                                    },
                                    {
                                        name: "Z-Score",
                                        value: strategyStats.get(currentStrategy!.id)!.zScore,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.zScore * 100) / 100}`
                                    },
                                ]
                            )
                            : <p>Loading...</p>
                    }
                </div>
            </div>
            <div className="flex flex-row space-x-6  w-full">
                <div className="basis-1/2" style={{ height: "50vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderEquityCurve(strategyStats.get(currentStrategy!.id)!.equityCurve,
                                [
                                    {
                                        name: "CAGR",
                                        value: strategyStats.get(currentStrategy!.id)!.compoundAnnualGrowthRate,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.compoundAnnualGrowthRate * 10000) / 100}%`
                                    },
                                    {
                                        name: "Annualized Volatility",
                                        value: strategyStats.get(currentStrategy!.id)!.annualizedVolatility,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.annualizedVolatility * 10000) / 100}%`
                                    },
                                    {
                                        name: "Value At Risk",
                                        value: strategyStats.get(currentStrategy!.id)!.valueAtRisk,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.valueAtRisk * 10000) / 100}%`
                                    },
                                    {
                                        name: "Expected Shortfall",
                                        value: strategyStats.get(currentStrategy!.id)!.conditionalValueAtRisk,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.conditionalValueAtRisk * 10000) / 100}%`
                                    },
                                ])
                            : <p>Loading...</p>
                    }
                </div>
                <div className="basis-1/2" style={{ height: "50vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderDrawdownDrawupCurve(strategyStats.get(currentStrategy!.id)!.drawdownCurve, strategyStats.get(currentStrategy!.id)!.drawupCurve,
                                [
                                    {
                                        name: "Calmar Ratio",
                                        value: strategyStats.get(currentStrategy!.id)!.calmarRatio,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.calmarRatio * 100) / 100}`
                                    },
                                    {
                                        name: "Max Drawdown",
                                        value: strategyStats.get(currentStrategy!.id)!.maxDrawdownPercentage,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.maxDrawdownPercentage * 10000) / 100}%`
                                    },
                                    {
                                        name: "Max Drawup",
                                        value: strategyStats.get(currentStrategy!.id)!.maxDrawupPercentage,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.maxDrawupPercentage * 10000) / 100}%`
                                    },
                                    {
                                        name: "Profit Factor",
                                        value: strategyStats.get(currentStrategy!.id)!.profitFactor,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.profitFactor * 100) / 100}`
                                    },
                                ])
                            : <p>Loading...</p>
                    }
                </div>
            </div>
            <div className="flex flex-row space-x-6 w-full">
                <div className="basis-1/2" style={{ height: "50vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderDailyReturns(strategyStats.get(currentStrategy!.id)!.returns,
                                [
                                    {
                                        name: "Sharpe Ratio",
                                        value: strategyStats.get(currentStrategy!.id)!.sharpeRatio,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.sharpeRatio * 100) / 100}`
                                    },
                                    {
                                        name: "Sortino Ratio",
                                        value: strategyStats.get(currentStrategy!.id)!.sortinoRatio,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.sortinoRatio * 100) / 100}`
                                    },
                                    {
                                        name: "Omega Ratio",
                                        value: strategyStats.get(currentStrategy!.id)!.omegaRatio,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.omegaRatio * 100) / 100}`
                                    },
                                    {
                                        name: "Kurtosis",
                                        value: strategyStats.get(currentStrategy!.id)!.kurtosis,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.kurtosis * 100) / 100}`
                                    },
                                    {
                                        name: "Skewness",
                                        value: strategyStats.get(currentStrategy!.id)!.skewness,
                                        display: `${Math.round(strategyStats.get(currentStrategy!.id)!.skewness * 100) / 100}`
                                    },
                                ])
                            : <p>Loading...</p>
                    }
                </div>
                <div className="basis-1/2" style={{ height: "50vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderBalanceMovement(strategyStats.get(currentStrategy!.id)!.balanceMovement)
                            : <p>Loading...</p>
                    }
                </div>
            </div>
            <div className="flex flex-row space-x-6 w-full">
                <div className="flex-1" style={{ height: "55vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderKPI(strategyStats.get(currentStrategy!.id)!)
                            : <p>Loading...</p>
                    }
                </div>
            </div>
            <div className="flex flex-row space-x-6 w-full">
                <div className="basis-1/2" style={{ height: "40vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderLongSideKPI(strategyStats.get(currentStrategy!.id)!)
                            : <p>Loading...</p>
                    }
                </div>
                <div className="basis-1/2" style={{ height: "40vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderShortSideKPI(strategyStats.get(currentStrategy!.id)!)
                            : <p>Loading...</p>
                    }
                </div>
            </div>
            <div className="flex flex-row space-x-6 w-full">
                <div className="basis-1/2" style={{ height: "22vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderWinningSideKPI(strategyStats.get(currentStrategy!.id)!)
                            : <p>Loading...</p>
                    }
                </div>
                <div className="basis-1/2" style={{ height: "22vh" }}>
                    {
                        strategyStats.get(currentStrategy?.id ?? "none") != undefined
                            ? renderLosingSideKPI(strategyStats.get(currentStrategy!.id)!)
                            : <p>Loading...</p>
                    }
                </div>
            </div>
        </div>
    )
}