'use client'

import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { ChartBarIcon, DocumentChartBarIcon } from '@heroicons/react/20/solid';
import {
    Bars3Icon,
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentTradeState } from '../../../../state/backtesting/atoms/current_trade';
import { tradesSelector } from '../../../../state/backtesting/selectors/trades_selector';
import { currentSessionState } from '../../../../state/backtesting/atoms/current_session';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function BacktestingSession() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [currentTrade, setCurrentTrade] = useRecoilState(currentTradeState);
    const [currentSession, setCurrentSession] = useRecoilState(currentSessionState);
    const sessionTrades = useRecoilValue(tradesSelector(currentSession?.id ?? ""))

    const [navigation, setNavigation] = useState([
        { name: 'Backtesting', href: 'backtesting', icon: ChartBarIcon, current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'backtesting' },
        { name: 'Analytics', href: 'analytics', icon: ChartPieIcon, current: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] == 'analytics' },
        { name: 'Trade Analysis', href: 'replay', icon: DocumentChartBarIcon, current: window.location.pathname.split("/")[4] == 'replay' },
    ])

    useEffect(() => {
        return () => {
            setCurrentTrade(undefined);
            //setCurrentSession(undefined);
        }
    }, [])

    return (
        <div className='flex'>
            <main className='flex-1 mr-20'>
                <Outlet />
            </main>

            {/* Static sidebar for desktop */}
            <div className="fixed inset-y-0 right-0 z-50 block w-20 overflow-y-auto bg-gray-950 pb-4">
                <div className="flex h-16 shrink-0 items-center justify-center">
                    <p className=' text-2xl font-bold tracking-tight text-indigo-400 sm:text-4xl'>
                        TL
                    </p>
                </div>
                <nav className="mt-8">
                    <ul role="list" className="flex flex-col items-center space-y-1">
                        {navigation.map((item) => (
                            <li key={item.name}>
                                <div
                                    className={classNames(
                                        item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                        'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                                    )}
                                    onClick={() => {
                                        setNavigation(navigation.map((n) => {
                                            if (n.name == item.name) {
                                                if (n.current == true) {
                                                    n.current = false;
                                                    setSidebarOpen(false);
                                                } else {
                                                    n.current = true;
                                                    setSidebarOpen(true);
                                                }
                                            } else {
                                                n.current = false;
                                            }

                                            const path = window.location.pathname.split("/");

                                            const isReplay = path.find((p) => p == "replay");

                                            if (item.href == "replay") {
                                                if (currentTrade == undefined) {
                                                    if (sessionTrades.length > 0) {
                                                        navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${item.href}/${sessionTrades[0].id}`);
                                                    } else {
                                                        navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${item.href}/no_trades`);
                                                    }
                                                } else {
                                                    navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${item.href}/${currentTrade.id}`);
                                                }
                                            } else {
                                                if (isReplay) {
                                                    navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 2).join("/")}/${item.href}`)
                                                } else {
                                                    navigate(`${window.location.pathname.split("/").slice(0, window.location.pathname.split("/").length - 1).join("/")}/${item.href}`)
                                                }

                                            }

                                            return n;
                                        }))
                                    }}
                                >
                                    <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                                    <span className="sr-only">{item.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            {/* <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
                    <a href="#">
                        <span className="sr-only">Your profile</span>
                        <img
                            alt=""
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            className="h-8 w-8 rounded-full bg-gray-800"
                        />
                    </a>
                </div> */}


        </div>
    )
}