import { useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authenticationManagerState } from "../../state/authentication/authentication_manager_state";
import { authenticatedState } from "../../state/authentication/atoms/authenticated";
import { usersManagerState } from "../../state/user/users_state";
import { userState } from "../../state/user/atoms/user";
import { useFormik } from "formik";
import { paymentManagerState } from "../../state/payment/payment_manager_state";
import { subscriptionsState } from "../../state/payment/atoms/subscriptions";
import LandingPageHeader from "../landing/components/LandingPageHeader";
import { GoogleLogin, useGoogleLogin, useGoogleOAuth } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import LoginErrorNotification from "./components/login_error_notification";

function Login() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const authenticationManager = useRecoilValue(authenticationManagerState);
    const userManager = useRecoilValue(usersManagerState);
    const [authenticated, setAuthenticated] = useRecoilState(authenticatedState);
    const [user, setUser] = useRecoilState(userState);
    const paymentManager = useRecoilValue(paymentManagerState);
    const setSubscriptions = useSetRecoilState(subscriptionsState);
    const [showpassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                await authenticationManager.loginGoogle(setAuthenticated, tokenResponse.access_token);
                const user = await userManager.getUser(setUser);

                const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);

                navigate("/app");
                return true;

            } catch (error) {
                setShowError(true);

                setTimeout(() => {
                    setShowError(false)
                }, 3000);

                console.log(error)
            }
        }
    })

    useEffect(() => {
        console.log(loaderData);
    }, []);

    const validate = (values: { email?: string, password?: string, api?: string }) => {
        const errors: { email?: string, password?: string, api?: string } = {};
        if (!values.email) {
            errors.email = "You have to enter an email";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(values.email ?? "")) {
            errors.email = "Invalid email provided";
        }

        if (!values.password) {
            errors.password = "You have to enter a password";
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validate,
        onSubmit: async (values) => {
            console.log("calling handle submit");
            console.log(JSON.stringify(user));
            try {
                await authenticationManager.login(setAuthenticated, values.email!, values.password!);
                const user = await userManager.getUser(setUser);

                try {
                    const subscriptions = await paymentManager.listSubscriptions(setSubscriptions, user.customerId);

                    navigate("/app");
                    return true;

                } catch (error) {
                    console.log(error)
                }
                //navigate("/payment");
            } catch (error) {
                errors.api = "login failed"
                return false;
            }

            return true;
        },
    });

    return (
        <div>
            <LandingPageHeader />
            <LoginErrorNotification show={showError} setShow={setShowError} />
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            {touched.email && errors.email
                                ? <p className="text-gray-400">{errors.email}</p>
                                : null}
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <Link to="/init_recover_password" className="font-semibold text-indigo-400 hover:text-indigo-300">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            {errors.api
                                ? <p className="text-gray-400">{errors.api}</p>
                                : null}
                        </div>

                        <div className="flex flex-col items-center space-y-3">
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Sign in
                            </button>
                            <button
                                type="button"
                                className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150"
                                onClick={() => login()}
                            >
                                <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                                <span>Login with Google</span>
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-400">
                        Not a member?{' '}
                        <Link to="/signup" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">
                            Create a free account
                        </Link>
                    </p>
                </div>
            </div>

            {/* <input type="text" name="email" onChange={handleChange} onBlur={handleBlur} />
                {touched.email && errors.email
                    ? <div>{errors.email}</div>
                    : null}
                <br />
                <input type={showpassword ? "text" : "password"} name="password" onChange={handleChange} onBlur={handleBlur} />
                {touched.password && errors.password
                    ? <div>{errors.password}</div>
                    : null}
                <button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setShowPassword(!showpassword);
                }}>{showpassword ? "hide password" : "show password"}</button>
                <button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/init_recover_password")
                }}>Forgot Password ?</button>
                <br />
                {errors.api
                    ? <div>{errors.api}</div>
                    : null}
                <div>
                    <button type="submit">Login</button>
                    <div>  </div>
                    <button onClick={() => navigate("/")}>Back</button>
                </div> */}

        </div >
    )
}

export default Login;