import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import './dark_theme.css';

interface TextEditorProps {
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ value = '', onChange, placeholder = 'Type here...' }) => {
    const [editorValue, setEditorValue] = useState<string>(value);

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const handleChange = (content: string) => {
        setEditorValue(content);
        if (onChange) {
            onChange(content);
        }
    };

    return (
        <div className='dark-theme-editor m-4 h-full'>
            <ReactQuill
                theme="snow"
                value={editorValue}
                onChange={handleChange}
                placeholder={placeholder}
                modules={{
                    toolbar: toolbarOptions
                }}
                className='min-h-full'
                style={{ maxHeight: "25vh" }}
            />
        </div>
    );
};

export default TextEditor;