import { SetterOrUpdater } from "recoil";
import IStatisticsAPIBase from "../api/statistics_api_base";
import { SessionFilterSettings, DaysFilterSettings, TagsFilterSettings, RulesFilterSettings, StrategyStatistics, SessionStatistics, RuleStatistics, TradeStatistics, BalanceMovement } from "../models/statistics_models";
import IStatisticsManagerBase from "./statistics_manager_base";

export default class StatisticsManager<T extends IStatisticsAPIBase> implements IStatisticsManagerBase {
    constructor(protected readonly api: T) { }

    async generateStrategyStatistics(setStrategiesStatisticsState: SetterOrUpdater<Map<string, StrategyStatistics>>, strategyId: string, sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<StrategyStatistics> {
        const strategyStatistics = await this.api.generateStrategyStatistics(strategyId, sessionId, sessionFilter, daysFilter, tagsFilter, rulesFilter);

        setStrategiesStatisticsState((strategiesStatsMap) => {
            strategyStatistics.balanceMovement = strategyStatistics.balanceMovement.map((bm) => new BalanceMovement(bm.date, bm.balance, bm.assetAmount, bm.amountOwed, bm.product, bm.sessionId))
            strategiesStatsMap.set(strategyId, strategyStatistics);

            return new Map(strategiesStatsMap);
        });

        return strategyStatistics;
    }

    async generateSessionStatistics(setSessionsStatisticsState: SetterOrUpdater<Map<string, SessionStatistics>>, sessionId: string, sessionFilter?: SessionFilterSettings, daysFilter?: DaysFilterSettings, tagsFilter?: TagsFilterSettings, rulesFilter?: RulesFilterSettings): Promise<SessionStatistics> {
        const sessionStatistics = await this.api.generateSessionStatistics(sessionId, sessionFilter, daysFilter, tagsFilter, rulesFilter);

        setSessionsStatisticsState((sessionsStatsMap) => {
            sessionsStatsMap.set(sessionId, sessionStatistics);

            return new Map(sessionsStatsMap);
        });

        return sessionStatistics;
    }

    async generateRuleStatistics(setRulesStatisticsState: SetterOrUpdater<Map<string, RuleStatistics>>, ruleId: string): Promise<RuleStatistics> {
        const ruleStatistics = await this.api.generateRuleStatistics(ruleId);

        setRulesStatisticsState((rulesStatsMap) => {
            rulesStatsMap.set(ruleId, ruleStatistics);

            return new Map(rulesStatsMap);
        });

        return ruleStatistics;
    }

    async generateTradeStatistics(setTradesStatisticsState: SetterOrUpdater<Map<string, TradeStatistics>>, tradeId: string): Promise<TradeStatistics> {
        const tradeStatistics = await this.api.generateTradeStatistics(tradeId);

        setTradesStatisticsState((tradesStatsMap) => {
            tradesStatsMap.set(tradeId, tradeStatistics);

            return new Map(tradesStatsMap);
        });

        return tradeStatistics;
    }
}