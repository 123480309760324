import { ChartMetaInfo, ResolutionString } from "charting_library";
import IChartManagementAPI from "../api/chart_management_api_base";
import { ChartLayout } from "../models/chart_layout_models";
import IChartManager from "./chart_manager_base";

export default class ChartManager<T extends IChartManagementAPI> implements IChartManager {
    constructor(protected api: T) { }

    async saveChartLayout(chartName: string, currentSession: string, chartContent: string, id?: string): Promise<string> {
        const chartId = id ?? Math.floor((Date.now() * Math.random()) / 1000)
        const content = btoa(chartContent);

        await this.api.saveChartLayout(chartId as number, chartName, currentSession, [currentSession], content);
        await this.api.updateSessionLastChartLayout(currentSession, chartId.toString());

        return chartId.toString();
    }

    async listAllChartLayouts(): Promise<ChartMetaInfo[]> {
        const rawChartMetaInfo = await this.api.listAllChartLayouts();

        return rawChartMetaInfo.map((chartLayoutInfo) => {
            return {
                id: chartLayoutInfo.chartId,
                name: chartLayoutInfo.chartName,
                symbol: "",
                resolution: "" as ResolutionString,
                timestamp: chartLayoutInfo.lastModified
            };
        });
    }

    async listSessionChartLayouts(sessionId: string): Promise<ChartMetaInfo[]> {
        const rawChartMetaInfo = await this.api.listSessionChartLayouts(sessionId);

        return rawChartMetaInfo.map((chartLayoutInfo) => {
            return {
                id: chartLayoutInfo.chartId,
                name: chartLayoutInfo.chartName,
                symbol: "",
                resolution: "" as ResolutionString,
                timestamp: chartLayoutInfo.lastModified
            };
        });
    }

    async loadChartLayout(chartId: number, sessionId: string): Promise<string> {
        const chartLayout = await this.api.loadChartLayout(chartId, sessionId);
        await this.api.updateSessionLastChartLayout(sessionId, chartId.toString())

        return atob(chartLayout.chartContent);
    }

    async deleteChartLayout(chartId: number, sessionId: string): Promise<void> {
        await this.api.deleteChartLayout(chartId, sessionId);
    }
}