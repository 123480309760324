export default function TradeExecutions() {
    return (
        <div className="flex flex-col space-y-3 text-gray-100">
            <h3>
                Your Executions
            </h3>
            <div className="flex flex-col space-y-3">
                <p>Buy 21 - 234 USDT</p>
            </div>
        </div>
    )
}