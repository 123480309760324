'use client'

import { useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentManagerState } from "../../../../../state/payment/payment_manager_state";
import { invoicesState } from "../../../../../state/payment/atoms/invoices";
import { userState } from "../../../../../state/user/atoms/user";
import { paymentMethodsState } from "../../../../../state/payment/atoms/payment_methods";

export default function BillingSettings() {
    const navigate = useNavigate()
    const loaderData = useLoaderData();
    const paymentManager = useRecoilValue(paymentManagerState);
    const user = useRecoilValue(userState);
    const [invoices, setInvoices] = useRecoilState(invoicesState);
    const [payementMethods, setPaymentMethods] = useRecoilState(paymentMethodsState);

    useEffect(() => {
        console.log(loaderData);
        paymentManager.listInvoices(setInvoices, user.customerId);
        paymentManager.listPaymentMethods(setPaymentMethods, user.customerId);
    }, [user]);

    return (
        <div className="flex flex-col space-y-12 divide-y divide-gray-700 text-gray-50">
            <div className="flex flex-col space-y-6 mt-12 max-w-4xl">
                <h4 className="text-3xl">
                    Invoices:
                </h4>
                {invoices.map((invoice, invoiceIdx) => (
                    <div className="mt-5">
                        <div className="rounded-md bg-gray-800 px-6 py-5 sm:flex sm:items-start sm:justify-between">

                            <div className="sm:flex sm:items-start">
                                <div>Invoice #{invoices.length - 1 - invoiceIdx}</div>
                                <div className="mt-3 sm:ml-4 sm:mt-0">
                                    <div className="text-sm font-medium">{`Due: ${invoice.amount_due / 100} $`}</div>
                                    <div className="text-sm font-medium">{`Paid: ${invoice.amount_paid / 100} $`}</div>
                                    <br />
                                    <div className="text-sm font-medium">{`Total: ${invoice.total / 100} $`}</div>
                                </div>
                            </div>
                            <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-600"
                                    onClick={() => window.open(invoice.hosted_invoice_url, '_blank')?.focus()}
                                >
                                    View Detail
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-col space-y-6 mt-12">
                <h4 className="text-3xl">
                    Payment Methods:
                </h4>
                <div className="bg-gray-900 max-w-3xl shadow sm:rounded-lg">
                    <div >
                        {payementMethods.map((paymentMethod) => (
                            <div className="mt-5">
                                <div className="rounded-md bg-gray-800 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                                    <h4 className="sr-only">{paymentMethod.card?.brand ?? paymentMethod.type}</h4>
                                    <div className="sm:flex sm:items-start">
                                        <div>{paymentMethod.card != undefined ? paymentMethod.card.brand : paymentMethod.type}</div>
                                        <div className="mt-3 sm:ml-4 sm:mt-0">
                                            <div className="text-sm font-medium">{paymentMethod.card != undefined ? `Ending with ${paymentMethod.card.last4}` : null}</div>
                                            <div className="mt-1 text-sm text-gray-200 sm:flex sm:items-center">
                                                <div>{paymentMethod.card != undefined ? `Expires ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}` : "never expires"}</div>
                                                <span aria-hidden="true" className="hidden sm:mx-2 sm:inline">
                                                    &middot;
                                                </span>
                                                <div className="mt-1 sm:mt-0">Last updated on 22 Aug 2017</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-600"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div >
    )
}