import { SetterOrUpdater } from "recoil";
import IBacktestingAPIBase from "../api/backtesting_api_base";
import { Rule, RuleGroup, Session, Strategy, Trade, Order, BasicSymbolInfo, TakeProfit, StopLoss, TPSL } from "../models/backtesting_models";
import IBacktestingManagerBase from "./backtesting_manager_base";
import { upsertDefault, upsertMany, upsertOrder, upsertTrade } from "../../../utils/unicity";

export default class BacktestingManager<T extends IBacktestingAPIBase> implements IBacktestingManagerBase {
    constructor(protected readonly api: T) { }

    async createSession(setSessionState: SetterOrUpdater<Map<string, Session[]>>, name: string, accountBalance: number, pairs: string[], startDate: number, endDate: number, description?: string, strategyId?: string): Promise<Session> {
        const session = await this.api.createSession(name, accountBalance, pairs, startDate, endDate, description, strategyId);

        setSessionState((sessions) => {
            if (session.strategyId != "") {
                sessions.set(session.strategyId, upsertDefault(session, sessions.get(session.strategyId) ?? []))
            } else {
                sessions.set("no_strategy", upsertDefault(session, sessions.get("no_strategy") ?? []))
            }

            return new Map(sessions);
        });

        return session;
    }

    async listSessions(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionName?: string, strategyId?: string): Promise<void> {
        const listedSessions = await this.api.listSessions(sessionName, strategyId);

        setSessionState((sessions) => {
            for (let i = 0; i < listedSessions.length; i++) {
                const session = listedSessions[i];
                if (session.strategyId != "") {
                    sessions.set(session.strategyId, upsertDefault(session, sessions.get(session.strategyId) ?? []))
                } else {
                    sessions.set("no_strategy", upsertDefault(session, sessions.get("no_strategy") ?? []))
                }
            }

            return new Map(sessions);
        });
    }

    async getSession(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string): Promise<Session> {
        const session = await this.api.getSession(sessionId);

        setSessionState((sessions) => {
            if (session.strategyId != "") {
                sessions.set(session.strategyId, upsertDefault(session, sessions.get(session.strategyId) ?? []))
            } else {
                sessions.set("no_strategy", upsertDefault(session, sessions.get("no_strategy") ?? []))
            }

            return new Map(sessions);
        });

        return session;
    }

    async topupSessionBalance(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string, amountToAdd: number): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async updateSessionResolution(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string, resolution: string): Promise<void> {
        await this.api.updateSessionResolution(sessionId, resolution);

        setSessionState((sessions) => {
            sessions.forEach((strategySessions, strategyId) => {
                sessions.set(
                    strategyId,
                    strategySessions.map((session) => {
                        if (session.id == sessionId) {
                            session.resolution = resolution;
                        }
                        return session;
                    }),
                );
            });

            return new Map(sessions);
        });
    }

    async updateSessionStopTime(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string, stopTime: number): Promise<void> {
        await this.api.updateSessionStopTime(sessionId, stopTime);

        setSessionState((sessions) => {
            sessions.forEach((strategySessions, strategyId) => {
                sessions.set(
                    strategyId,
                    strategySessions.map((session) => {
                        if (session.id == sessionId) {
                            session.stopDate = stopTime;
                        }
                        return session;
                    }),
                );
            });

            return new Map(sessions);
        });
    }

    async updateSession(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string, name?: string, description?: string, strategyId?: string, endDate?: number): Promise<void> {
        await this.api.updateSession(sessionId, name, description, strategyId, endDate);

        setSessionState((sessions) => {
            sessions.forEach((strategySessions, sID) => {
                sessions.set(
                    sID,
                    strategySessions.map((session) => {
                        if (session.id == sessionId) {
                            if (name != undefined) session.name = name;
                            if (description != undefined) session.description = description;
                            if (strategyId != undefined) session.strategyId = strategyId;
                            if (endDate != undefined) session.endDate = endDate;

                            return session;
                        }

                        return session;
                    }),
                );
            });

            return new Map(sessions);
        });
    }

    async deleteSession(setSessionState: SetterOrUpdater<Map<string, Session[]>>, sessionId: string): Promise<void> {
        await this.api.deleteSession(sessionId);

        setSessionState((sessions) => {
            sessions.forEach((strategySessions, strategyId) => {
                sessions.set(
                    strategyId,
                    strategySessions.filter((session) => session.id != sessionId),
                );
            });

            return new Map(sessions);
        });
    }

    async createStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, name: string, description: string): Promise<void> {
        const strategy = await this.api.createStrategy(name, description);

        setStrategiesState((strategies) => upsertDefault(strategy, strategies));
    }

    async listStrategies(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyName?: string): Promise<void> {
        const strategies = await this.api.listStrategies(strategyName);

        setStrategiesState((previousStrategies) => upsertMany(strategies, previousStrategies));
    }

    async getStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string): Promise<Strategy> {
        const strategy = await this.api.getStrategy(strategyId);

        setStrategiesState((strategies) => upsertDefault(strategy, strategies));

        return strategy;
    }

    async updateStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string, strategyName?: string, strategyDescription?: string): Promise<void> {
        await this.api.updateStrategy(strategyId, strategyName, strategyDescription);

        setStrategiesState((strategies) => strategies.map((strategy) => {
            if (strategy.id == strategyId) {
                if (strategyName != undefined) strategy.name = strategyName;
                if (strategyDescription != undefined) strategy.description = strategyDescription;

                return strategy;
            }

            return strategy;
        }));
    }

    async deleteStrategy(setStrategiesState: SetterOrUpdater<Strategy[]>, strategyId: string): Promise<void> {
        await this.api.deleteStrategy(strategyId);

        setStrategiesState((strategies) => strategies.filter((strategy) => strategy.id != strategyId));
    }

    async createRuleGroup(setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>, name: string, description: string, strategyId: string): Promise<void> {
        const ruleGroup = await this.api.createRuleGroup(name, description, strategyId);

        setRuleGroupState((groups) => {
            if (ruleGroup.strategyId != "") {
                groups.set(ruleGroup.strategyId, upsertDefault(ruleGroup, groups.get(ruleGroup.strategyId) ?? []))
            } else {
                groups.set("no_strategy", upsertDefault(ruleGroup, groups.get("no_strategy") ?? []))
            }

            return new Map(groups);
        });
    }

    async listRuleGroups(setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>, strategyId: string): Promise<void> {
        const ruleGroups = await this.api.listRuleGroups(strategyId);

        setRuleGroupState((groups) => {
            for (let i = 0; i < ruleGroups.length; i++) {
                const group = ruleGroups[i];
                if (group.strategyId != "") {
                    groups.set(group.strategyId, upsertDefault(group, groups.get(group.strategyId) ?? []))
                } else {
                    groups.set("no_strategy", upsertDefault(group, groups.get("no_strategy") ?? []))
                }
            }

            return new Map(groups);
        });
    }

    async getRuleGroup(setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>, groupId: string): Promise<RuleGroup> {
        const ruleGroup = await this.api.getRuleGroup(groupId);

        setRuleGroupState((groups) => {
            if (ruleGroup.strategyId != "") {
                groups.set(ruleGroup.strategyId, upsertDefault(ruleGroup, groups.get(ruleGroup.strategyId) ?? []))
            } else {
                groups.set("no_strategy", upsertDefault(ruleGroup, groups.get("no_strategy") ?? []))
            }

            return new Map(groups);
        });

        return ruleGroup;
    }

    async updateRuleGroup(setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>, groupId: string, groupName?: string, groupDescription?: string): Promise<void> {
        await this.api.updateRuleGroup(groupId, groupName, groupDescription);

        setRuleGroupState((groups) => {
            groups.forEach((strategyGroups, strategyId) => {
                groups.set(
                    strategyId,
                    strategyGroups.map((group) => {
                        if (group.id == groupId) {
                            if (groupName != undefined) group.name = groupName;
                            if (groupDescription != undefined) group.description = groupDescription;

                            return group;
                        }

                        return group;
                    }),
                );
            });

            return new Map(groups);
        });
    }

    async deleteRuleGroup(setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>, groupId: string): Promise<void> {
        await this.api.deleteRuleGroup(groupId);

        setRuleGroupState((groups) => {
            groups.forEach((strategyGroups, strategyId) => {
                groups.set(
                    strategyId,
                    strategyGroups.filter((group) => group.id != groupId),
                );
            });

            return new Map(groups);
        });
    }

    async createRule(setRuleState: SetterOrUpdater<Map<string, Rule[]>>, strategyId: string, groupId: string, name: string, description: string): Promise<void> {
        const rule = await this.api.createRule(strategyId, groupId, name, description);

        setRuleState((rules) => {
            if (rule.groupId != "") {
                rules.set(rule.groupId, upsertDefault(rule, rules.get(rule.groupId) ?? []))
            } else {
                rules.set("no_group", upsertDefault(rule, rules.get("no_group") ?? []))
            }

            return new Map(rules);
        });
    }

    async listRules(setRuleState: SetterOrUpdater<Map<string, Rule[]>>, groupId: string): Promise<void> {
        const listedRules = await this.api.listRules(groupId);

        setRuleState((rules) => {
            for (let i = 0; i < listedRules.length; i++) {
                const rule = listedRules[i];
                if (rule.groupId != "") {
                    rules.set(rule.groupId, upsertDefault(rule, rules.get(rule.groupId) ?? []))
                } else {
                    rules.set("no_group", upsertDefault(rule, rules.get("no_group") ?? []))
                }
            }

            return new Map(rules);
        });
    }

    async getRule(setRuleState: SetterOrUpdater<Map<string, Rule[]>>, ruleId: string): Promise<Rule> {
        const rule = await this.api.getRule(ruleId);

        setRuleState((rules) => {
            if (rule.groupId != "") {
                rules.set(rule.groupId, upsertDefault(rule, rules.get(rule.groupId) ?? []))
            } else {
                rules.set("no_group", upsertDefault(rule, rules.get("no_group") ?? []))
            }

            return new Map(rules);
        });

        return rule;
    }

    async updateRule(setRuleState: SetterOrUpdater<Map<string, Rule[]>>, ruleId: string, ruleName?: string, ruleDescription?: string): Promise<void> {
        await this.api.updateRule(ruleId, ruleName, ruleDescription);

        setRuleState((rules) => {
            rules.forEach((groupRules, groupId) => {
                rules.set(
                    groupId,
                    groupRules.map((rule) => {
                        if (rule.id == groupId) {
                            if (ruleName != undefined) rule.name = ruleName;
                            if (ruleDescription != undefined) rule.description = ruleDescription;

                            return rule;
                        }

                        return rule;
                    }),
                );
            });

            return new Map(rules);
        });
    }

    async deleteRule(setRuleState: SetterOrUpdater<Map<string, Rule[]>>, ruleId: string): Promise<void> {
        await this.api.deleteRule(ruleId);

        setRuleState((rules) => {
            rules.forEach((groupRules, groupId) => {
                rules.set(
                    groupId,
                    groupRules.filter((rule) => rule.id != ruleId),
                );
            });

            return new Map(rules);
        });
    }

    async listTrades(setTradeState: SetterOrUpdater<Map<string, Trade[]>>): Promise<Trade[]> {
        const listedTrades = await this.api.listTrades();

        setTradeState((trades) => {
            for (let i = 0; i < listedTrades.length; i++) {
                const trade = listedTrades[i];

                trades.set(trade.sessionId, upsertTrade(trade, trades.get(trade.sessionId) ?? []))
            }

            return new Map(trades);
        });

        return listedTrades;
    }

    async listSessionTrades(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, sessionId: string): Promise<Trade[]> {
        const listedTrades = await this.api.listSessionTrades(sessionId);

        setTradeState((trades) => {
            for (let i = 0; i < listedTrades.length; i++) {
                const trade = listedTrades[i];

                trades.set(trade.sessionId, upsertTrade(trade, trades.get(trade.sessionId) ?? []))
            }

            return new Map(trades);
        });

        return listedTrades;
    }

    async listStrategyTrades(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, strategyId: string): Promise<Trade[]> {
        const listedTrades = await this.api.listStrategyTrades(strategyId);

        console.log("here:", listedTrades)

        setTradeState((trades) => {
            for (let i = 0; i < listedTrades.length; i++) {
                const trade = listedTrades[i];

                trades.set(trade.sessionId, upsertTrade(trade, trades.get(trade.sessionId) ?? []))
            }

            console.log("there:", trades)

            return new Map(trades);
        });

        return listedTrades;
    }

    async getTrade(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, tradeId: string): Promise<Trade> {
        const trade = await this.api.getTrade(tradeId);

        setTradeState((trades) => {
            trades.set(trade.sessionId, upsertTrade(trade, trades.get(trade.sessionId) ?? []))

            return new Map(trades);
        });

        return trade;
    }

    async triggerStopLoss(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, tradeId: string, tpslId: string, triggerTime: number): Promise<void> {
        const order = await this.api.triggerStopLoss(sessionId, tradeId, tpslId, triggerTime);

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => {
                        if (trade.id == tradeId) {
                            trade.tpsl = trade.tpsl.map((tpsl) => {
                                if (tpsl.id == tpslId) {
                                    if (tpsl.stopLoss != undefined) {
                                        tpsl.stopLoss.triggered = true;
                                    }
                                }
                                return tpsl;
                            });

                            return trade;
                        }

                        return trade;
                    }),
                );
            });

            return trades;
        });

        setOrderState((orders) => {
            orders.set(order.tradeId, upsertOrder(order, orders.get(order.tradeId) ?? []))

            return new Map(orders);
        });
    }

    async triggerTakeProfit(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, tradeId: string, tpslId: string, triggerTime: number): Promise<void> {
        const order = await this.api.triggerTakeProfit(sessionId, tradeId, tpslId, triggerTime);

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => {
                        if (trade.id == tradeId) {
                            trade.tpsl = trade.tpsl.map((tpsl) => {
                                if (tpsl.id == tpslId) {
                                    if (tpsl.takeProfit != undefined) {
                                        tpsl.takeProfit.triggered = true;
                                    }
                                }
                                return tpsl;
                            });

                            return trade;
                        }

                        return trade;
                    }),
                );
            });

            return trades;
        });

        setOrderState((orders) => {
            orders.set(order.tradeId, upsertOrder(order, orders.get(order.tradeId) ?? []))

            return new Map(orders);
        });
    }

    async liquidateTrade(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, tradeId: string, liquidationPrice: number, triggerTime: number): Promise<void> {
        const order = await this.api.liquidateTrade(sessionId, tradeId, liquidationPrice, triggerTime);

        setOrderState((orders) => {
            orders.set(order.tradeId, upsertOrder(order, orders.get(order.tradeId) ?? []))

            return new Map(orders);
        });
    }

    async updateTrade(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, tradeId: string, tpsl: TPSL, setups?: string[], mistakes?: string[], custom?: string[], rulesFollowed?: string[]): Promise<void> {
        await this.api.updateTrade(tradeId, tpsl, setups, mistakes, custom, rulesFollowed);
        const updatedTrade = await this.api.getTrade(tradeId);

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => (trade.id == tradeId) ? updatedTrade : trade),
                );
            });

            return trades;
        });
    }

    async deleteTakeProfit(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, tradeId: string, tpslId: string): Promise<void> {
        await this.api.deleteTakeProfit(tradeId, tpslId);
        const updatedTrade = await this.api.getTrade(tradeId);

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => (trade.id == tradeId) ? updatedTrade : trade),
                );
            });

            return trades;
        });

        // setTradeState((trades) => {
        //     for (let i = 0; i < trades.length; i++) {
        //         const trade = trades[i];

        //         const tpsls: TPSL[] = [];

        //         for (let i = 0; i < trade.tpsl.length; i++) {
        //             const tpsl = trade.tpsl[i];

        //             if (tpsl.id == tpslId) {
        //                 if (tpsl.stopLoss == undefined) {
        //                     continue;
        //                 }

        //                 tpsl.takeProfit = undefined;
        //             }

        //             tpsls.push(tpsl);
        //         }

        //         trade.tpsl = tpsls;
        //     }

        //     return trades;
        // });
    }

    async deleteStopLoss(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, tradeId: string, tpslId: string): Promise<void> {
        await this.api.deleteStopLoss(tradeId, tpslId);
        const updatedTrade = await this.api.getTrade(tradeId);

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => (trade.id == tradeId) ? updatedTrade : trade),
                );
            });

            return trades;
        });

        // setTradeState((trades) => {
        //     for (let i = 0; i < trades.length; i++) {
        //         const trade = trades[i];

        //         const tpsls: TPSL[] = [];

        //         for (let i = 0; i < trade.tpsl.length; i++) {
        //             const tpsl = trade.tpsl[i];

        //             if (tpsl.id == tpslId) {
        //                 if (tpsl.takeProfit == undefined) {
        //                     continue;
        //                 }

        //                 tpsl.stopLoss = undefined;
        //             }

        //             tpsls.push(tpsl);
        //         }

        //         trade.tpsl = tpsls;
        //     }

        //     return trades;
        // });
    }

    async closeTrade(setTradeState: SetterOrUpdater<Map<string, Trade[]>>, setOrderState: SetterOrUpdater<Map<string, Order[]>>, tradeId: string, closePrice: number, closeTime: number): Promise<void> {
        const closeOrder = await this.api.closeTrade(tradeId, closePrice, closeTime);

        setOrderState((orders) => {
            orders.set(closeOrder.tradeId, upsertOrder(closeOrder, orders.get(closeOrder.tradeId) ?? []))

            return new Map(orders);
        });

        setTradeState((trades) => {
            trades.forEach((sessionTrades, sId) => {
                trades.set(
                    sId,
                    sessionTrades.map((trade) => {
                        if (trade.id == tradeId) {
                            trade.status = "closed";
                        }

                        return trade;
                    }),
                );
            });

            return trades;
        });
    }

    async takeOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, side: string, orderType: string, price: number, amount: number, createdSessionTime: number, product: string, tags: string[], priceAtTime?: number, stopLoss?: StopLoss, takeProfit?: TakeProfit, strategyId?: string): Promise<void> {
        const order = await this.api.takeOrder(sessionId, side, orderType, price, amount, createdSessionTime, product, tags, priceAtTime, stopLoss, takeProfit, strategyId);

        setOrderState((orders) => {
            const tradeId = order.tradeId != "" ? order.tradeId : "default";
            orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))

            return new Map(orders);
        });
    }

    async triggerOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, orderId: string, triggerTime: number): Promise<void> {
        const triggeredOrder = await this.api.triggerOrder(sessionId, orderId, triggerTime);

        setOrderState((orders) => {
            const tradeOrders = orders.get(triggeredOrder.tradeId) ?? [];
            const pendingOrders = orders.get("default") ?? [];

            orders.set("default", pendingOrders.filter((order) => order.id != orderId));

            orders.set(
                triggeredOrder.tradeId,
                upsertOrder(triggeredOrder, tradeOrders),
            );

            return new Map(orders);
        });
    }

    async listOrders(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string): Promise<Order[]> {
        const listedOrders = await this.api.listOrders(sessionId);

        setOrderState((orders) => {
            for (let i = 0; i < listedOrders.length; i++) {
                const order = listedOrders[i];
                const tradeId = order.tradeId != "" ? order.tradeId : "default";

                orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))
            }

            return new Map(orders);
        });

        return listedOrders;
    }

    async listTradeOrders(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string, tradeId: string): Promise<Order[]> {
        const listedOrders = await this.api.listTradeOrders(sessionId, tradeId);

        setOrderState((orders) => {
            for (let i = 0; i < listedOrders.length; i++) {
                const order = listedOrders[i];
                const tradeId = order.tradeId != "" ? order.tradeId : "default";

                orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))
            }

            return new Map(orders);
        });

        return listedOrders;
    }

    async listPassedOrders(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string): Promise<Order[]> {
        const listedOrders = await this.api.listPassedOrders(sessionId);

        setOrderState((orders) => {
            for (let i = 0; i < listedOrders.length; i++) {
                const order = listedOrders[i];
                const tradeId = order.tradeId != "" ? order.tradeId : "default";

                orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))
            }

            return new Map(orders);
        });

        return listedOrders;
    }

    async listAwaitingOrders(setOrderState: SetterOrUpdater<Map<string, Order[]>>, sessionId: string): Promise<Order[]> {
        const listedOrders = await this.api.listAwaitingOrders(sessionId);

        setOrderState((orders) => {
            for (let i = 0; i < listedOrders.length; i++) {
                const order = listedOrders[i];
                const tradeId = order.tradeId != "" ? order.tradeId : "default";

                orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))
            }

            return new Map(orders);
        });

        return listedOrders;
    }

    async getOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, orderId: string): Promise<Order> {
        const order = await this.api.getOrder(orderId);

        setOrderState((orders) => {
            const tradeId = order.tradeId != "" ? order.tradeId : "default";
            orders.set(tradeId, upsertOrder(order, orders.get(tradeId) ?? []))

            return new Map(orders);
        });

        return order;
    }

    async updateOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, orderId: string, side?: string, entry?: number, amount?: number, stopLoss?: StopLoss, takeProfit?: TakeProfit, tags?: string[]): Promise<void> {
        await this.api.updateOrder(orderId, side, entry, amount, stopLoss, takeProfit, tags);

        setOrderState((orders) => {
            orders.forEach((tradeOrders, tradeId) => {
                orders.set(
                    tradeId,
                    tradeOrders.map((order) => {
                        if (order.id == orderId) {
                            if (side != undefined) order.side = side;
                            if (entry != undefined) order.price = entry;
                            if (amount != undefined) order.amount = amount;
                            if (stopLoss != undefined) order.stopLoss = stopLoss;
                            if (takeProfit != undefined) order.takeProfit = takeProfit;
                            if (tags != undefined) order.tags = tags;
                        }

                        return order;
                    }),
                );
            });

            return new Map(orders);
        });
    }

    async cancelOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, orderId: string): Promise<void> {
        await this.api.cancelOrder(orderId);

        setOrderState((orders) => {
            orders.forEach((tradeOrders, tradeId) => {
                orders.set(
                    tradeId,
                    tradeOrders.map((order) => {
                        if (order.id == orderId) {
                            order.state = "canceled";
                        }

                        return order;
                    }),
                );
            });

            return new Map(orders);
        });
    }

    async deleteOrder(setOrderState: SetterOrUpdater<Map<string, Order[]>>, orderId: string): Promise<void> {
        await this.api.deleteOrder(orderId);

        setOrderState((orders) => {
            orders.forEach((tradeOrders, tradeId) => {
                orders.set(
                    tradeId,
                    tradeOrders.filter((order) => order.id != orderId),
                );
            });

            return new Map(orders);
        });
    }

    async listAllPairs(setSymbolsState: SetterOrUpdater<BasicSymbolInfo[]>): Promise<void> {
        const symbols = await this.api.listAllSymbols();

        setSymbolsState(symbols);
    }

    async clear(
        setStrategyState: SetterOrUpdater<Strategy[]>,
        setSessionState: SetterOrUpdater<Map<string, Session[]>>,
        setRuleGroupState: SetterOrUpdater<Map<string, RuleGroup[]>>,
        setRuleState: SetterOrUpdater<Map<string, Rule[]>>,
        setTradeState: SetterOrUpdater<Map<string, Trade[]>>,
        setOrderState: SetterOrUpdater<Map<string, Order[]>>,
    ): Promise<void> {
        setStrategyState([]);
        setSessionState(new Map());
        setRuleGroupState(new Map());
        setRuleState(new Map());
        setTradeState(new Map());
        setOrderState(new Map());
    }
}