import { TradeStatistics } from "../../../../../../state/statistics/models/statistics_models";

const getTradeStatisticsById = (statisticsMap: Map<string, TradeStatistics>, id: string): TradeStatistics | undefined => {
    return statisticsMap.get(id);
};

export const getUserId = (statisticsMap: Map<string, TradeStatistics>, id: string): string | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.userId;
};

export const getSessionId = (statisticsMap: Map<string, TradeStatistics>, id: string): string | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.sessionId;
};

export const getProduct = (statisticsMap: Map<string, TradeStatistics>, id: string): string | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.product;
};

export const getSetups = (statisticsMap: Map<string, TradeStatistics>, id: string): Map<string, number> | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.setups;
};

export const getMistakes = (statisticsMap: Map<string, TradeStatistics>, id: string): Map<string, number> | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.mistakes;
};

export const getCustoms = (statisticsMap: Map<string, TradeStatistics>, id: string): Map<string, number> | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.custom;
};

export const getBreakEvenThreshold = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.breakEvenThreshold;
};

export const getAmountTraded = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.amountTraded;
};

export const getNetProfit = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.netProfit;
};

export const getGrossProfit = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.grossProfit;
};

export const getGrossLoss = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.grossLoss;
};

export const getValueInvested = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.valueInvested;
};

export const getValueRisked = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.valueRisked;
};

export const getExpectedProfit = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.expectedProfit;
};

export const getReturnOnCapital = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.returnOnCapital;
};

export const getRiskReturnRatio = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.riskReturnRatio;
};

export const getReturnOnInvestment = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.returnOnInvestment;
};

export const getRiskRewardRatio = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.riskRewardRatio;
};

export const getIdealRiskRewardRatio = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.idealRiskRewardRatio;
};

export const getMAE = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.mae;
};


export const getMFE = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.mfe;
};


export const getRating = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.rating;
};


export const getPlannedRMultiple = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.plannedRMultiple;
};


export const getRealizedRMultiple = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.realizedRMultiple;
};


export const getNotes = (statisticsMap: Map<string, TradeStatistics>, id: string): string | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.notes;
};

export const getTotalNumberOfOrders = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.totalNumberOfOrder;
};

export const getTotalNumberOfBuyOrders = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.totalNumberOfBuyOrder;
};

export const getTotalNumberOfSellOrders = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.totalNumberOfSellOrder;
};

export const getTimeInMarket = (statisticsMap: Map<string, TradeStatistics>, id: string): number | undefined => {
    const trade = getTradeStatisticsById(statisticsMap, id);
    return trade?.timeInMarket;
};