import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { BasicSymbolInfo, Session, Strategy } from '../../../../../../../state/backtesting/models/backtesting_models'
import IBacktestingManagerBase from '../../../../../../../state/backtesting/manager/backtesting_manager_base'
import { useFormik } from 'formik'
import { currentStrategyState } from '../../../../../../../state/backtesting/atoms/current_strategy'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { symbolsState } from '../../../../../../../state/backtesting/atoms/symbols'
import { backtestingManagerState } from '../../../../../../../state/backtesting/backtesting_manager_state'
import { strategiesState } from '../../../../../../../state/backtesting/atoms/strategies'
import { sessionsState } from '../../../../../../../state/backtesting/atoms/sessions'
import { useNavigate } from 'react-router-dom'
import { currentSessionState } from '../../../../../../../state/backtesting/atoms/current_session'
import { convertToDateTimeFormat } from '../../../../../../../utils/date'

export default function CreateSessionModal(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}) {
    const navigate = useNavigate();
    const setCurrentSession = useSetRecoilState(currentSessionState);
    const currentStrategy = useRecoilValue(currentStrategyState);
    const [strategyQuery, setStrategyQuery] = useState('')
    const [query, setQuery] = useState('')
    const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(null);
    const [selectedSymbol, setSelectedSymbol] = useState<BasicSymbolInfo | null>(null);
    const [symbols, setSymbols] = useRecoilState(symbolsState);
    const [strategies, setStrategies] = useRecoilState(strategiesState);
    const backtestingManager = useRecoilValue(backtestingManagerState);
    const setSessions = useSetRecoilState(sessionsState);

    useEffect(() => {
        backtestingManager.listAllPairs(setSymbols);
        backtestingManager.listStrategies(setStrategies);
    }, []);

    const filteredStrategies =
        strategyQuery === ''
            ? strategies
            : strategies.filter((strategy) => {
                return strategy.name.toLowerCase().includes(strategyQuery.toLowerCase())
            })

    const filteredSymbols =
        query === ''
            ? symbols
            : symbols.filter((symbol) => {
                return symbol.name.toLowerCase().includes(query.toLowerCase())
            })

    const validate = (values: { name?: string, accountBalance?: number, asset?: string, startDate?: string, stopDate?: string, description?: string, strategy?: string, api?: string }) => {
        const errors: { name?: string, accountBalance?: string, asset?: string, startDate?: string, stopDate?: string, description?: string, stragegy?: string, api?: string } = {};
        if (!values.name) {
            errors.name = "You have to enter a name";
        }

        if (!values.accountBalance) {
            errors.accountBalance = "You have to enter an account balance";
        }

        if (!values.asset) {
            errors.asset = "You have to enter an asset";
        }

        if (!values.startDate) {
            errors.startDate = "You have to enter a start date";
        }

        if (!values.stopDate) {
            errors.stopDate = "You have to enter a stop date";
        }

        if (!values.description) {
            errors.description = "You have to enter a description";
        }

        return errors;
    }

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
    } = useFormik({
        initialValues: {
            name: undefined,
            accountBalance: undefined,
            asset: undefined,
            startDate: undefined,
            stopDate: undefined,
            description: undefined,
            strategy: currentStrategy?.id,
        },
        validate,
        onSubmit: async (values) => {
            console.log(values);
            try {
                let offsetStart = 0;
                if (values.startDate != undefined) {
                    const d = new Date(values.startDate);
                    offsetStart = d.getTimezoneOffset();
                }

                let offsetEnd = 0;
                if (values.stopDate != undefined) {
                    const d = new Date(values.stopDate);
                    offsetEnd = d.getTimezoneOffset();
                }
                const session = await backtestingManager.createSession(
                    setSessions,
                    values.name!,
                    values.accountBalance!,
                    [values.asset!],
                    (Date.parse(values.startDate!) / 1000) - (offsetStart * 60),
                    (Date.parse(values.stopDate!) / 1000) - (offsetEnd * 60),
                    values.description!,
                    values.strategy ?? currentStrategy?.id
                );

                props.setOpen(false);

                values = {};

                setCurrentSession(session);
                navigate(`/app/session/${session.id}/backtesting`);

                return true;
            } catch (error) {
                errors.api = "failed to create session"
                return false;
            }
        }
    });

    return (
        <Dialog open={props.open} onClose={props.setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-transparent backdrop-blur-md transition-all data-[closed]:backdrop-blur-none data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <form onSubmit={handleSubmit}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-start sm:items-center sm:p-0">

                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mt-1 sm:mt-3">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Create New Session
                                    </DialogTitle>

                                    <div className="mt-6 flex flex-col space-y-6">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="new session"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.name && errors.name
                                                ? <p className="text-gray-900">{errors.name}</p>
                                                : null}
                                        </div>
                                        {
                                            currentStrategy == undefined
                                                ? <div>
                                                    <Combobox
                                                        as="div"
                                                        value={selectedStrategy}
                                                        onChange={(strategy) => {
                                                            setQuery('')
                                                            setSelectedStrategy(strategy)
                                                            values.strategy = strategy?.id;
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <Label className="block text-sm font-medium leading-6 text-gray-900">Assign a Strategy</Label>
                                                        <div className="relative mt-2">
                                                            <ComboboxInput
                                                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                onChange={(event) => setStrategyQuery(event.target.value)}
                                                                onBlur={() => setStrategyQuery('')}
                                                                displayValue={(strategy: Strategy | null) => strategy?.name ?? ""}
                                                            />
                                                            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </ComboboxButton>

                                                            {filteredStrategies.length > 0 && (
                                                                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {filteredStrategies.map((strategy) => (
                                                                        <ComboboxOption
                                                                            key={strategy.name}
                                                                            value={strategy}
                                                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                                        >
                                                                            <span className="block truncate group-data-[selected]:font-semibold">{strategy.name}</span>

                                                                            <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        </ComboboxOption>
                                                                    ))}
                                                                </ComboboxOptions>
                                                            )}
                                                        </div>
                                                    </Combobox>
                                                    {touched.asset && errors.asset
                                                        ? <p className="text-gray-900">{errors.asset}</p>
                                                        : null}
                                                </div>
                                                : null
                                        }
                                        <div>
                                            <label htmlFor="accountBalance" className="block text-sm font-medium leading-6 text-gray-900">
                                                Account Balance
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="accountBalance"
                                                    name="accountBalance"
                                                    type="number"
                                                    placeholder="25000"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.accountBalance && errors.accountBalance
                                                ? <p className="text-gray-900">{errors.accountBalance}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <Combobox
                                                as="div"
                                                value={selectedSymbol}
                                                onChange={(symbol) => {
                                                    setQuery('')
                                                    setSelectedSymbol(symbol)
                                                    values.asset = symbol?.name
                                                }}
                                                onBlur={handleBlur}
                                            >
                                                <Label className="block text-sm font-medium leading-6 text-gray-900">Asset</Label>
                                                <div className="relative mt-2">
                                                    <ComboboxInput
                                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        onChange={(event) => setQuery(event.target.value)}
                                                        onBlur={() => setQuery('')}
                                                        displayValue={(symbol: BasicSymbolInfo | null) => symbol?.name ?? ""}
                                                    />
                                                    <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </ComboboxButton>

                                                    {filteredSymbols.length > 0 && (
                                                        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredSymbols.map((symbol) => (
                                                                <ComboboxOption
                                                                    key={symbol.name}
                                                                    value={symbol}
                                                                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                                >
                                                                    <span className="block truncate group-data-[selected]:font-semibold">{symbol.name}</span>

                                                                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                </ComboboxOption>
                                                            ))}
                                                        </ComboboxOptions>
                                                    )}
                                                </div>
                                            </Combobox>
                                            {touched.asset && errors.asset
                                                ? <p className="text-gray-900">{errors.asset}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <label htmlFor="startDate" className="block text-sm font-medium leading-6 text-gray-900">
                                                Choose Start Date
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="startDate"
                                                    name="startDate"
                                                    min={selectedSymbol != null ? convertToDateTimeFormat(new Date(selectedSymbol?.firstDate ?? "0").toISOString()) : undefined}
                                                    max={selectedSymbol != null ? values.stopDate ?? convertToDateTimeFormat(new Date(selectedSymbol?.lastDate ?? "0").toISOString()) : undefined}
                                                    type="datetime-local"
                                                    placeholder="start date"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.startDate && errors.startDate
                                                ? <p className="text-gray-900">{errors.startDate}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <label htmlFor="stopDate" className="block text-sm font-medium leading-6 text-gray-900">
                                                Choose Stop Date
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="stopDate"
                                                    name="stopDate"
                                                    type="datetime-local"
                                                    placeholder="stop date"
                                                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    min={selectedSymbol != null ? values.startDate ?? convertToDateTimeFormat(new Date(selectedSymbol.firstDate).toISOString()) : undefined}
                                                    max={selectedSymbol != null ? convertToDateTimeFormat(new Date(selectedSymbol.lastDate).toISOString()) : undefined}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.stopDate && errors.stopDate
                                                ? <p className="text-gray-900">{errors.stopDate}</p>
                                                : null}
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    rows={4}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    defaultValue={''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {touched.description && errors.description
                                                ? <p className="text-gray-900">{errors.description}</p>
                                                : null}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="submit"
                                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                >
                                    Create
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => props.setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>

                    </div>
                </div>
            </form>
        </Dialog>
    )
}